import React, { memo, Fragment, FC } from 'react';
import { Box } from '@mui/material';
import { RegularText, BrandAccentBlue } from '@Iot-Bee/standard-web-library';
import { TimeFormat } from '../Types/Types';

interface TableCellContentProps {
	day: string;
	time: TimeFormat;
	isHovered: boolean;
}

const TableCellContent: FC<TableCellContentProps> = memo(({ day, time, isHovered }) => {
	const timeSplit = time.split(':');
	const displayTime = timeSplit[1] === '00' || timeSplit[1] === '59' || isHovered;

	return (
		<Fragment>
			{day === '' ? (
				<RegularText
					id={`${day}-${time}`}
					sx={{ fontSize: 12, position: 'absolute', top: '-1px', color: isHovered ? BrandAccentBlue : 'black', boxSizing: 'border-box', m: 0, p: 0, textAlign: 'center', width: '100%' }}
				>
					{displayTime && time}
				</RegularText>
			) : (
				<Box id={`${day}-${time}`} sx={{ height: '100%', width: '100%', borderRadius: '5px', boxSizing: 'border-box', m: 0, p: 0 }}></Box>
			)}
		</Fragment>
	);
});

export default TableCellContent;
