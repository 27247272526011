import { io } from 'socket.io-client';

let realSocketUrl = process.env.NODE_ENV === 'development' ? (process.env.REACT_APP_API_URL as string) : '/';
const realsocketLength = realSocketUrl.length;

if (realSocketUrl[realsocketLength - 1] === '/') {
	realSocketUrl = realSocketUrl.slice(0, -1);
}

// if (process.env.NODE_ENV === 'development') {
// 	const socketSplit = process.env.REACT_APP_API_URL?.split(':');
// 	const devSocket = socketSplit ? `${socketSplit[0]}:${socketSplit[1]}:${parseInt(socketSplit[2].replace('/', '')) + 1}` : 'http://localhost:8081';
// 	realSocketUrl = devSocket;
// }

export const socket = io(realSocketUrl, { autoConnect: false, transports: ['websocket'], path: process.env.NODE_ENV === 'development' ? undefined : '/api/socket.io' });
