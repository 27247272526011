import { TimeFormat, TimeStamp, WeekDay, weekDays } from '../../../Types/types';

export function calculateNewDayAndTime(originalDay: WeekDay, originalTime: TimeFormat, dayMovement: number, timeMovement: number, oneTimeMovement: number): TimeStamp {
	const newDay = weekDays[weekDays.indexOf(originalDay) + dayMovement];

	const [hour, minute] = originalTime.split(':'); // split the time into hours and minutes - ['12', '30']

	let newMinuteTime = parseInt(minute);
	let newHourTime = parseInt(hour);

	let totalTimeMovement = timeMovement * oneTimeMovement;

	// if the time movement is more than 60 minutes, we need to adjust the hours
	while (Math.abs(totalTimeMovement) >= 60) {
		if (totalTimeMovement > 0) {
			newHourTime = newHourTime + 1;
			totalTimeMovement = totalTimeMovement - 60;
		} else if (totalTimeMovement < 0) {
			newHourTime = newHourTime - 1;
			totalTimeMovement = totalTimeMovement + 60;
		} else {
			break;
		}
	}

	// add the remaining time movement to the minutes
	newMinuteTime = newMinuteTime + totalTimeMovement;

	// if the minutes are more than 60 or less than 0, we need to adjust the hours and the minutes
	if (newMinuteTime >= 60) {
		newHourTime = newHourTime + 1;
		newMinuteTime = newMinuteTime - 60;
	} else if (newMinuteTime < 0) {
		newHourTime = newHourTime - 1;
		newMinuteTime = newMinuteTime + 60;
	}

	return {
		day: newDay,
		time: `${newHourTime < 10 ? `0${newHourTime}` : newHourTime}:${newMinuteTime < 10 ? `0${newMinuteTime}` : newMinuteTime}`,
	};
}
