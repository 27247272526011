import React, { useState, useContext } from 'react';
import { Box, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

import { BrandDarkBlue, LoginFormTitle, LoginFooterText, InputField, Checkbox, LoginButton, useSnackbar, Logo } from '@Iot-Bee/standard-web-library';

import logo_background from '../../assets/logo_background.svg';

import { encryptPassword } from '../../auth/auth';

const LoginWrapper = styled(Box)(() => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	textAlign: 'center',
	height: '100vh',
	background: BrandDarkBlue,
	backgroundImage: `url(${logo_background})`,
	backgroundPosition: 'bottom center',
	backgroundRepeat: 'no-repeat',
}));

const LoginFormBox = styled(Box)(() => ({
	backgroundColor: '#ffffff',
	boxShadow: '0px 2px 16px rgba(20, 12, 71, 0.1)',
	borderRadius: '6px',
	padding: '40px 20px 30px 20px',
	width: '350px',
}));

export async function registerUser(email, password, firstName, navigate, openSnackbar) {
	//TODO: make the secret secret
	const encryptedPassword = encryptPassword(password);

	try {
		const response = await fetch(process.env.REACT_APP_API_URL + `register`, {
			method: 'POST',
			headers: new Headers({
				// Your header content
				'Content-Type': 'application/json',
			}),
			body: JSON.stringify({ username: email, password: encryptedPassword, firstnmae: firstName }),
		});

		if (response.ok) {
			const data = await response.json();
			openSnackbar('success', 'Registration was successful');
			navigate('/login');
			return { success: true, error: undefined };
		} else {
			console.error('Registration failed', response.status);
			return { success: false, error: 'Registration failed' };
		}
	} catch (error) {
		console.error('Error fetching data:', error);
		return { success: false, error: 'Internal server error' };
	}
}

const RegisterPage = () => {
	const navigate = useNavigate();
	const [{ email, password, confirmedPassword, firstName }, setFormData] = useState({
		email: '',
		password: '',
		confirmedPassword: '',
		firstName: '',
	});

	const {
		actions: { openSnackbar },
	} = useSnackbar();

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (password != confirmedPassword) {
			openSnackbar('error', 'Password and Confirm password need to match');
			setFormData((prevState) => ({
				...prevState,
				confirmedPassword: '',
			}));
		}
		/*const isAuthenticated = await authenticate(username, password);
		if (isAuthenticated.success) {
			navigate('/');
		} else {
			console.log(isAuthenticated);
			// Show an error message or handle the failed authentication
			openSnackbar('error', isAuthenticated.error);
		}*/
	};

	return (
		<LoginWrapper>
			<Box sx={{ position: 'absolute', top: 50, left: 'calc(50% - 82px)' }}>
				<Logo />
			</Box>
			<LoginFormBox>
				<LoginFormTitle sx={{ pb: 7.25 }}>Sign up</LoginFormTitle>
				<form onSubmit={handleSubmit}>
					<InputField
						label="Email"
						value={email}
						required
						sx={{ pb: 5 }}
						onChange={(email) =>
							setFormData((state) => ({
								...state,
								email,
							}))
						}
					/>
					<InputField
						label="Password"
						type="password"
						required
						sx={{ pb: 2 }}
						value={password}
						onChange={(password) =>
							setFormData((state) => ({
								...state,
								password,
							}))
						}
					/>
					<InputField
						label="Confirm Password"
						type="password"
						required
						sx={{ pb: 2 }}
						value={confirmedPassword}
						onChange={(confirmedPassword) =>
							setFormData((state) => ({
								...state,
								confirmedPassword,
							}))
						}
					/>
					<InputField
						label="First name"
						value={firstName}
						required
						sx={{ pb: 2 }}
						onChange={(firstName) =>
							setFormData((state) => ({
								...state,
								firstName,
							}))
						}
					/>
					<Box>
						<LoginButton type="submit">Sign up</LoginButton>
					</Box>
				</form>
				<Stack direction="row" justifyContent="space-between" sx={{ pt: 7.5 }}>
					<LoginFooterText
						onClick={() => {
							navigate('/login'); // Navigate to the login page
						}}
					>
						Already have an account?
					</LoginFooterText>
				</Stack>
			</LoginFormBox>
		</LoginWrapper>
	);
};

export default RegisterPage;
