import React, { FC, useState } from 'react';
import { Box, TextField } from '@mui/material';
import { RegularText } from '@Iot-Bee/standard-web-library';

interface SetCustomHeadersProps {
	onChange: (headers: { key: string; value: string }[]) => void;
	startHeaders?: { key: string; value: string }[];
}

const SetCustomHeaders: FC<SetCustomHeadersProps> = ({ onChange, startHeaders }) => {
	const [customHeaders, setCustomHeaders] = useState(startHeaders || [{ key: '', value: '' }]);

	//Val is the new value, index is the index of the header, type is the key or value
	const internalOnChange = (val: string, index: number, type: 'key' | 'value') => {
		const newHeaders = [...customHeaders];
		newHeaders[index][type] = val;

		if (index === newHeaders.length - 1 && val !== '' && newHeaders.length < 4) {
			newHeaders.push({ key: '', value: '' });
		} else if (val === '' && type === 'key' && customHeaders[index].value === '') {
			newHeaders.splice(index, 1);
		}

		setCustomHeaders(newHeaders);

		if (customHeaders[index].key !== '' && customHeaders[index].value !== '') {
			const onlyValidHeaders = customHeaders.filter((header) => header.key !== '' && header.value !== '');
			onChange(onlyValidHeaders);
		}
	};

	return (
		<Box>
			<RegularText sx={{ fontSize: 14 }}>Custom Headers</RegularText>
			<Box sx={{ display: 'flex', flexDirection: 'column' }}>
				{customHeaders.map((header, index) => {
					return (
						<Box sx={{ display: 'flex', flexDirection: 'row', flex: 1 }} key={index}>
							<Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
								<RegularText sx={{ fontSize: 12 }}>Key {index + 1}</RegularText>
								<TextField
									value={header.key}
									variant="outlined"
									size="small"
									placeholder="Key"
									inputProps={{ sx: { fontSize: '12px' } }}
									onChange={(e) => internalOnChange(e.target.value, index, 'key')}
								/>
							</Box>
							<Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
								<RegularText sx={{ fontSize: 12 }}>Value {index + 1}</RegularText>
								<TextField
									value={header.value}
									variant="outlined"
									size="small"
									placeholder="Value"
									inputProps={{ sx: { fontSize: '12px' } }}
									onChange={(e) => internalOnChange(e.target.value, index, 'value')}
								/>
							</Box>
						</Box>
					);
				})}
			</Box>
		</Box>
	);
};

export default SetCustomHeaders;
