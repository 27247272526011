// export interface GraphData {
// 	x: number;
// 	y: number;
// }

export type GraphData = [number, number];

export interface IDable {
	id: number;
}

/**
 * @param clientId - The client ID
 * @param deviceId - The device ID
 * @param sensor - The sensor type
 * @param time - The time the data was recorded in unix milliseconds
 * @param value - The value of the sensor, this is a bigint which cannot be formatted in JSON so it is stored as a string
 */
export interface DeviceData {
	clientId: string;
	deviceId: string;
	sensor: string;
	time: number;
	value: string;
}

export interface Calibration extends IDable {
	name: string;
	value: string;
}

export interface WebUser {
	id: number;
	clientId: number;
	email: string;
	role: string;
	name: string;
}

export interface Sensor {
	alertId: number | null;
	calibration: Calibration;
	calibrationId: number; // TODO: this is redundant
	clientId: number;
	deviceId: string;
	lastTriggerDate: string | null;
	sensor: string;
}

export interface Device {
	id: string;
	dataUsage: string;
	deviceName: string;
	status: 'Active' | 'Inactive';
	tags: string;
	deviceType: string;
}

export interface Alert {
	id: number;
	name: string;
	deviceIds: string[];
	sensorType: string[];
	action: '' | 'email' | 'HTTP' | 'MQTT';
	recipient: string;
	condition: string;
	message: string;
	subject: string;
	triggerInterval: number;
	customheaders: {key: string; value: string}[];
	mqttCredentials: {port: number; username: string; password: string};
}

export interface DeviceWithSensor {
	deviceId: string;
	sensor: string;
}

export interface ServerAlert {
	id: number;
	clientId: number;
	name: string;
	action: '' | 'email' | 'HTTP';
	recipient: string;
	condition: string;
	message: string;
	subject: string;
	triggerInterval: number;
	header: string | null;
	mqttCredentials: string | null;
	deviceSensors: Sensor[];
}

export interface DeviceTag {
	id: number;
	color: string;
	deviceId: string;
	clientId: number;
	name: string;
}

export interface ServerDevice {
	id: string;
	imei: string;
	frequency: number;
	accumulate: boolean;
	lastPing: string;
	battery: number;
	clientId: number;
	friendlyName: string;
	sensors: number;
	location: string;
	sim: string;
	scheduleId: number | null;
	deviceType: 'regular' | 'energySavingTool';
	dataUsage: number;
	isActive: boolean;
	deviceSensors: Sensor[];
	schedule?: Schedule[];
	deviceTags: DeviceTag[];
}

export type DBWeekDays = 'Mon' | 'Tue' | 'Wed' | 'Thu' | 'Fri' | 'Sat' | 'Sun';

export interface ServerScheduleEvents {
	id: number;
	startTime: string;
	endTime: string;
	scheduleId: number;
	days: DBWeekDays[];
}

export interface Schedule {
	id: number;
	clientId: number;
	name: string;
	devices: ServerDevice[];
	scheduleEvents: ServerScheduleEvents[];
	updatedDate: string;
}

export type WeekDay = '' | 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday' | 'Sunday';
export const weekDays: WeekDay[] = ['', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

export type Hour = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20 | 21 | 22 | 23 | 24;
export const hours: Hour[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];

export type TimeFormat = `${string}:${string}`;

export type TimeStamp = {
	day: WeekDay;
	time: TimeFormat;
};

export interface CalenderScheduleEvent {
	id: number;
	start: TimeStamp;
	end: TimeStamp;
}

export interface NewCalenderScheduleEvent extends Omit<CalenderScheduleEvent, 'id'> {}

export interface BaseCalenderSchedule {
	id: number;
	name: string;
	events: CalenderScheduleEvent[];
	allDevices: string;
	temperatures: {on: number; off: number};
}

export interface CalenderSchedule extends BaseCalenderSchedule {
	devices: string[];
}

export interface EditSchedule extends BaseCalenderSchedule {
	devices: DeviceNameAndUsed[];
}

export interface DeviceNameAndUsed {
	name: string;
	isUsed: string;
	moveFrom?: string;
	selectAll?: boolean;
}

export interface TempText {
	text: string;
	error: boolean;
}

export interface ServerDeviceSettingOperation {
	id: number;
	settingType: string;
	type: string;
	value: number;
	operation?: string;
	connection: string;
	deviceSettingId: number;
}

export interface ServerDeviceSetting {
	id: number;
	clientId: number;
	name: string;
	devices: ServerDevice[];
	deviceSettingOperations: ServerDeviceSettingOperation[];
}

export const settingTypes = ['time', 'value', 'dynamic'] as const;
export const timeIntervals = [120, 180, 240, 300, 360, 420, 480, 540, 600] as const;
export const logicalOperators = ['=', '>', '<'] as const;
export const dynamicOperators = ['larger', 'smaller'] as const;
export const connectorOptions = ['or', 'finished'] as const; //['and', 'or', 'finished'];

export type LogicalOperators = '=' | '>' | '<';
export type DynamicOperators = 'larger' | 'smaller';

export type TimeSetting = number;
export type ValueSetting = {operator: LogicalOperators; value: string};
export type DynamicSetting = {operator: DynamicOperators; value: string};

export interface Setting {
	type: (typeof settingTypes)[number];
	value: TimeSetting | ValueSetting | DynamicSetting;
	connection: 'and' | 'or' | 'finished';
}

interface BaseDeviceSetting {
	id: number;
	name: string;
	transmissionSettings: Setting[];
	collectionSettings: Setting[];
	allDevices: string;
}

export interface DeviceSetting extends BaseDeviceSetting {
	devices: string[];
}

export interface UpdateDeviceSetting extends BaseDeviceSetting {
	devices: DeviceNameAndUsed[];
}
