import React, {useState, createContext, useEffect, ChangeEvent, useRef} from 'react';
import {useParams} from 'react-router-dom';
import {cacheFetcher, noCacheFetcher, reloadCacheData, cacheKeys, calculatedCacheKeys} from '../../../utils/fetch';
import {localStorageUpdateCallback} from '../../../utils/localstorageHandlers';
import {useSnackbar} from '@Iot-Bee/standard-web-library';
import {Calibration, Sensor, DeviceData, GraphData, Device} from '../../../Types/types';
import dayjs, {Dayjs} from 'dayjs';
import {LocalstorageData} from '../../../Types/LocalStorageData';
import FetchResponse from '../../../Types/FetchResponse';

const defaultState = {
	deviceId: '',

	graphData: [] as GraphData[],
	setGraphData: (data: GraphData[]) => {},

	loadingGraphData: {loading: true, success: false},
	setLoadingGraphData: ({loading, success}) => {},

	sensor: '',
	handleChangeSensor: (sensor: ChangeEvent<HTMLInputElement>) => {},

	availableSensors: [] as string[],
	setAvailableSensors: (sensors: string[]) => {},

	calibration: null as Calibration | null,
	handleCalibrationChange: (selectedOption: ChangeEvent<HTMLInputElement>) => {},

	calibrations: [] as Calibration[],

	fromDate: dayjs(new Date()),
	setFromDate: (date: Dayjs) => {},

	toDate: dayjs(new Date()),
	setToDate: (date: Dayjs) => {},

	showDataPoints: false,
	setShowDataPoints: (boolean: boolean) => {},

	unaccumulate: false, // an option to group accumulated data to daily data
	handleAccumulateToDailyChange: (boolean: boolean) => {},

	isPowerDevice: false,

	deviceData: [] as DeviceData[],
	setDeviceData: (data: DeviceData[]) => {},

	historicData: [] as DeviceData[],

	updateData: () => {},
};

export const ChartContext = createContext(defaultState);

const unAccumulateGraphData = (data: GraphData[]) => {
	const newData = [...data];
	for (let i = newData.length - 1; i > 0; i--) {
		newData[i][1] = newData[i][1] - newData[i - 1][1];
	}
	if (newData.length > 0) newData[0][1] = 0;
	return newData;
};

const filterDataByDateRange = (data: DeviceData[], from: Dayjs, to: Dayjs, label: string, accumulate: boolean, calibration: Calibration | null, unAccumulate = false) => {
	//Ensuring we go from start to the end of the day
	let fromCpy = from.clone().set('hours', 0).set('minutes', 0).set('seconds', 0).set('milliseconds', 1);

	let toCpy = to.clone().set('hours', 23).set('minutes', 59).set('seconds', 59).set('milliseconds', 999);

	//Compute the timezone offset
	// const tzOffset = new Date().getTimezoneOffset() * 60 * 1000;

	const filtered = data
		//Filter based on chosen dates and labels
		.filter((item) => {
			const itemDate = dayjs(new Date(item.time));
			return itemDate >= fromCpy && itemDate <= toCpy && item.sensor === label;
		});
	//Only take out 1000 elements, otherwise the graph can't show it (maybe more than 1000 is ok, try)
	// .slice(-1000)
	//Accumulate values if the user choose to
	const reduced = filtered.reduce((accumulator, currentData) => {
		// const prevValue = accumulate && index > 0 ? accumulator[index - 1].y : 0;
		const currentValue = parseFloat(currentData.value);

		//If a calibration is chosen, then compute it
		const yValueFinal = calibration && calibration.value ? eval(calibration.value.replaceAll('$', `${currentValue}`)) : currentValue;

		accumulator.push([
			new Date(currentData.time).getTime(), // - tzOffset,
			yValueFinal,
		]);

		return accumulator; // Highcharts needs the data to be in ascending order
	}, [] as GraphData[]);

	if (unAccumulate && label === 'kwh') {
		return unAccumulateGraphData(reduced);
	}

	return reduced;
};

function initIsPowerDevice(deviceId: string) {
	const userDevices = localStorage.getItem('userDevices');
	if (userDevices) {
		const devices: LocalstorageData<Device[]> = JSON.parse(userDevices);
		const device = devices.data.find((item) => item.id === deviceId);
		return device ? device.deviceType === 'energySavingTool' : false;
	}
	return false;
}

export const ChartContextProvider = ({children}) => {
	const [accumulateIsOn, setAccumulateIsOn] = useState<boolean>(false);
	const [graphData, setGraphData] = useState<GraphData[]>([]);
	const [loadingGraphData, setLoadingGraphData] = useState<{
		loading: boolean;
		success: boolean;
	}>({loading: true, success: false});

	const [sensor, setSensor] = useState<string>(''); //selected sensor
	const [availableSensors, setAvailableSensors] = useState<string[]>([]); //available sensors

	const [calibration, setCalibration] = useState<Calibration | null>(null); //selected calibration
	const [calibrations, setCalibrations] = useState<Calibration[]>([]); //available calibrations
	const [calibrationsMap, setCalibrationsMap] = useState<{
		[key: string]: Calibration;
	}>({});

	const [fromDate, setFromDate] = useState<Dayjs>(dayjs(new Date()));
	const [toDate, setToDate] = useState<Dayjs>(dayjs(new Date()));
	const [showDataPoints, setShowDataPoints] = useState<boolean>(false);
	const [unaccumulate, setUnaccumulate] = useState<boolean>(false);

	const {device} = useParams(); // id of device chosen

	const [historicData, setHistoricData] = useState<DeviceData[]>([]); //This is for graphdata that is not stored in locastorage

	const [deviceData, setDeviceData] = useState<DeviceData[]>([]); //data for chosen device

	const [isPowerDevice, setIsPowerDevice] = useState<boolean>(initIsPowerDevice(device || ''));

	const {
		actions: {openSnackbar},
	} = useSnackbar();

	/*const handleChangeSensor = (event: ChangeEvent<HTMLInputElement>) => {
		const selectedLabel = event.target.value;

		// change dates
		const oldestDate = findOldestDataDate(deviceData, selectedLabel);
		setFromDate(oldestDate);
		const toDate = dayjs(new Date());
		setToDate(toDate);

		//Check if there is a calibration for this sensor
		const hasCalibration = calibrationsMap[selectedLabel];

		const updatedGraphData = filterDataByDateRange([...historicData, ...deviceData], oldestDate, toDate, selectedLabel, accumulateIsOn, hasCalibration, unaccumulate);

		setSensor(selectedLabel);
		setGraphData(updatedGraphData);
		setCalibration(hasCalibration || null);
		setHistoricData([]);
	};*/

	const handleChangeSensor = (event: ChangeEvent<HTMLInputElement>) => {
		const selectedLabel = event.target.value;
		setSensor(selectedLabel);

		if (!device) return;

		setLoadingGraphData({loading: true, success: false});

		const from = fromDate.toDate();
		const to = toDate.toDate();

		const url = process.env.REACT_APP_API_URL + `getolddata/${encodeURIComponent(device)}/${selectedLabel}?from=${from.toISOString()}&to=${to.toISOString()}`;
		noCacheFetcher(url, {
			method: 'GET',
			headers: new Headers({
				// Your header content
				'Content-Type': 'application/json',
			}),
		}).then((res) => {
			res.json()
				.then((response: FetchResponse<DeviceData[]>) => {
					const data = response.message;

					const sortedData = data.sort((a, b) => {
						return Math.floor(new Date(a.time).getTime() / 1000) - Math.floor(new Date(b.time).getTime() / 1000);
					});

					//check if the last (oldest) element has the same timestamp as requested
					const oldestElement = sortedData.length > 0 ? sortedData[0].time : new Date().toDateString();
					const oldestElementDate = dayjs(new Date(oldestElement));
					if (fromDate.isBefore(oldestElementDate) && data.length === 10000) {
						openSnackbar('warning', `You can at most fetch 10.000 data points at a time. So we went back to: ${oldestElementDate.format('YYYY-MM-DD HH:mm:ss')}`);
						setFromDate(oldestElementDate);
					} else {
						setFromDate(fromDate);
					}

					// Append the new historic data correctly
					const newHistoric = [...sortedData, ...historicData];
					setHistoricData([...newHistoric]);
					const dataForGraph: DeviceData[] = [...newHistoric, ...deviceData];
					const updatedGraphData = filterDataByDateRange(dataForGraph, fromDate, toDate, selectedLabel, accumulateIsOn, calibration, unaccumulate);
					setGraphData(updatedGraphData);
					setLoadingGraphData({loading: false, success: true});
				})
				.catch((err) => {
					console.log(err);
					openSnackbar('error', 'Could not fetch more data');
					setLoadingGraphData({loading: false, success: false});
				});
		});
	};

	const handleCalibrationChange = (selectedOption: ChangeEvent<HTMLInputElement>) => {
		if (selectedOption === undefined) return;
		const newCalibration = calibrations.find((item) => item.name === selectedOption.target.value) || null;

		const dataForGraph = [...historicData, ...deviceData];
		const updatedGraphData = filterDataByDateRange(dataForGraph, fromDate, toDate, sensor, accumulateIsOn, newCalibration, unaccumulate);
		setGraphData(updatedGraphData);
		setCalibration(newCalibration || null);
		storeCalibration(newCalibration);
	};

	//Store the calibrations
	const storeCalibration = (newCalibration: Calibration | null) => {
		if (!device) return;
		const calibrationupdate = {
			deviceId: device,
			calibrationId: newCalibration === null ? null : newCalibration.id,
			sensor: sensor,
		};

		cacheFetcher(
			process.env.REACT_APP_API_URL + `updatedevicecalibration`,
			calculatedCacheKeys.calibrationsMap(device),
			(data, cacheKey) => {
				if (data.success) {
					const oldLocalStorageCalibration = localStorage.getItem(cacheKey) || '{}';
					const oldLocalStorageCalibrationParsed: LocalstorageData<{
						[key: string]: Calibration;
					}> = JSON.parse(oldLocalStorageCalibration);
					const newCalibrationMap = {
						...oldLocalStorageCalibrationParsed.data,
						[sensor]: newCalibration,
					};
					const newLocalStorageCalibration: LocalstorageData<{
						[key: string]: Calibration | null;
					}> = {
						createdDate: new Date(),
						data: newCalibrationMap,
					};
					localStorage.setItem(cacheKey, JSON.stringify(newLocalStorageCalibration));
					return newCalibrationMap.data;
				}
			},
			false,
			{
				method: 'PUT',
				headers: new Headers({
					// Your header content
					'Content-Type': 'application/json',
				}),
				body: JSON.stringify(calibrationupdate),
			}
		)
			// .then((res) => res.json())
			.then((res) => {
				setCalibrationsMap((oldMap) => {
					if (newCalibration === null) {
						const newMap = {...oldMap};
						delete newMap[sensor];
						return newMap;
					} else {
						const newMap = {...oldMap, [sensor]: newCalibration};
						return newMap;
					}
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const updateData = () => {
		if (!device) return;
		setLoadingGraphData({loading: true, success: false});
		reloadCacheData<DeviceData[]>(process.env.REACT_APP_API_URL + `getdata/${encodeURIComponent(device)}`, calculatedCacheKeys.data(device), localStorageUpdateCallback, false)
			.then((data) => {
				// Highcharts needs the data to be in ascending order
				const sorted = data.sort((a, b) => {
					return Math.floor(new Date(a.time).getTime() / 1000) - Math.floor(new Date(b.time).getTime() / 1000);
				});

				setDeviceData(sorted);
				const updatedGraphData = filterDataByDateRange(sorted, fromDate, toDate, sensor, accumulateIsOn, calibration, unaccumulate);
				setGraphData(updatedGraphData);
				if (sorted.length > 0) {
					setFromDate(dayjs(new Date(sorted[0].time)));
					let newToDate = dayjs(new Date(sorted[sorted.length - 1].time));
					newToDate = newToDate.set('hours', 23);
					newToDate = newToDate.set('minutes', 59);
					newToDate = newToDate.set('seconds', 59);
					setToDate(newToDate);
				}
				setLoadingGraphData({loading: false, success: true});
				setHistoricData([]);
			})
			.catch((err) => {
				console.log(err);
				setLoadingGraphData({loading: false, success: false});
			});
	};

	// TODO: move this somewhere else
	interface LabelAndCalibrationMap {
		uniqueLabels: string[];
		startSensor: string;
		startCalibration: Calibration | null;
		calibrationsMap: {[key: string]: Calibration};
	}

	//Get the unique labels
	const fetchLabelAndCalibrationMap: () => Promise<LabelAndCalibrationMap> = () => {
		return new Promise((resolve, _reject) => {
			if (!device) return;
			cacheFetcher<Sensor[]>(`${process.env.REACT_APP_API_URL}getdevicecalibrations/${encodeURIComponent(device)}`, calculatedCacheKeys.calibrationsMap(device), (data, cacheKey) => {
				if (data.success) {
					const map = {};
					data.message.forEach((element: Sensor) => {
						map[element.sensor] = element.calibration;
					});
					const localStorageData: LocalstorageData<{
						[key: string]: Calibration;
					}> = {
						createdDate: new Date(),
						data: map,
					};
					localStorage.setItem(calculatedCacheKeys.calibrationsMap(device), JSON.stringify(localStorageData));
				}
			}).then((data) => {
				const returnObject = {
					uniqueLabels: [] as string[],
					startSensor: '',
					startCalibration: null as null | Calibration,
					calibrationsMap: {},
				};

				const uniqueLabels = new Set<string>();
				data.forEach((item) => {
					uniqueLabels.add(item.sensor);
				});
				returnObject.uniqueLabels = Array.from(uniqueLabels);

				if (returnObject.uniqueLabels.length > 0) {
					// find the first sensor with an actual name
					returnObject.startSensor = returnObject.uniqueLabels.find((item) => item !== '') || uniqueLabels[0];

					//check if the sensor has a calibration
					const sensor = data.find((item) => item.sensor === returnObject.startSensor);
					const newcalibration = sensor ? sensor.calibration : undefined;

					if ((calibration === null && newcalibration) || (calibration && newcalibration && calibration.id !== newcalibration.id)) {
						returnObject.startCalibration = newcalibration;
					}
				}

				data.forEach((item) => {
					returnObject.calibrationsMap[item.sensor] = item.calibration;
				});

				return resolve(returnObject);
			});
		});
	};

	//Check if the data is available, if not then fetch from the API on start
	useEffect(() => {
		const getGraphData = () => {
			if (!device) return Promise.resolve([]);
			return new Promise<DeviceData[]>((resolve, reject) => {
				cacheFetcher<DeviceData[]>(process.env.REACT_APP_API_URL + `getdata/${encodeURIComponent(device)}`, calculatedCacheKeys.data(device), localStorageUpdateCallback, false, {
					method: 'GET',
					headers: new Headers({
						// Your header content
						'Content-Type': 'application/json',
					}),
				})
					.then((response: DeviceData[]) => {
						resolve(response);
					})
					.catch((error) => {
						reject(error);
					});
			});
		};

		const fetchLabelAndCalibrationMapPromise = fetchLabelAndCalibrationMap();
		const calibrationsPromise = cacheFetcher<Calibration[]>(`${process.env.REACT_APP_API_URL}getcalibrations`, cacheKeys.calibrations, localStorageUpdateCallback);
		const getGraphDataPromise = getGraphData();

		Promise.all([fetchLabelAndCalibrationMapPromise, calibrationsPromise, getGraphDataPromise])
			.then((values) => {
				const {uniqueLabels, startSensor, startCalibration, calibrationsMap} = values[0];
				let firstSensor = startSensor;
				if (uniqueLabels.includes('kwh')) {
					firstSensor = 'kwh';
				}
				const calibrations = values[1];
				const data = values[2];

				const sortedData = data.sort((a, b) => {
					return Math.floor(new Date(a.time).getTime() / 1000) - Math.floor(new Date(b.time).getTime() / 1000);
				});

				if (sortedData.length > 0) {
					const fromDate = dayjs(new Date(sortedData[0].time));
					const toDate = dayjs(new Date(sortedData[sortedData.length - 1].time));
					const dataForGraph = [...historicData, ...sortedData];
					const updatedGraphData = filterDataByDateRange(dataForGraph, fromDate, toDate, firstSensor, accumulateIsOn, startCalibration, unaccumulate);

					setFromDate(fromDate);
					setToDate(toDate);
					setGraphData(updatedGraphData);
				}

				// If it is a power device, only show the sensors that are relevant
				const powerDeviceSensors = ['kwh', 'battery', 'connectivity', 'temperature'];
				// Set available sensors based on the condition
				setAvailableSensors(isPowerDevice ? uniqueLabels.filter((item) => powerDeviceSensors.includes(item.toLowerCase())) : uniqueLabels);

				setSensor(firstSensor);
				setCalibration(startCalibration);
				setCalibrationsMap(calibrationsMap);

				setDeviceData(sortedData);

				setCalibrations(calibrations);

				setLoadingGraphData({loading: false, success: true});
			})
			.catch((err) => {
				console.log(err);
				setLoadingGraphData({loading: false, success: false});
			});
	}, [device]);

	const findOldestDataDate = (data: DeviceData[], sensor: string) => {
		let oldestDate = dayjs(new Date());
		const deviceDataSensor = data.filter((item) => item.sensor === sensor);
		const historicSensorData = [...historicData, ...deviceDataSensor];
		if (historicSensorData.length > 0) {
			oldestDate = dayjs(new Date(historicSensorData[0].time)).subtract(1, 'millisecond');
		}
		return oldestDate;
	};

	const handleFromDateChange = (date: Dayjs) => {
		let newFromDate = date;

		if (newFromDate.isAfter(toDate)) {
			openSnackbar('error', 'From date cannot be after to date');
			return;
		}

		let oldestDate = findOldestDataDate(deviceData, sensor);

		if (newFromDate.isBefore(oldestDate) && newFromDate.isBefore(fromDate)) {
			//Fetch more data from the API and place it in the historical array

			if (!device) return;
			newFromDate = newFromDate.hour(0).minute(0).second(0).millisecond(0);

			setLoadingGraphData({loading: true, success: false});

			const from = newFromDate.toDate();
			const to = oldestDate.toDate();

			const url = process.env.REACT_APP_API_URL + `getolddata/${encodeURIComponent(device)}/${sensor}?from=${from.toISOString()}&to=${to.toISOString()}`;
			noCacheFetcher(url, {
				method: 'GET',
				headers: new Headers({
					// Your header content
					'Content-Type': 'application/json',
				}),
			}).then((res) => {
				res.json()
					.then((response: FetchResponse<DeviceData[]>) => {
						const data = response.message;

						const sortedData = data.sort((a, b) => {
							return Math.floor(new Date(a.time).getTime() / 1000) - Math.floor(new Date(b.time).getTime() / 1000);
						});

						//check if the last (oldest) element has the same timestamp as requested
						const oldestElement = sortedData.length > 0 ? sortedData[0].time : new Date().toDateString();
						const oldestElementDate = dayjs(new Date(oldestElement));
						if (newFromDate.isBefore(oldestElementDate) && data.length === 10000) {
							openSnackbar('warning', `You can at most fetch 10.000 data points at a time. So we went back to: ${oldestElementDate.format('YYYY-MM-DD HH:mm:ss')}`);
							setFromDate(oldestElementDate);
						} else {
							setFromDate(newFromDate);
						}

						// Append the new historic data correctly
						const newHistoric = [...sortedData, ...historicData];
						setHistoricData([...newHistoric]);
						const dataForGraph: DeviceData[] = [...newHistoric, ...deviceData];
						const updatedGraphData = filterDataByDateRange(dataForGraph, newFromDate, toDate, sensor, accumulateIsOn, calibration, unaccumulate);
						setGraphData(updatedGraphData);
						setLoadingGraphData({loading: false, success: true});
					})
					.catch((err) => {
						console.log(err);
						openSnackbar('error', 'Could not fetch more data');
						setLoadingGraphData({loading: false, success: false});
					});
			});
		} else {
			setFromDate(newFromDate);
			const dataForGraph = [...historicData, ...deviceData];
			const updatedGraphData = filterDataByDateRange(dataForGraph, newFromDate, toDate, sensor, accumulateIsOn, calibration, unaccumulate);
			setGraphData(updatedGraphData);
		}
	};

	const handleToDateChange = (date: Dayjs) => {
		const newToDate = date;
		setToDate(newToDate);
		const dataForGraph = [...historicData, ...deviceData];
		const updatedGraphData = filterDataByDateRange(dataForGraph, fromDate, newToDate, sensor, accumulateIsOn, calibration, unaccumulate);
		setGraphData(updatedGraphData);
	};

	const handleAccumulateToDailyChange = (unAccumulate: boolean) => {
		if (sensor !== 'kwh') {
			openSnackbar('error', 'Only kwh can be accumulated to daily data');
			return;
		}

		const dataForGraph = [...historicData, ...deviceData];

		const graphData = filterDataByDateRange(dataForGraph, fromDate, toDate, sensor, accumulateIsOn, calibration, unAccumulate);

		setGraphData(graphData);
		setUnaccumulate(unAccumulate);
	};

	return (
		<ChartContext.Provider
			value={{
				graphData,
				setGraphData,
				loadingGraphData,
				setLoadingGraphData,
				sensor,
				handleChangeSensor,
				availableSensors,
				setAvailableSensors,
				calibration,
				handleCalibrationChange,
				calibrations,
				fromDate,
				setFromDate: handleFromDateChange,
				toDate,
				setToDate: handleToDateChange,
				showDataPoints,
				setShowDataPoints,
				isPowerDevice,
				unaccumulate,
				handleAccumulateToDailyChange,
				deviceData,
				historicData,
				setDeviceData,
				updateData,
				deviceId: device || '',
			}}>
			{children}
		</ChartContext.Provider>
	);
};
