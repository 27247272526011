import React, {useState} from 'react';
import {FormControlLabel, Box, Stack} from '@mui/material';
import {styled} from '@mui/material/styles';
import {useNavigate} from 'react-router-dom';

import {Black, BrandDarkBlue, LoginFormTitle, LoginFooterText, InputField, Checkbox, LoginButton, useSnackbar, Logo} from '@Iot-Bee/standard-web-library';

import logo_background from '../../assets/logo_background.svg';
import {authenticate} from '../../auth/auth';

const LoginWrapper = styled(Box)(() => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	textAlign: 'center',
	height: '100vh',
	background: BrandDarkBlue,
	backgroundImage: `url(${logo_background})`,
	backgroundPosition: 'bottom center',
	backgroundRepeat: 'no-repeat',
}));

const LoginFormBox = styled(Box)(() => ({
	backgroundColor: '#ffffff',
	boxShadow: '0px 2px 16px rgba(20, 12, 71, 0.1)',
	borderRadius: '6px',
	padding: '40px 20px 30px 20px',
	maxWidth: '80vw',
	minWidth: '300px',
	width: '25%',
}));

const LoginPage = () => {
	const navigate = useNavigate();
	const [{username, password, keepSigned}, setFormData] = useState({
		username: '',
		password: '',
		keepSigned: false,
	});

	const {
		actions: {openSnackbar},
	} = useSnackbar();

	const handleSubmit = async (e) => {
		e.preventDefault();
		const isAuthenticated = await authenticate(username, password);
		if (isAuthenticated.success) {
			navigate('/');
			openSnackbar('success', 'Successfully logged in');
		} else {
			// Show an error message or handle the failed authentication
			if (isAuthenticated.error) {
				openSnackbar('error', isAuthenticated.error);
			} else {
				openSnackbar('error', 'Authentication failed');
			}
		}
	};

	return (
		<LoginWrapper>
			<Box sx={{position: 'absolute', top: 50}}>
				<Logo fill="white" />
			</Box>
			<LoginFormBox>
				<LoginFormTitle sx={{pb: 7.25}}>Sign in</LoginFormTitle>
				<form onSubmit={handleSubmit}>
					<InputField
						label="Username"
						value={username}
						required
						sx={{pb: 5, display: 'block'}}
						onChange={(username) =>
							setFormData((state) => ({
								...state,
								username,
							}))
						}
					/>
					<InputField
						label="Password"
						type="password"
						required
						sx={{pb: 2, display: 'block'}}
						value={password}
						onChange={(password) =>
							setFormData((state) => ({
								...state,
								password,
							}))
						}
					/>
					<Box textAlign="left">
						<FormControlLabel
							sx={{
								'& .MuiFormControlLabel-label': {
									fontSize: '14px',
									color: Black,
								},
							}}
							label="Keep me Signed in"
							control={
								<Checkbox
									checked={keepSigned}
									onChange={(keepSigned) =>
										setFormData((state) => ({
											...state,
											keepSigned,
										}))
									}
									styling={{margin: '0 5px 0 10px'}}
								/>
							}
						/>
					</Box>
					<Box>
						<LoginButton type="submit">Sign in</LoginButton>
					</Box>
				</form>
				<Stack direction="row" justifyContent="space-between" sx={{pt: 7.5}}>
					{/*<LoginFooterText
						onClick={() => {
							navigate('/register'); // Navigate to the register page
						}}
					>Don’t have an account?</LoginFooterText>*/}
					<LoginFooterText>Forgot password</LoginFooterText>
				</Stack>
			</LoginFormBox>
		</LoginWrapper>
	);
};

export default LoginPage;
