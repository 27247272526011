import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';

import { Card, TitleTypography, OutlinedButton, useWindowDimensions } from '@Iot-Bee/standard-web-library';

import { ChartContext } from './ChartContext';
import Map from './Map';
import { Box } from '@mui/material';
import { Device } from '../../../Types/types';
import { cacheFetcher } from '../../../utils/fetch';
import { userDevicesLocalStorageUpdateCallback } from '../../../utils/localstorageHandlers';

const cacheKey = 'userDevices';

const DeviceDataCell = ({ title, value, ...props }) => (
	<Stack direction="column" sx={{ mr: '60px', width: 'auto' }} {...props}>
		<TitleTypography>{title}</TitleTypography>
		<TitleTypography sx={{ color: '#111111', mt: '5px' }}>{value}</TitleTypography>
	</Stack>
);

function DeviceStatusCard() {
	const { updateData, deviceId } = useContext(ChartContext);

	const [device, setDevice] = useState<Device>({} as Device);

	const { screenwidth } = useWindowDimensions();

	useEffect(() => {
		// It is very likely that the device is already in the cache, so we can use the cacheFetcher to get the device
		cacheFetcher<Device[]>(process.env.REACT_APP_API_URL + `getids`, cacheKey, userDevicesLocalStorageUpdateCallback, true)
			.then((data) => {
				setDevice(data.find((device) => device.id === deviceId) as Device);
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	return (
		<Box sx={{ display: 'flex', flexFlow: 'row wrap', gap: '16px' }}>
			<Box sx={{ flex: 4, width: '100%' }}>
				<Card title={`Device ${device.deviceName}`} headerActions={<OutlinedButton onClick={updateData}>Reload data</OutlinedButton>}>
					<Stack direction="row" sx={{ p: screenwidth > 320 ? '20px' : '10px 20px' }}>
						<DeviceDataCell title="Status" value={device.status} />
						<DeviceDataCell title="Data usage" value={device.dataUsage} />
						<DeviceDataCell title="Id" value={device.id} />
					</Stack>
				</Card>
			</Box>
			<Box sx={{ flex: 1 }}>
				<Map simNumber={'238023760026882'} />
			</Box>
		</Box>
	);
}

DeviceDataCell.propTypes = {
	title: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default DeviceStatusCard;
