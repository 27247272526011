import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Outlet, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';

import { SnackbarContextProvider, Snackbar, createTheme, PrivateRoute, WindowDimensionsProvider, TitleTypography } from '@Iot-Bee/standard-web-library';

import DashboardPage from './pages/Dashboard/DashboardPage';
import DeviceOverview from './pages/DeviceOverview/DeviceOverview';
import Login from './pages/Login/Login';
import DevicesPage from './pages/Devices/Devices';
//import DataPage from './pages/DataPage/DataPage';
import AlertsPage from './pages/Alerts/AlertPage';
import CalibrationPage from './pages/Calibration/CalibrationPage';
import UsersPage from './pages/Users/UsersPage';
import Scheduling from './pages/Scheduling/Scheduling';
import DeviceSettings from './pages/DeviceSettings/DeviceSettings';

import './index.css';
import App from './App';
import AccessControl from './components/AccessControl';
import reportWebVitals from './reportWebVitals';
import NotFound from './pages/NotFound';
import RegisterPage from './pages/Register/Register';
import { Navigate } from 'react-router-dom';
import { CacheHandlerProvider } from './context/CacheHandlerContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<ThemeProvider theme={createTheme({})}>
			<WindowDimensionsProvider>
				<BrowserRouter>
					<SnackbarContextProvider>
						<AccessControl>
							<CacheHandlerProvider>
								<Routes>
									<Route path="/login" element={<Login />} />

									{/*<Route path="/register" element={<RegisterPage/>} />*/}
									<Route
										path="/"
										element={
											<App>
												<Outlet />
											</App>
										}
									>
										<Route
											path="*"
											element={
												<PrivateRoute>
													<Navigate to="/" />
												</PrivateRoute>
											}
										/>
										<Route
											path="/"
											element={
												<PrivateRoute>
													<DashboardPage />
												</PrivateRoute>
											}
										/>
										{/*<Route
											path="/data"
											element={
												<PrivateRoute>
													<DataPage />
												</PrivateRoute>
											}
										/>*/}

										<Route
											path="/devices"
											element={
												<PrivateRoute>
													<DevicesPage />
												</PrivateRoute>
											}
										/>
										<Route
											path="/devices/:device"
											element={
												<PrivateRoute>
													<DeviceOverview />
												</PrivateRoute>
											}
										/>
										<Route
											path="/alerts"
											element={
												<PrivateRoute>
													<AlertsPage />
												</PrivateRoute>
											}
										/>
										<Route
											path="/calibration"
											element={
												<PrivateRoute>
													<CalibrationPage />
												</PrivateRoute>
											}
										/>
										<Route
											path="/users"
											element={
												<PrivateRoute>
													<UsersPage />
												</PrivateRoute>
											}
										/>
										<Route
											path="/scheduling"
											element={
												<PrivateRoute>
													<Scheduling />
												</PrivateRoute>
											}
										/>
										<Route
											path="/device-settings"
											element={
												<PrivateRoute>
													<DeviceSettings />
												</PrivateRoute>
											}
										/>
									</Route>
									{/*<Route path="*" element={<NotFound />} />*/}
								</Routes>
							</CacheHandlerProvider>
						</AccessControl>
						<Snackbar />
					</SnackbarContextProvider>
				</BrowserRouter>
			</WindowDimensionsProvider>
		</ThemeProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
