import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import LoginPage from '../pages/Login/Login';

const AccessControl = ({ children }) => {
	const location = useLocation();
	const isLoginActive = location.pathname === '/login';
	return <>{isLoginActive ? <LoginPage /> : children}</>;
};

AccessControl.ropTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element, PropTypes.node]),
};

export default AccessControl;
