import HmacSHA256 from 'crypto-js/hmac-sha256';

export function encryptPassword(password: string) {
	return HmacSHA256(password, 'HongKongDingDongWongTongSoup').toString();
}

export async function authenticate(username: string, password: string) {
	const encryptedPassword = encryptPassword(password);

	try {
		const response = await fetch(process.env.REACT_APP_API_URL + `login`, {
			method: 'POST',
			headers: new Headers({
				// Your header content
				'Content-Type': 'application/json',
			}),
			body: JSON.stringify({ username, password: encryptedPassword }),
		});

		if (response.ok) {
			const data = await response.json();
			localStorage.setItem('accesstoken', data.message.accesstoken);
			localStorage.setItem('refreshtoken', data.message.refreshtoken);
			localStorage.setItem('name', data.message.name);
			if (data.message.clientId === '15') {
				localStorage.setItem('clientid', data.message.clientId);
			}
			return { success: true, error: undefined };
		} else {
			console.error('Authentication failed', response.status);
			return { success: false, error: 'Authentication failed' };
		}
	} catch (error) {
		console.error('Error fetching data:', error);
		return { success: false, error: 'Internal server error' };
	}
}

export function handleLogout(navigate) {
	// Remove the access token from localStorage
	localStorage.removeItem('accesstoken');
	localStorage.clear();

	// Redirect the user to the login page
	navigate('/login');
}
