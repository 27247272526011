import React, { FC, useMemo, useState } from 'react';
import { BaseEventProps, TimeStamp } from '../Types/Types';
import { findElementFromTime, calculateEventPosition, calculateTemptext } from '../Utils/EventUtils';
import { BrandAccentBlue, RegularText } from '@Iot-Bee/standard-web-library';
import { Box } from '@mui/material';

const CreateEvent: FC<BaseEventProps> = ({ startElement, endElement, markersPrHour, parentScrollTop, dragGrid }) => {
	const [tempText, setTempText] = useState<{ start: TimeStamp; end: TimeStamp } | { error: string } | null>(null);
	const startPosition = useMemo(() => {
		if (!startElement) return null;
		return findElementFromTime(startElement);
	}, [startElement, parentScrollTop]);

	const endPosition = useMemo(() => {
		if (!endElement || !startElement) return null;
		return findElementFromTime(endElement);
	}, [endElement, startElement, parentScrollTop]);

	const calculatePosition = useMemo(() => {
		if (!startPosition || !endPosition) return { top: 0, left: 0, width: 0, height: 0 };
		const standardPos = calculateEventPosition(startPosition, endPosition, startElement, endElement, parentScrollTop, dragGrid);

		return standardPos;
	}, [startPosition, endPosition]);

	const calculateTextContent = useMemo(() => {
		if (!startElement || !endElement) return null;

		return calculateTemptext(startElement, endElement, tempText, calculatePosition.height);
	}, [startElement, endElement, tempText]);

	const boxStyles = {
		position: 'absolute',
		top: `${calculatePosition.top}px`,
		left: `${calculatePosition.left}px`,
		width: `${calculatePosition.width}px`,
		height: `${calculatePosition.height}px`,
		backgroundColor: `${BrandAccentBlue}88`,
		zIndex: 1,
		pointerEvents: 'none',
		borderRadius: '5px',
		boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
		overflow: 'hidden',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		padding: '0 5px',
		cursor: 'none',
	};

	return (
		<Box sx={boxStyles}>
			<RegularText sx={{ m: 0 }}>On</RegularText>
			{calculatePosition.height > 30 && <RegularText sx={{ fontSize: '10px', m: 0 }}>{calculateTextContent}</RegularText>}
		</Box>
	);
};

export default CreateEvent;
