import React, { FC, useState } from 'react';

import { FormDialog, InputField, TextfieldSelect } from '@Iot-Bee/standard-web-library';

import { InputLabel } from '@mui/material';

const USER_ROLES = ['admin', 'user'];

interface UserDialogProps {
	user?: {
		id: number;
		name: string;
		email: string;
		role: string;
	};
	onClose: () => void;
	onSave: (user: any) => void;
}

const UserDialog: FC<UserDialogProps> = ({ user, onClose, onSave }) => {
	const [userFormData, setUserFormData] = useState(
		user !== undefined ? { ...user, currentPassword: '', newPassword: '' } : { name: '', email: '', role: USER_ROLES[0], currentPassword: '', newPassword: '' }
	);
	const { name, email, role, currentPassword, newPassword } = userFormData;
	const actionText = user?.id ? 'Update' : 'Add';

	return (
		<FormDialog
			title="Manage user"
			buttonText={`${actionText} user`}
			open={true}
			onClose={onClose}
			onSave={() => {
				onSave(userFormData);
			}}
			maxWidth="xs"
		>
			<InputField
				label="Name"
				value={name}
				onChange={(value) =>
					setUserFormData((state) => ({
						...state,
						name: value,
					}))
				}
				sx={{ pb: 5, display: 'block' }}
				required
			/>
			<InputField
				label="E-mail"
				type="email"
				value={email}
				readOnly={true}
				onChange={(value) =>
					setUserFormData((state) => ({
						...state,
						email: value,
					}))
				}
				sx={{ pb: 5, display: 'block' }}
				required
			/>
			<InputLabel sx={{ pb: 1 }}>Role *</InputLabel>
			<TextfieldSelect
				value={role}
				options={USER_ROLES}
				onChange={(e) =>
					setUserFormData((state) => ({
						...state,
						role: e.target.value,
					}))
				}
				sx={{ pb: 5 }}
				fullWidth
				required
			/>
			<InputField
				label={user !== undefined ? 'Current Password' : 'Password'}
				type="password"
				value={currentPassword}
				sx={{ pb: 5, display: 'block' }}
				onChange={(value) =>
					setUserFormData((state) => ({
						...state,
						currentPassword: value,
					}))
				}
				required
			/>
			{user !== undefined && (
				<InputField
					label="New Password"
					type="password"
					value={newPassword}
					sx={{ pb: 5, display: 'block' }}
					onChange={(value) =>
						setUserFormData((state) => ({
							...state,
							newPassword: value,
						}))
					}
				/>
			)}
		</FormDialog>
	);
};

export default UserDialog;
