import { createTheme as createMuiTheme } from '@mui/material/styles';
import '@fontsource/dm-sans';

export const BrandDarkBlue = '#140C47';
export const DarkGrey = '#777C9A';
export const BrandAccentBlue = '#0C5CF8';
export const GreenColor = '#27CE7E';
export const LightGrey = '#EBEEF4';
export const SubtleGrey = '#F7F7FA';
export const Black = '#111111';
export const BrandLightBlue = '#2E75FF';
export const GreyColor = '#C8CFE0';
export const AlertRed = '#FF4141';
export const BrandLightPurple = '#D7C2FF';

export const SideMenuWidth = 190;
export const TopBarHeight = 60;
export const TopPadding = TopBarHeight + 4; // top bar + it's shadow

export function createTheme(config) {
	return createMuiTheme({
		spacing: 4,
		typography: {
			fontFamily: 'DM Sans',
		},
		palette: {
			primary: {
				main: BrandAccentBlue,
			},
		},
		components: {
			MuiFormControl: {
				defaultProps: {
					variant: 'outlined',
				},
			},
			MuiDrawer: {
				styleOverrides: {
					paper: {
						background: BrandDarkBlue,
						padding: 0,
					},
				},
			},
			MuiAppBar: {
				styleOverrides: {
					root: {
						height: TopBarHeight,
					},
				},
			},
			MuiToolbar: {
				styleOverrides: {
					root: {
						marginLeft: SideMenuWidth,
						background: '#ffffff',
						height: TopBarHeight,
						borderBottom: `1px solid ${LightGrey}`,
						// Overwrite MUI padding
						padding: '0 29px 0 29px !important',
					},
				},
			},
			MuiCssBaseline: {
				styleOverrides: {
					'font-family': 'DM Sans',
				},
			},
			MuiInputLabel: {
				styleOverrides: {
					root: {
						fontFamily: 'DM Sans',
						color: BrandDarkBlue,
						fontSize: '14px',
						fontWeight: 500,
					},
				},
			},
			MuiDatePicker: {
				styleOverrides: {
					root: {
						fontFamily: 'DM Sans',
						color: BrandDarkBlue,
						fontSize: '14px',
						fontWeight: 500,
					},
				},
			},
			MuiTextField: {
				styleOverrides: {
					root: {
						fontFamily: 'DM Sans',
						color: BrandDarkBlue,
						fontSize: '14px',
						fontWeight: 500,
					},
				},
			},
			MuiTableCell: {
				styleOverrides: {
					root: {
						fontFamily: 'DM Sans',
						color: Black,
						fontSize: '14px',
						fontWeight: 500,
					},
				},
			},
			MuiCheckbox: {
				defaultProps: {
					size: 'medium',
					disableRipple: true,
				},
			},
			MuiDivider: {
				styleOverrides: {
					root: {
						borderColor: LightGrey,
					},
				},
			},
		},
	});
}
