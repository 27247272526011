import React, { FC, Ref, useContext, ChangeEvent } from 'react';
import { Dialog, Typography, DialogTitle, DialogContent, Button, DialogActions, Box, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';

import { TextfieldSelect, SwitchToggle, DarkGrey, GreyColor, TextHeader } from '@Iot-Bee/standard-web-library';

import DateSelector from '../../../components/form/DateSelector';
import FormControlLabel from '@mui/material/FormControlLabel';

import Slide from '@mui/material/Slide';
import { ChartContext } from './ChartContext';
import { TransitionProps } from '@mui/material/transitions';

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement<any, any>;
	},
	ref: Ref<unknown>
) {
	return <Slide direction="left" ref={ref} {...props} />;
});

const SideDialog = styled(Dialog)(({ theme }) => ({
	'& .MuiDialog-container': {
		alignItems: 'right',
		margin: 0,
		padding: 0,
		justifyContent: 'right',
		width: '100%',
	},
	'& .MuiPaper-root': {
		margin: 0,
		padding: 0,
		width: '70%',
		maxWidth: '250px',
		height: '100vh',
		maxHeight: '100vh',
		borderRadius: '8px 0px 0px 8px',
	},
	'& .MuiDialogContent-root': {
		paddingBottom: '4px',
	},
}));

interface MobileTableDialogProps {
	open: boolean;
	onClose: () => void;
}

const MobileTableDialog: FC<MobileTableDialogProps> = ({ open, onClose }) => {
	const {
		calibration,
		handleCalibrationChange,
		calibrations,
		fromDate,
		setFromDate,
		toDate,
		setToDate,
		showDataPoints,
		setShowDataPoints,
		isPowerDevice,
		unaccumulate,
		handleAccumulateToDailyChange,
		loadingGraphData,
		sensor,
	} = useContext(ChartContext);

	return (
		<SideDialog open={open} TransitionComponent={Transition} keepMounted onClose={onClose}>
			<DialogTitle sx={{ padding: '14px', borderBottom: '1px solid', borderColor: GreyColor, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
				<TextHeader>Graph Filters</TextHeader>
			</DialogTitle>
			<DialogContent sx={{ marginTop: '4px', padding: '14px' }}>
				<TextfieldSelect
					label="Select calibration"
					sx={{ width: 166, margin: '12px 0px' }}
					options={calibrations.map((item) => item.name)}
					value={calibration !== null ? calibration.name : ''}
					onChange={handleCalibrationChange}
					showNone={true}
					size="small"
				/>
				<Box sx={{ my: 2 }}>
					<DateSelector label="From date" value={fromDate} onChange={setFromDate} dateFormat={'DD/MM/YYYY'} sx={{ my: 1 }} />
					<DateSelector label="To date" value={toDate} onChange={setToDate} dateFormat={'DD/MM/YYYY'} sx={{ my: 1 }} />
				</Box>
				<FormControlLabel
					sx={{
						textDecoration: 'none',
						fontStyle: 'normal',
						fontWeight: 500,
						fontSize: '14px',
						lineHeight: '120%',
						ml: '1px',
						my: 2,
					}}
					control={
						<SwitchToggle
							checked={showDataPoints}
							onChange={(ev) => {
								setShowDataPoints(ev.target.checked);
							}}
							name="showDataPoints"
						/>
					}
					label={
						<Typography
							sx={{
								color: '#111111',
								fontStyle: 'normal',
								fontWeight: 500,
								fontSize: '14px',
								ml: '8px',
							}}
						>
							Show data points
						</Typography>
					}
				/>
				{isPowerDevice && (
					<FormControlLabel
						sx={{
							textDecoration: 'none',
							fontStyle: 'normal',
							fontWeight: 500,
							fontSize: '14px',
							lineHeight: '120%',
							width: '175px',
							ml: '1px',
						}}
						control={
							<SwitchToggle
								checked={unaccumulate}
								onChange={(ev: ChangeEvent<HTMLInputElement>) => {
									handleAccumulateToDailyChange(ev.target.checked);
								}}
								name="unaccumulate"
							/>
						}
						label={
							<Box sx={{ display: 'flex', alignItems: 'center' }}>
								<Typography
									sx={{
										color: '#111111',
										fontStyle: 'normal',
										fontWeight: 500,
										fontSize: '14px',
										ml: '11px',
									}}
								>
									Unaccumulate kwh
								</Typography>
							</Box>
						}
						disabled={loadingGraphData.loading || sensor !== 'kwh'}
					/>
				)}
			</DialogContent>
			<DialogActions sx={{ padding: '0 8px', justifyContent: 'flex-start' }}>
				<Button onClick={onClose} sx={{ color: DarkGrey, borderColor: DarkGrey, textTransform: 'none', fontSize: '14px', fontWeight: 500 }}>
					Close
				</Button>
			</DialogActions>
		</SideDialog>
	);
};

export default MobileTableDialog;
