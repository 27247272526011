import dayjs from 'dayjs';
import { CalenderScheduleEvent, WeekDay } from '../../../Types/types';

const dayjsDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const timeStampToUtc = (event: CalenderScheduleEvent): CalenderScheduleEvent => {
	const tzoffset = new Date().getTimezoneOffset();
	const startDayindex = dayjsDays.findIndex((day) => day === event.start.day);
	const endDayindex = dayjsDays.findIndex((day) => day === event.end.day);

	const [startHour, startMinute] = event.start.time.split(':').map((time) => parseInt(time));
	const [endHour, endMinute] = event.end.time.split(':').map((time) => parseInt(time));

	const startDay = dayjs().day(startDayindex).hour(startHour).minute(startMinute).add(tzoffset, 'minute');
	const endDay = dayjs().day(endDayindex).hour(endHour).minute(endMinute).add(tzoffset, 'minute');

	return {
		...event,
		start: {
			day: startDay.format('dddd') as WeekDay,
			time: startDay.format('HH:mm') as `${string}:${string}`,
		},
		end: {
			day: endDay.format('dddd') as WeekDay,
			time: endDay.format('HH:mm') as `${string}:${string}`,
		},
	};
};

export const utcTimestampToLocale = (event: CalenderScheduleEvent): CalenderScheduleEvent => {
	const tzoffset = new Date().getTimezoneOffset();
	const startDayindex = dayjsDays.findIndex((day) => day === event.start.day);
	const endDayindex = dayjsDays.findIndex((day) => day === event.end.day);

	const [startHour, startMinute] = event.start.time.split(':').map((time) => parseInt(time));
	const [endHour, endMinute] = event.end.time.split(':').map((time) => parseInt(time));

	const startDay = dayjs().day(startDayindex).hour(startHour).minute(startMinute).subtract(tzoffset, 'minute');
	const endDay = dayjs().day(endDayindex).hour(endHour).minute(endMinute).subtract(tzoffset, 'minute');

	return {
		...event,
		start: {
			day: startDay.format('dddd') as WeekDay,
			time: startDay.format('HH:mm') as `${string}:${string}`,
		},
		end: {
			day: endDay.format('dddd') as WeekDay,
			time: endDay.format('HH:mm') as `${string}:${string}`,
		},
	};
};
