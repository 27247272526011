import React, { useState, useEffect, useMemo, useContext, FC } from 'react';
import { PrimaryButton, useSnackbar, IOTTableWrapper, Card, DeleteDialog } from '@Iot-Bee/standard-web-library';

import CalibrationDialog from './components/CalibrationDialog';

// import { TableDataContext } from '../../context/TableDataContext';
import { cacheFetcher, cacheKeys } from '../../utils/fetch';
import { appendToArrayInLocalStorage, deleteItemFromArrayInLocalStorage, localStorageUpdateCallback, updateItemInArrayInLocalStorage } from '../../utils/localstorageHandlers';
import { Calibration } from '../../Types/types';

export const CalibrationPage: FC = () => {
	const [calibrations, setCalibrations] = useState<Calibration[]>([]);

	const [dialogInfo, setDialogInfo] = useState<{ open: boolean; mode: 'add' | 'edit'; calibration: Calibration | null }>({
		open: false,
		mode: 'add',
		calibration: null,
	});

	const [deleteDialog, setDeleteDialog] = useState<{ open: boolean; calibration: Calibration }>({ open: false, calibration: {} as Calibration });

	const {
		actions: { openSnackbar },
	} = useSnackbar();

	// id is used to fetch the data from the object that we use
	// title is the title of the column/the displayed text
	// width is the width of the column (not used)
	// onClick is the function that is called when the column is clicked
	// priority is the priority of the column, for mobile table, depending on screensize the amount of priorities are displayed
	const columns = useMemo(
		() => [
			{
				id: 'name',
				title: 'Name',
				width: 250,
				priority: 1,
				isKey: true,
			},
			{
				id: 'value',
				title: 'Operation',
				width: 250,
				priority: 2,
			},
		],
		[]
	);

	useEffect(() => {
		cacheFetcher<Calibration[]>(`${process.env.REACT_APP_API_URL}getcalibrations`, cacheKeys.calibrations, localStorageUpdateCallback)
			.then((data) => {
				setCalibrations(data);
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	const handleSaveCalibration = (calibration: Calibration) => {
		// validate the calibration
		if (calibration.name === '') {
			openSnackbar('error', 'Please enter a name for the calibration');
			return;
		}
		if (calibration.value === '') {
			openSnackbar('error', 'Please enter a value for the calibration');
			return;
		}

		if (!calibration.value.includes('$')) {
			openSnackbar('error', 'The calibration value must be in the format: $/400');
			return;
		}
		//TODO: this is not 100% correct as dollarsign can be put many places. You can try and look at my code in DeviceDataCard where i use the dollarsign
		const resultsInValue = calibration.value.replaceAll('$', '1');
		let valid = false;
		try {
			/*jslint evil: true */
			const res = Function('return ' + resultsInValue)();
			if (!isNaN(res)) {
				valid = true;
			}
		} catch (e) {
			console.log(e);
			valid = false;
		}

		if (!valid) {
			alert('The calibration does not return a number');
			return;
		}
		// This returns the id of the new calibration
		cacheFetcher<Calibration>(
			`${process.env.REACT_APP_API_URL}addcalibration`,
			cacheKeys.calibrations,
			(addCalibrationResponse) => {
				if (addCalibrationResponse.success) {
					appendToArrayInLocalStorage(addCalibrationResponse.message, cacheKeys.calibrations);
				}
			},
			false,
			{
				method: 'POST',
				headers: new Headers({
					// Your header content
					'Content-Type': 'application/json',
				}),
				body: JSON.stringify(calibration),
			}
		)
			.then((response) => {
				setCalibrations((state) => [...state, response]);
				setDialogInfo({ open: false, mode: 'add', calibration: null });
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const handleDeleteCalibration = (calibration: Calibration) => {
		cacheFetcher(
			`${process.env.REACT_APP_API_URL}deletecalibration`,
			cacheKeys.calibrations,
			(deleteResponse) => {
				if (deleteResponse.success) {
					deleteItemFromArrayInLocalStorage<Calibration>((c) => c.id === calibration.id, cacheKeys.calibrations);
				}
			},
			false,
			{
				method: 'DELETE',
				headers: new Headers({
					// Your header content
					'Content-Type': 'application/json',
				}),
				body: JSON.stringify({ id: calibration.id }),
			}
		)
			.then((response) => {
				setCalibrations((state) => state.filter((c) => c.id !== calibration.id));
				//Get the calibrationmaps from localstorage
				const items = { ...localStorage };
				const keys = Object.keys(items);
				const calibrationMaps = keys.reduce((map, key) => {
					if (key.includes('calibrationsMap')) {
						map[key] = JSON.parse(items[key]);
					}
					return map;
				}, {});
				//Set the calibrations to null in the calibrationmaps
				const newCalibrationMaps = Object.keys(calibrationMaps).reduce((map, key) => {
					map[key] = calibrationMaps[key].map((c) => {
						if (c.id === calibration.id) {
							return { ...c, value: null };
						} else {
							return c;
						}
					});
					return map;
				}, {});
				//Set the calibrationsmap in localstorage
				Object.keys(newCalibrationMaps).forEach((key) => {
					localStorage.setItem(key, JSON.stringify(newCalibrationMaps[key]));
				});
			})
			.catch((error) => {
				console.log(error);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const handleEditCalibration = (calibration: Calibration) => {
		cacheFetcher(
			`${process.env.REACT_APP_API_URL}editcalibration`,
			cacheKeys.calibrations,
			(editRes) => {
				if (editRes.success) {
					updateItemInArrayInLocalStorage<Calibration>((c) => c.id === calibration.id, calibration, cacheKeys.calibrations);
				}
			},
			false,
			{
				method: 'PUT',
				headers: new Headers({
					// Your header content
					'Content-Type': 'application/json',
				}),
				body: JSON.stringify(calibration),
			}
		)
			.then((response) => {
				setCalibrations((state) => state.map((c) => (c.id === calibration.id ? calibration : c)));
				setDialogInfo({ open: false, mode: 'add', calibration: null });
			})
			.catch((error) => {
				console.log(error);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	return (
		<Card
			title={'Calibration'}
			headerActions={
				<PrimaryButton
					onClick={() => {
						setDialogInfo({ open: true, mode: 'add', calibration: null });
					}}
				>
					Add new calibration
				</PrimaryButton>
			}
		>
			<IOTTableWrapper
				columns={columns}
				data={calibrations.sort((a, b) => a.id - b.id)}
				onEdit={(itemToEdit) => {
					setDialogInfo({ open: true, mode: 'edit', calibration: itemToEdit });
				}}
				onDelete={(itemToDelete) => {
					setDeleteDialog({ open: true, calibration: itemToDelete });
				}}
			/>
			{dialogInfo.open && (
				<CalibrationDialog
					calibration={dialogInfo.calibration}
					onClose={() => setDialogInfo({ open: false, mode: 'add', calibration: null })}
					onSave={dialogInfo.mode === 'add' ? handleSaveCalibration : handleEditCalibration}
				/>
			)}
			<DeleteDialog
				title={deleteDialog.calibration?.name}
				open={deleteDialog.open}
				handleDelete={() => {
					handleDeleteCalibration(deleteDialog.calibration);
					setDeleteDialog((prev) => ({ ...prev, open: false }));
				}}
				onClose={() => setDeleteDialog((prev) => ({ ...prev, open: false }))}
				itemName={deleteDialog.calibration?.name}
			/>
		</Card>
	);
};

export default CalibrationPage;
