import React, { useState, useEffect, useMemo } from 'react';
import { Card, PrimaryButton, IOTTableWrapper, useSnackbar, DeleteDialog } from '@Iot-Bee/standard-web-library';

import UserDialog from './components/UserDialog';
import { encryptPassword } from '../../auth/auth';
import { cacheFetcher, cacheKeys } from '../../utils/fetch';
import { WebUser } from '../../Types/types';
import { appendToArrayInLocalStorage, deleteItemFromArrayInLocalStorage, localStorageUpdateCallback, updateItemInArrayInLocalStorage } from '../../utils/localstorageHandlers';

export function UsersPage() {
	const [webUsers, setWebUsers] = useState<WebUser[]>([]);
	const [editUser, setEditUser] = useState<WebUser | undefined>(undefined);
	const [dialogOpen, setDialogOpen] = useState<any>(false);
	const [newUser, setNewUser] = useState<any>(false);
	const [deleteDialog, setDeleteDialog] = useState<any>({ open: false, user: undefined });

	const {
		actions: { openSnackbar },
	} = useSnackbar();

	// id is used to fetch the data from the object that we use
	// title is the title of the column/the displayed text
	// width is the width of the column (not used)
	// onClick is the function that is called when the column is clicked
	// priority is the priority of the column, for mobile table, depending on screensize the amount of priorities are displayed
	const columns = useMemo(
		() => [
			{
				id: 'name',
				title: 'Name',
				width: 250,
				priority: 1,
				isKey: true,
			},
			{
				id: 'email',
				title: 'Email',
				width: 250,
				priority: 2,
			},

			{
				id: 'role',
				title: 'Role',
				width: 150,
				priority: 3,
			},
		],
		[]
	);

	const fetchData = () => {
		cacheFetcher<WebUser[]>(process.env.REACT_APP_API_URL + `getwebusers`, cacheKeys.webusers, localStorageUpdateCallback, false, {
			method: 'GET',
			headers: new Headers({
				// Your header content
				'Content-Type': 'application/json',
			}),
		}).then((data) => {
			setWebUsers(data);
		});
	};

	useEffect(() => {
		fetchData();
	}, []);

	interface EditWebUser extends WebUser {
		currentPassword: string;
		newPassword: string;
	}

	const handleEditUser = (user: EditWebUser) => {
		const currentEncryptedPassword = encryptPassword(user.currentPassword);
		const encryptedNewPassword = encryptPassword(user.newPassword);

		const body = {
			...user,
			currentPassword: currentEncryptedPassword,
			newPassword: user.newPassword.length > 0 ? encryptedNewPassword : undefined,
		};

		cacheFetcher(
			process.env.REACT_APP_API_URL + `updatewebuser`,
			cacheKeys.webusers,
			(data, cacheKey) => {
				updateItemInArrayInLocalStorage((webUser) => webUser.id === user.id, user, cacheKey);
			},
			false,
			{
				method: 'PUT',
				headers: new Headers({
					// Your header content
					'Content-Type': 'application/json',
				}),
				body: JSON.stringify(body),
			}
		)
			.then((response) => {
				setWebUsers((state) => state.map((u) => (u.id === user.id ? { ...user } : u)));
				openSnackbar('success', 'User updated successfully');
			})
			.catch((err) => {
				console.log('err', err);
				openSnackbar('error', `Error updating user, ${err}`);
			});
	};

	interface NewWebuser {
		email: string;
		name: string;
		currentPassword: string; // it is called currentPassword in the UserDialog
		role: 'admin' | 'user';
	}

	const handleAddUser = (userData: NewWebuser) => {
		const { name, email, role, currentPassword } = userData; // currentPassword is the password in the UserDialog
		const encryptedPassword = encryptPassword(currentPassword);
		cacheFetcher<{ userId: number; clientId: number }>(
			process.env.REACT_APP_API_URL + `addwebuser`,
			cacheKeys.webusers,
			(addRes, cacheKey) => {
				if (addRes.success) {
					const localstorageUser = { ...userData, id: addRes.message.userId, clientId: addRes.message.clientId };
					appendToArrayInLocalStorage<WebUser>(localstorageUser, cacheKey);
				}
			},
			false,
			{
				method: 'POST',
				headers: new Headers({
					// Your header content
					'Content-Type': 'application/json',
				}),
				body: JSON.stringify({ name: name, email: email, role: role, password: encryptedPassword }),
			}
		)
			.then((response) => {
				setWebUsers((state) => [...state, { ...userData, id: response.userId, clientId: response.clientId }]);
				openSnackbar('success', 'User added successfully');
			})
			.catch((err) => {
				console.log('err', err);
				openSnackbar('error', 'Error adding user');
			});
	};

	const handleDeleteUser = (userId: string) => {
		cacheFetcher(
			process.env.REACT_APP_API_URL + `deletewebuser`,
			cacheKeys.webusers,
			(data, cacheKey) =>
				deleteItemFromArrayInLocalStorage<WebUser>((webuser) => {
					return webuser.id === +userId;
				}, cacheKey),
			false,
			{
				method: 'DELETE',
				headers: new Headers({
					// Your header content
					'Content-Type': 'application/json',
				}),
				body: JSON.stringify({ id: userId }),
			}
		)
			.then((response) => {
				openSnackbar('success', 'User deleted successfully');
				setWebUsers((state) => state.filter((u) => u.id !== +userId));
			})
			.catch((err) => {
				openSnackbar('error', 'Error deleting user');
			});
	};

	return (
		webUsers.length !== 0 && (
			<Card
				title={'Users'}
				headerActions={
					<PrimaryButton
						onClick={() => {
							setEditUser(undefined);
							setDialogOpen(true);
							setNewUser(true);
						}}
					>
						Add new user
					</PrimaryButton>
				}
			>
				<IOTTableWrapper
					columns={columns}
					data={webUsers}
					onEdit={(userToEdit) => {
						setEditUser(userToEdit);
						setDialogOpen(true);
						setNewUser(false);
					}}
					onDelete={(user) => setDeleteDialog({ open: true, user: user })}
				/>
				{dialogOpen && (
					<UserDialog
						user={editUser}
						onClose={() => setDialogOpen(false)}
						onSave={(userData: EditWebUser | NewWebuser) => {
							if (editUser) {
								handleEditUser(userData as EditWebUser);
							} else {
								handleAddUser(userData as NewWebuser);
							}
							setDialogOpen(false);
						}}
					/>
				)}
				<DeleteDialog
					title={deleteDialog.user?.name}
					open={deleteDialog.open}
					handleDelete={() => {
						handleDeleteUser(deleteDialog.user.id);
						setDeleteDialog({ open: false, user: undefined });
					}}
					onClose={() => setDeleteDialog((state) => ({ ...state, open: false }))}
					itemName={deleteDialog.user?.name}
				/>
			</Card>
		)
	);
}

export default UsersPage;
