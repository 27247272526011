import React, {FC} from 'react';
import HomeIcon from '../../assets/icons/HomeIcon';
import DevicesIcon from '../../assets/icons/DevicesIcon';
import DataIcon from '../../assets/icons/DataIcon';
import AlertsIcon from '../../assets/icons/AlertsIcon';
import CalibrationIcon from '../../assets/icons/CalibrationIcon';
import UsersIcon from '../../assets/icons/UsersIcon';
import SchedulingIcon from '../../assets/icons/Scheduling';
import TuneIcon from '@mui/icons-material/Tune';

const TuneIconColor: FC<{strokeColor: string}> = ({strokeColor}) => {
	return <TuneIcon sx={{color: strokeColor}} />;
};

const sideMenus = [
	{
		path: '/',
		icon: <HomeIcon strokeColor={'white'} />,
		text: 'Dashboard',
	},
	{
		path: '/devices',
		icon: <DevicesIcon strokeColor={'white'} />,
		text: 'Devices',
	},
	/*{
		path: '/data',
		icon: <DataIcon strokeColor={'white'} />,
		text: 'Data',
	},*/
	{
		path: '/alerts',
		icon: <AlertsIcon strokeColor={'white'} />,
		text: 'Alerts',
	},
	{
		path: '/calibration',
		icon: <CalibrationIcon strokeColor={'white'} />,
		text: 'Calibration',
	},
	{
		path: '/users',
		icon: <UsersIcon strokeColor={'white'} />,
		text: 'Users',
	},
	{
		path: '/scheduling',
		icon: <SchedulingIcon strokeColor={'white'} />,
		text: 'Scheduling',
	},
	{
		path: '/device-settings',
		icon: <TuneIconColor strokeColor={'white'} />,
		text: 'Device Settings',
	},
];

export default sideMenus;
