import React, { useMemo, useState, useRef, FC, MouseEvent } from 'react';
import { Box, Button } from '@mui/material';
import { CalenderScheduleEvent, NewCalenderScheduleEvent, WeekDay, weekDays } from '../../../Types/types';

import { BrandAccentBlue, RegularText } from '@Iot-Bee/standard-web-library';

import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { debounce } from 'lodash';
import { calculateNewDayAndTime } from '../Utils/calculateNewDayAndTime';

import Draggable from 'react-draggable';
import ResizeBoxes from './ResizeBoxes';
import { HandleType, TempText, TimeFormat, TimeStamp } from '../Types/Types';

import { BaseEventProps } from '../Types/Types';
import { calculateEventPosition, calculateTemptext, findElementFromTime } from '../Utils/EventUtils';
import { orderEventStartAndEnd } from '../Utils/sortCalenderEvents';

interface ExistingEventProps extends BaseEventProps {
	eventId: number;
	deleteEvent: (eventId: number) => void;
	handleMoveEvent: (oldEvent: any, newEvent: any) => void;
	updateEventSizeAndPosition: (oldEvent: any, newEvent: any) => void;
	toggleHover: (value: boolean) => void;
	validatePosition: (event: CalenderScheduleEvent) => boolean;
	editable: boolean;
}

interface Resizing {
	dir: string;
	top: number;
	left: number;
	width: number;
	height: number;
}

const Event: FC<ExistingEventProps> = ({
	startElement,
	endElement,
	eventId,
	deleteEvent,
	markersPrHour,
	dragGrid,
	handleMoveEvent,
	updateEventSizeAndPosition,
	toggleHover,
	parentScrollTop,
	validatePosition,
	editable,
}) => {
	// this stores the id of the event that is being dragged
	const [isDragging, setIsDragging] = useState<any>(null);
	const [isResizing, setIsResizing] = useState<Resizing>({ dir: '', top: 0, left: 0, width: 0, height: 0 });
	const [startResizing, setStartResizing] = useState<any>({ x: 0, y: 0 });
	const [tempText, setTempText] = useState<{ start: TimeStamp; end: TimeStamp } | { error: string } | null>(null);

	const draggableRef = useRef(null);

	const finishResizing = () => {
		// updateEventSizeAndPosition({ start: startElement, end: endElement, id: eventId, position: isResizing });
		setIsResizing({ dir: '', top: 0, left: 0, width: 0, height: 0 });
		setStartResizing({ x: 0, y: 0 });
		setTempText(null);
		toggleHover(true); // enables the blue line hover in calender
	};

	const handleResizing = (newSize: Resizing, resizingText: { start: TimeStamp; end: TimeStamp } | { error: string } | null) => {
		setIsResizing(newSize);
		if (resizingText) {
			setTempText(resizingText);
		}
	};

	const startPosition = useMemo(() => {
		if (!startElement) return null;
		return findElementFromTime(startElement);
	}, [startElement, parentScrollTop]);

	const endPosition = useMemo(() => {
		if (!endElement || !startElement) return null;
		return findElementFromTime(endElement);
	}, [endElement, startElement, parentScrollTop]);

	const calculatePosition = useMemo(() => {
		if (!startPosition || !endPosition) return { top: 0, left: 0, width: 0, height: 0 };
		const standardPos = calculateEventPosition(startPosition, endPosition, startElement, endElement, parentScrollTop, dragGrid, isResizing);

		return standardPos;
	}, [startPosition, endPosition, isResizing]);

	const calculateTextContent = useMemo(() => {
		if (!startElement || !endElement) return null;

		return calculateTemptext(startElement, endElement, tempText, calculatePosition.height);
	}, [startElement, endElement, tempText]);

	const handleDragEventStart = (calenderEvent: NewCalenderScheduleEvent) => {
		const elementId = `${calenderEvent.start.day}-${calenderEvent.start.time}/${calenderEvent.end.day}-${calenderEvent.end.time}`;
		const element = document.getElementById(elementId);
		if (element === null) return;
		const elementRect = element.getBoundingClientRect();
		const elementPosition = {
			x: elementRect.x,
			y: elementRect.y,
		};
		setIsDragging({ id: elementId, elementPosition });
	};

	// Debounce the handleDragEvent function, so it only runs every 10ms
	// This ensures the position of the element is "fully" updated and we dont run the function too often on fast dragging
	const handleDragEvent = debounce((calenderEvent: NewCalenderScheduleEvent) => {
		// find the element that is being dragged
		const elementId = `${calenderEvent.start.day}-${calenderEvent.start.time}/${calenderEvent.end.day}-${calenderEvent.end.time}`;
		if (isDragging === null || isDragging.id !== elementId) {
			setIsDragging(null);
			setTempText(null);
			return;
		}
		const element = document.getElementById(elementId);
		if (element === null) return;
		const elementRect = element.getBoundingClientRect();
		const elementPosition = {
			x: elementRect.x,
			y: elementRect.y,
		};

		// find the distance the element has been dragged
		const deltaX = elementPosition.x - isDragging.elementPosition.x;
		const deltaY = elementPosition.y - isDragging.elementPosition.y;

		// find the closest grid point
		const dayMovement = Math.round(deltaX / dragGrid[0]);
		const timeMovement = Math.round(deltaY / dragGrid[1]);
		const oneTimeMovement = 60 / markersPrHour; // 1 time movement in minutes

		const newStart = calculateNewDayAndTime(calenderEvent.start.day, calenderEvent.start.time, dayMovement, timeMovement, oneTimeMovement);
		const newEnd = calculateNewDayAndTime(calenderEvent.end.day, calenderEvent.end.time, dayMovement, timeMovement, oneTimeMovement);

		// check if the new position is valid
		const newEvent = { start: newStart, end: newEnd, id: eventId };
		if (validatePosition(newEvent)) {
			setTempText({ start: newStart, end: newEnd });
		} else {
			setTempText({ error: 'Invalid position' });
		}
	}, 10);

	const handleDragEventEnd = (calenderEvent: CalenderScheduleEvent) => {
		const elementId = `${calenderEvent.start.day}-${calenderEvent.start.time}/${calenderEvent.end.day}-${calenderEvent.end.time}`;
		if (isDragging === null || isDragging.id !== elementId) {
			setIsDragging(null);
			setTempText(null);
			return;
		}
		const element = document.getElementById(elementId);
		if (element === null) return;
		const elementRect = element.getBoundingClientRect();
		const elementPosition = {
			x: elementRect.x,
			y: elementRect.y,
		};

		// find the distance the element has been dragged
		const deltaX = elementPosition.x - isDragging.elementPosition.x;
		const deltaY = elementPosition.y - isDragging.elementPosition.y;

		// find the closest grid point
		const dayMovement = Math.round(deltaX / dragGrid[0]);
		const timeMovement = Math.round(deltaY / dragGrid[1]);
		const oneTimeMovement = 60 / markersPrHour; // 1 time movement in minutes

		const newStart = calculateNewDayAndTime(calenderEvent.start.day, calenderEvent.start.time, dayMovement, timeMovement, oneTimeMovement);
		const newEnd = calculateNewDayAndTime(calenderEvent.end.day, calenderEvent.end.time, dayMovement, timeMovement, oneTimeMovement);

		// check if any of the times are 24:00 and change them to 23:59
		if (newStart.time === '24:00') newStart.time = '23:59';
		if (newEnd.time === '24:00') newEnd.time = '23:59';

		const newEvent = { start: newStart, end: newEnd, id: calenderEvent.id };

		handleMoveEvent(calenderEvent, newEvent);

		setIsDragging(null);
		setTempText(null);
	};

	const handleResizeStart = (e: MouseEvent, handleType: HandleType) => {
		e.stopPropagation();
		toggleHover(false); // disables the blue line hover in calender
		document.body.style.userSelect = 'none'; // disables text selection while resizing
		const { clientX, clientY } = e;

		const getUpdatedPosition = (moveEvent: MouseEvent) => {
			const deltaX = moveEvent.clientX - clientX;
			const deltaY = moveEvent.clientY - clientY;

			let newWidth = 0;
			let newHeight = 0;
			let newLeft = 0;
			let newTop = 0;

			if (handleType === 'right' || handleType === 'top-right' || handleType === 'bottom-right') {
				newWidth = deltaX;
			} else if (handleType === 'left' || handleType === 'top-left' || handleType === 'bottom-left') {
				newLeft = deltaX;
			}

			if (handleType === 'bottom' || handleType === 'bottom-left' || handleType === 'bottom-right') {
				newHeight = deltaY;
			} else if (handleType === 'top' || handleType === 'top-left' || handleType === 'top-right') {
				newTop = deltaY;
			}

			// Update the event size and position
			const updatedPosition = {
				dir: handleType,
				top: newTop,
				left: newLeft,
				width: newWidth,
				height: newHeight,
			};
			return updatedPosition;
		};

		const getNewStartAndEnd = (moveEvent: MouseEvent) => {
			// find the distance the element has been dragged (this is actually the distance the mouse has been moved)
			const deltaX = moveEvent.clientX - clientX; // if deltaX is negative we are dragging to the left
			const deltaY = moveEvent.clientY - clientY; // if deltaY is negative we are dragging up

			let move = { x: 0, y: 0 };
			if (handleType === 'right' || handleType === 'top-right' || handleType === 'bottom-right') {
				if (deltaX < 0) {
					// when moving to the left from the right side we need to move the element dragGrid[0] - 20px for first tick and then dragGrid[0] for each tick
					let ticks = 0;
					if (Math.abs(deltaX) > dragGrid[0] - 20) {
						ticks++;
					}
					const newDeltaX = deltaX + (dragGrid[0] - 20);
					move.x = -ticks - Math.floor(Math.abs(newDeltaX) / dragGrid[0]);
				} else {
					// when moving to the right from the right side we need to move the element 15px for first tick and then dragGrid[0] for each tick
					let ticks = 0;
					if (deltaX > 15) {
						ticks++;
					}
					const newDeltaX = deltaX - 15;
					move.x = ticks + Math.floor(Math.abs(newDeltaX) / dragGrid[0]);
				}
			} else if (handleType === 'left' || handleType === 'top-left' || handleType === 'bottom-left') {
				if (deltaX < 0) {
					// when moving to the left from the left side we need to move the element 15px for first tick and then dragGrid[0] for each tick
					let ticks = 0;
					if (deltaX < -15) {
						ticks++;
					}
					const newDeltaX = deltaX + 15;
					move.x = -ticks - Math.floor(Math.abs(newDeltaX) / dragGrid[0]);
				} else {
					// when moving to the right from the left side we need to move the element dragGrid[0] - 20px for first tick and then dragGrid[0] for each tick
					let ticks = 0;
					if (deltaX > dragGrid[0] - 20) {
						ticks++;
					}
					const newDeltaX = deltaX - (dragGrid[0] - 20);
					move.x = ticks + Math.floor(Math.abs(newDeltaX) / dragGrid[0]);
				}
			}

			if (handleType === 'bottom' || handleType === 'bottom-left' || handleType === 'bottom-right') {
				move.y = Math.round(deltaY / dragGrid[1]);
			} else if (handleType === 'top' || handleType === 'top-left' || handleType === 'top-right') {
				move.y = Math.round(deltaY / dragGrid[1]);
			}

			const oneTimeMovement = 60 / markersPrHour; // 1 time movement in minutes

			const start = startElement;
			const end = endElement;

			let { start: sortedStart, end: sortedEnd } = orderEventStartAndEnd(start, end, eventId);

			if (handleType.includes('top') || handleType.includes('left')) {
				sortedStart = calculateNewDayAndTime(sortedStart.day, sortedStart.time, move.x, move.y, oneTimeMovement);
				if (handleType === 'bottom-left') {
					// start time cannot be moved when resizing from the bottom left
					sortedStart.time = start.time;
					// end day cannot be moved when resizing from the bottom left
					sortedEnd.day = end.day;
				}
				if (handleType === 'top-right' && sortedEnd.day !== end.day) {
					// if we are resizing from the top right and the end day has changed, the start day should not change
					sortedStart.day = start.day;
				}
			}

			if (handleType.includes('bottom') || handleType.includes('right')) {
				sortedEnd = calculateNewDayAndTime(sortedEnd.day, sortedEnd.time, move.x, move.y, oneTimeMovement);
				if (handleType === 'top-right') {
					// end time cannot be moved when resizing from the top right
					sortedEnd.time = end.time;
					// start day cannot be moved when resizing from the top right
					sortedStart.day = start.day;
				}
				if (handleType === 'bottom-left' && sortedStart.day !== start.day) {
					// if we are resizing from the bottom left and the start day has changed, the end day should not change
					sortedEnd.day = end.day;
				}
			}

			// check if the any of the times are 24:00 and change them to 23:59
			if (sortedStart.time === '24:00') sortedStart.time = '23:59';
			if (sortedEnd.time === '24:00') sortedEnd.time = '23:59';

			return { start: sortedStart, end: sortedEnd };
		};

		const handleResizeMove = (moveEvent: MouseEvent) => {
			const updatedPosition = getUpdatedPosition(moveEvent);

			const { start: newStart, end: newEnd } = getNewStartAndEnd(moveEvent);

			const validPosition = validatePosition({ start: newStart, end: newEnd, id: eventId });

			handleResizing(updatedPosition, !validPosition ? { error: 'Invalid resizing' } : { start: newStart, end: newEnd });
		};

		//TODO: The types are so weird here!
		const handleResizeMoveWrapper = (moveEvent: Event) => {
			handleResizeMove(moveEvent as unknown as MouseEvent);
		};

		const getNewStartAndEndWrapper = (moveEvent: Event) => {
			return getNewStartAndEnd(moveEvent as unknown as MouseEvent);
		};

		const handleResizeEnd = (moveEvent: Event) => {
			document.removeEventListener('mousemove', handleResizeMoveWrapper);
			document.removeEventListener('mouseup', handleResizeEnd);

			updateEventSizeAndPosition({ start: startElement, end: endElement, id: eventId }, { ...getNewStartAndEndWrapper(moveEvent), id: eventId });
			finishResizing();
			document.body.style.userSelect = 'auto';
		};

		if (startResizing.x === 0 && startResizing.y === 0) {
			setStartResizing({ x: e.clientX, y: e.clientY });
		}

		document.addEventListener('mousemove', handleResizeMoveWrapper);
		document.addEventListener('mouseup', handleResizeEnd);
	};

	if (!startPosition || !endPosition) return null;

	const boxStyles = {
		position: 'absolute',
		top: `${calculatePosition.top}px`,
		left: `${calculatePosition.left}px`,
		width: `${calculatePosition.width}px`,
		height: `${calculatePosition.height}px`,
		backgroundColor: `${BrandAccentBlue}88`,
		zIndex: 1,
		pointerEvents: 'all',
		borderRadius: '5px',
		boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
		overflow: 'hidden',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		padding: '0 5px',
		cursor: editable ? 'grab' : 'auto',
	};

	return (
		<Draggable
			onStart={() => {
				handleDragEventStart({ start: startElement, end: endElement }); // this is used to get the DOM id of the element being dragged
			}}
			onDrag={() => {
				handleDragEvent({ start: startElement, end: endElement }); // this is used to get the position of the element being dragged
			}}
			onStop={() => {
				handleDragEventEnd({ start: startElement, end: endElement, id: eventId }); // this is used to get the DOM id of the element being dragged and the eventId
			}}
			grid={dragGrid} // this is used to move the element in controlled steps (calculated in parent component)
			position={{ x: 0, y: 0 }} // this is such that we can control the position of the element being dragged, it never has to change because we snap it to the table
			nodeRef={draggableRef}
			bounds={editable ? false : { top: 0, right: 0, bottom: 0, left: 0 }}
		>
			<Box sx={boxStyles} id={`${startElement.day}-${startElement.time}/${endElement.day}-${endElement.time}`} ref={draggableRef}>
				{editable && (
					<Button
						sx={{ position: 'absolute', top: 0, right: 0, m: 0, p: 0, minWidth: 'unset', zIndex: 3 }}
						onClick={(e) => {
							e.stopPropagation(); // this is used to prevent the event from being dragged when the delete button is clicked
							deleteEvent(eventId);
						}}
					>
						<HighlightOffIcon sx={{ fontSize: '14px', p: 0, m: 0, minWidth: 'unset' }} />
					</Button>
				)}
				{editable && <ResizeBoxes handleResizeStart={handleResizeStart} id={`${startElement.day}-${startElement.time}/${endElement.day}-${endElement.time}`} />}
				<RegularText sx={{ m: 0 }}>On</RegularText>
				{calculatePosition.height > 30 && <RegularText sx={{ fontSize: '10px', m: 0 }}>{calculateTextContent}</RegularText>}
			</Box>
		</Draggable>
	);
};

export default Event;
