import { BrandDarkBlue } from "../theme";

export const AxisLabelStyles = {
  fontFamily: "DM Sans",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "12px",
  color: "#FFFFFF",
  opacity: 0.7,
};

export const ChartTitleProps = {
  align: "left",
  margin: 20,
  style: {
    fontFamily: "DM Sans",
    fontWeight: 400,
    color: "#FFFFFF",
    fontSize: "18px",
    marginBottom: "12px",
  },
};

export const YAxisProps = {
  gridLineWidth: 0.5,
  gridLineColor: "#5f3492",
  lineColor: "#5f3492",
  lineWidth: 0.5,
  title: "",
};

export const XAxisProps = {
  type: "datetime",
  gridLineWidth: 0.5,
  gridLineColor: "#5f3492",
  lineColor: "#5f3492",
  lineWidth: 0.5,
  tickColor: BrandDarkBlue,
  maxPadding: 0,
  dateTimeLabelFormats: {
    day: "%e %b",
  },
};
