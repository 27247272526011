import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const SchedulingIcon = ({ strokeColor, ...props }) => (
	<SvgIcon sx={{ width: 24, height: 24, fill: 'none' }} viewBox="0 0 24 24" fill="none" {...props}>
		<rect x="2.5" y="3.5" width="19" height="17" rx="2" stroke={strokeColor || 'white'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
		<line x1="5.5" y1="2.5" x2="5.5" y2="6.5" stroke={strokeColor || 'white'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
		<line x1="18.5" y1="2.5" x2="18.5" y2="6.5" stroke={strokeColor || 'white'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
		<line x1="2.5" y1="8.5" x2="21.5" y2="8.5" stroke={strokeColor || 'white'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
	</SvgIcon>
);

export default SchedulingIcon;
