import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { BrandDarkBlue } from '../theme';

const ChartContainer = styled(Box)(({ padding }) => ({
	padding: padding || '16px',
	background: BrandDarkBlue,
	borderRadius: '6px',
}));

export default ChartContainer;
