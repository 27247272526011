import React, {ChangeEvent, useContext, useState} from 'react';
import {Box, Button, Stack, Typography, FormControlLabel, Tooltip} from '@mui/material';
import {DarkGrey, SubtleGrey, TextfieldSelect, SwitchToggle, useWindowDimensions, GreyColor} from '@Iot-Bee/standard-web-library';
import DateSelector from '../../../components/form/DateSelector';
import {ChartContext} from './ChartContext';
import MobileFilter from './MobileFilter';
import TuneIcon from '@mui/icons-material/Tune';
import InfoIcon from '@mui/icons-material/Info';

const DataFilter = () => {
	const [mobileFilterOpen, setMobileFilterOpen] = useState(false);
	const widths = {
		sensor: '145px',
		dateSelector: '155px',
		calibration: '160px',
		checkfield: '175px',
	};
	const {
		sensor,
		handleChangeSensor,
		availableSensors,
		calibration,
		handleCalibrationChange,
		calibrations,
		fromDate,
		setFromDate,
		toDate,
		setToDate,
		showDataPoints,
		setShowDataPoints,
		unaccumulate,
		handleAccumulateToDailyChange,
		loadingGraphData,
		isPowerDevice,
	} = useContext(ChartContext);

	const {screenwidth} = useWindowDimensions();

	if (screenwidth > 768) {
		return (
			<Stack direction="row" spacing={4} sx={{p: '20px', background: SubtleGrey}}>
				<TextfieldSelect
					label="Sensor"
					placeholder="Select reading"
					options={availableSensors}
					value={sensor || ''}
					onChange={handleChangeSensor}
					width={widths.sensor}
					disabled={loadingGraphData.loading}
				/>
				<DateSelector
					label="From date"
					value={fromDate}
					onChange={setFromDate}
					disableDayFunction={(date) => {
						return date.isAfter(toDate) && !(date.date() === toDate.date() && date.month() === toDate.month() && date.year() === toDate.year());
					}}
					disableFuture={true}
					disabled={loadingGraphData.loading}
					sx={{width: widths.dateSelector}}
				/>
				<DateSelector
					label="To date"
					value={toDate}
					onChange={setToDate}
					disableDayFunction={(date) => {
						return date.isBefore(fromDate) && !(date.date() === fromDate.date() && date.month() === fromDate.month() && date.year() === fromDate.year());
					}}
					disableFuture={true}
					disabled={loadingGraphData.loading}
					sx={{width: widths.dateSelector}}
				/>
				<TextfieldSelect
					label="Select calibration"
					width={widths.calibration}
					options={calibrations.map((item) => item.name)}
					value={calibration !== null && calibration.name ? calibration.name : ''}
					onChange={handleCalibrationChange}
					showNone={true}
					disabled={loadingGraphData.loading}
				/>
				<FormControlLabel
					sx={{
						textDecoration: 'none',
						fontStyle: 'normal',
						fontWeight: 500,
						fontSize: '14px',
						lineHeight: '120%',
						width: widths.checkfield,
					}}
					control={
						<SwitchToggle
							checked={showDataPoints}
							onChange={(ev: ChangeEvent<HTMLInputElement>) => {
								setShowDataPoints(ev.target.checked);
							}}
							name="showDataPoints"
						/>
					}
					label={
						<Typography
							sx={{
								color: '#111111',
								fontStyle: 'normal',
								fontWeight: 500,
								fontSize: '14px',
								ml: '11px',
							}}>
							Show data points
						</Typography>
					}
					disabled={loadingGraphData.loading}
				/>
				{isPowerDevice && (
					<FormControlLabel
						sx={{
							textDecoration: 'none',
							fontStyle: 'normal',
							fontWeight: 500,
							fontSize: '14px',
							lineHeight: '120%',
							width: widths.checkfield,
						}}
						control={
							<SwitchToggle
								checked={unaccumulate}
								onChange={(ev: ChangeEvent<HTMLInputElement>) => {
									handleAccumulateToDailyChange(ev.target.checked);
								}}
								name="unaccumulate"
							/>
						}
						label={
							<Box sx={{display: 'flex', alignItems: 'center'}}>
								<Typography
									sx={{
										color: '#111111',
										fontStyle: 'normal',
										fontWeight: 500,
										fontSize: '14px',
										ml: '11px',
									}}>
									Unaccumulate
								</Typography>
								<Tooltip title="Unaccumulate kwh data to show incremental changes" arrow>
									<InfoIcon sx={{fontSize: '18px', ml: '5px', color: GreyColor}} />
								</Tooltip>
							</Box>
						}
						disabled={loadingGraphData.loading || sensor !== 'kwh'}
					/>
				)}
			</Stack>
		);
	}

	return (
		<Box sx={{p: '20px', background: SubtleGrey, display: 'flex', flexFlow: 'row wrap', justifyContent: 'space-between', gap: '10px', alignItems: 'stretch'}}>
			<TextfieldSelect label="Sensor" placeholder="Select reading" options={availableSensors} value={sensor || ''} onChange={handleChangeSensor} sx={{width: '166px'}} />
			<Button onClick={() => setMobileFilterOpen(true)}>
				<TuneIcon sx={{color: DarkGrey}} />
			</Button>

			<MobileFilter open={mobileFilterOpen} onClose={() => setMobileFilterOpen(false)} />
		</Box>
	);
};

export default DataFilter;
