import React, { FC, ReactNode, useEffect } from 'react';

import { Box } from '@mui/material';

import { AppTopBar, AppDrawer, useWindowDimensions, MobileMenu } from '@Iot-Bee/standard-web-library';

import { TopPadding } from './theme';
import { noCacheFetcher } from './utils/fetch';
import sideMenus from './components/menu/menuitems';

const getSideBarWidth = (screenwidth) => {
	return screenwidth * 0.15 < 200 && screenwidth > 768 ? '220px' : screenwidth > 768 ? '220px' : '0';
};

interface AppProps {
	children: ReactNode;
}

const App: FC<AppProps> = ({ children }) => {
	const { screenwidth } = useWindowDimensions();

	useEffect(() => {
		// check that the user is logged in
		noCacheFetcher(`${process.env.REACT_APP_API_URL}checkloggedin`)
			.then((_data) => {
				// do nothing
			})
			.catch((e) => {
				localStorage.clear();
				window.location.href = '/login';
			});
	}, []);

	const handleLogout = () => {
		localStorage.clear();
		window.location.href = '/login';
	};

	return (
		<Box>
			<AppTopBar handleLogout={handleLogout} />
			{screenwidth > 768 ? <AppDrawer menuItems={sideMenus} handleLogout={() => handleLogout()} /> : <MobileMenu menuItems={sideMenus} handleLogout={() => handleLogout()} />}
			<Box
				component="main"
				sx={{
					pl: `${getSideBarWidth(screenwidth)}`,
					pt: `${TopPadding}px`,
					height: `calc(100vh - ${TopPadding}px)`,
					width: `calc(100vw - ${getSideBarWidth(screenwidth)})`,
				}}
			>
				<Box sx={{ padding: screenwidth > 768 ? '30px' : '30px 2.5vw' }}>{children}</Box>
			</Box>
		</Box>
	);
};

export default App;
