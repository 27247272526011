import dayjs from 'dayjs';
import { AxisLabelStyles, ChartTitleProps, YAxisProps, XAxisProps } from '../../../components/highchartUtils';
import { BrandDarkBlue, GreenColor } from '../../../theme';
import { GraphData } from '../../../Types/types';

function calcMinimumY(graphData: GraphData[]) {
	if (graphData.length === 0) return 0;
	const min = graphData.reduce((prev, curr) => (prev[1] < curr[1] ? prev : curr))[1];
	const calculated = min * 0.9999;
	return Math.floor(calculated);
}

function calcMaximumY(graphData: GraphData[]) {
	if (graphData.length === 0) return 100;
	const max = graphData.reduce((prev, curr) => (prev[1] > curr[1] ? prev : curr))[1];

	const calculated = max * 1.0001;

	return Math.ceil(calculated);
}

export default function getDefaultGraphSettings(showDataPoints: boolean, graphData: GraphData[], sensor: string) {
	return {
		time: {
			getTimezoneOffset: function (timestamp: string) {
				return new Date(timestamp).getTimezoneOffset();
			},
		},
		chart: {
			backgroundColor: BrandDarkBlue,
			zoomType: 'x',
			spacing: [30, 10, 10, 10],
		},
		boost: {
			useGPUTranslations: true,
		},
		title: {
			...ChartTitleProps,
			text: sensor,
			style: {
				color: 'white',
				fontFamily: 'DM Sans',
				textTransform: 'capitalize',
			},
			margin: 0,
			y: 0,
			verticalAlign: 'top',
		},
		legend: {
			enabled: false,
		},
		yAxis: {
			...YAxisProps,
			labels: {
				style: AxisLabelStyles,
			},
			min: calcMinimumY(graphData), // this scales the y-axis to show small changes better
			max: calcMaximumY(graphData), // this scales the y-axis to show small changes better
		},
		xAxis: {
			...XAxisProps,
			type: 'datetime',
			labels: {
				y: 25,
				style: AxisLabelStyles,
			},
		},
		plotOptions: {
			series: {
				lineWidth: 3,
				// pointStart: Date.UTC(2023, 1, 7),
				// pointInterval: 2 * 24 * 3600 * 1000, // two day
			},
			area: {
				marker: {
					enabled: false,
					symbol: 'circle',
					radius: 3,
					states: {
						hover: {
							enabled: true,
						},
					},
				},
			},
		},
		tooltip: {
			enabled: true,
			backgroundColor: '#ffffff',
			useHTML: true,
			formatter: function (this: any) {
				const date = dayjs(new Date(this.x));
				const newDate = date.format('dddd, MMMM D, HH:mm:ss');

				const toFixedIfNecessary = (value: string, dp: number) => {
					return +parseFloat(value).toFixed(dp);
				};

				return `<div style="margin: 0;"><p style="font-size: 11px; margin: 0;">${newDate}<p>
										<p style="color:${GreenColor}; margin: 0; display:inline-block;">●</p>
										<p style="font-size: 13px; margin: 0; display:inline-block;">Value: <b>${toFixedIfNecessary(this.y, 4)}</b></p>
								</div>`;
			},
		},

		series: [
			{
				fillColor: {
					linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
					stops: [
						[0.45, 'rgba(39, 206, 126, 0.45)'], // start
						[1, 'rgba(39, 206, 126, 0)'], // end
					],
				},
				type: 'area',
				name: 'Calibration data',
				color: GreenColor,
				dashStyle: 'solid',
				data: graphData,
				marker: {
					enabled: showDataPoints,
					symbol: 'circle',
					radius: 3,
					lineWidth: 1,
					lineColor: '#ffffffbb',
					states: {
						hover: {
							enabled: true,
						},
					},
				},
			},
		],
	};
}
