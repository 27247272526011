import React, {useEffect, useState} from 'react';
import {Card, IOTTableWrapper, PrimaryButton, DeleteDialog, useSnackbar} from '@Iot-Bee/standard-web-library';

import SchedulingDialog from './components/SchedulingDialog';
import {CalenderSchedule, DBWeekDays, Device, DeviceNameAndUsed, EditSchedule, Schedule} from '../../Types/types';
import {cacheFetcher, cacheKeys} from '../../utils/fetch';
import {backendScheduleToCalenderSchedule, deleteItemFromArrayInLocalStorage, schedulingLocalStorageUpdateCallback, userDevicesLocalStorageUpdateCallback} from '../../utils/localstorageHandlers';
import {LocalstorageData} from '../../Types/LocalStorageData';
import {moveDevicesSchedule} from './Utils/moveDeviceSchedule';

const Scheduling = () => {
	const [schedules, setSchedules] = useState<CalenderSchedule[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	const [dialogInfo, setDialogInfo] = useState<{open: boolean; mode: 'add' | 'edit'; eventToEdit: CalenderSchedule}>({
		open: false,
		mode: 'add',
		eventToEdit: {
			id: schedules.reduce((acc, s) => {
				return Math.max(s.id, acc);
			}, 1),
		} as CalenderSchedule,
	});

	const [deleteDialog, setDeleteDialog] = useState<{open: boolean; schedule: CalenderSchedule | undefined}>({open: false, schedule: undefined});

	const [devices, setDevices] = useState<DeviceNameAndUsed[]>([]);

	const {
		actions: {openSnackbar},
	} = useSnackbar();

	useEffect(() => {
		//fetch schedules
		cacheFetcher<CalenderSchedule[]>(process.env.REACT_APP_API_URL + 'schedules', cacheKeys.schedules, schedulingLocalStorageUpdateCallback, true)
			.then((schedules) => {
				cacheFetcher<Device[]>(process.env.REACT_APP_API_URL + `getids`, cacheKeys.userDevices, userDevicesLocalStorageUpdateCallback, true)
					.then((data) => {
						setDevices([
							{name: 'Select all free devices', isUsed: '', selectAll: true},
							...data.map((device) => {
								const isUsed = schedules.find((schedule) => schedule.devices.includes(device.deviceName));
								return {name: device.deviceName, isUsed: isUsed ? isUsed.name : ''};
							}),
						]);
						setSchedules(schedules);
					})
					.catch((error) => {
						console.error(error);
					});
			})
			.catch((error) => {
				console.error(error);
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, []);

	const resetDialogInfo = (open: boolean) => {
		setDialogInfo({
			open: open,
			mode: 'add',
			eventToEdit: {
				id:
					schedules.reduce((acc, s) => {
						return Math.max(s.id, acc);
					}, 1) + 1,
			} as CalenderSchedule,
		});
	};

	const handleAddSchedule = (schedule: EditSchedule) => {
		const devices = schedule.devices.map((device: DeviceNameAndUsed) => {
			return device.name;
		});

		const body: Omit<CalenderSchedule, 'id' | 'allDevices'> = {
			name: schedule.name,
			events: schedule.events,
			devices: devices,
			temperatures: schedule.temperatures,
		};

		cacheFetcher<Schedule>(
			process.env.REACT_APP_API_URL + 'schedule',
			cacheKeys.schedules,
			(addRes, cacheKey) => {
				if (addRes.success) {
					let allDevices = schedule.devices
						.map((device) => {
							return device.name;
						})
						.join(', ');
					if (allDevices.length > 50) {
						allDevices = allDevices.slice(0, 50);
						allDevices += '...';
					}

					const oldLocalStorage: LocalstorageData<CalenderSchedule[]> = JSON.parse(localStorage.getItem(cacheKey) || '[]');
					const oldData = oldLocalStorage.data;

					const newSchedule = addRes.message;
					const mapped = backendScheduleToCalenderSchedule(newSchedule);
					mapped.allDevices = mapped.devices.join(', ');
					if (mapped.allDevices.length > 50) {
						mapped.allDevices = mapped.allDevices.substring(0, 50) + '...';
					}

					const moved = moveDevicesSchedule(schedule.devices, [...oldData, mapped]);

					const newLocalStorage: LocalstorageData<CalenderSchedule[]> = {
						createdDate: new Date(),
						data: moved,
					};
					localStorage.setItem(cacheKey, JSON.stringify(newLocalStorage));
				}
			},
			false,
			{method: 'POST', body: JSON.stringify(body)}
		)
			.then((data) => {
				const mapped = backendScheduleToCalenderSchedule(data);
				mapped.allDevices = mapped.devices.join(', ');
				if (mapped.allDevices.length > 50) {
					mapped.allDevices = mapped.allDevices.substring(0, 50) + '...';
				}

				setSchedules((prev) => {
					const moved = moveDevicesSchedule(schedule.devices, [...prev, mapped]);
					return moved;
				});
				setDevices((prev) => {
					return prev.map((device) => {
						if (devices.includes(device.name)) {
							return {...device, isUsed: schedule.name};
						}
						return device;
					});
				});
				openSnackbar('success', 'Schedule added successfully!');
			})
			.catch((error) => {
				console.error(error);
				openSnackbar('error', 'Failed to add schedule');
			});
		resetDialogInfo(false);
	};

	const handleDelete = (schedule: CalenderSchedule) => {
		cacheFetcher(
			`${process.env.REACT_APP_API_URL}schedule/${schedule.id}`,
			cacheKeys.schedules,
			(data) => {
				if (data.success) {
					deleteItemFromArrayInLocalStorage<CalenderSchedule>((item) => item.id === schedule.id, cacheKeys.schedules);
				}
			},
			false,
			{method: 'DELETE'}
		)
			.then((_res) => {
				setSchedules((prev) => prev.filter((s) => s.id !== schedule.id));
				setDevices((prev) => {
					return prev.map((device) => {
						if (schedule.devices.includes(device.name)) {
							return {...device, isUsed: ''};
						}
						return device;
					});
				});
				openSnackbar('success', 'Schedule deleted successfully!');
			})
			.catch((error) => {
				console.error(error);
				openSnackbar('error', 'Failed to delete schedule');
			});
	};

	interface UpdateScheduleResponse {
		clientId: number;
		createdDate: string;
		devices: {id: string; imei: string; clientId: number; friendlyName: string; scheduleId: number}[];
		id: number;
		name: string;
		scheduleEvents: {id: number; scheduleId: number; startTime: `${string}:${string}`; endTime: `${string}:${string}`; days: DBWeekDays[]}[];
	}

	const handleUpdateSchedule = (schedule: EditSchedule) => {
		const mappedDevices = schedule.devices.map((device: DeviceNameAndUsed) => {
			return device.name;
		});

		const calenderSchedule: CalenderSchedule = {
			id: schedule.id,
			name: schedule.name,
			events: schedule.events,
			devices: mappedDevices,
			allDevices: '',
			temperatures: schedule.temperatures,
		};

		calenderSchedule.allDevices = calenderSchedule.devices.join(', ');
		if (calenderSchedule.allDevices.length > 50) {
			calenderSchedule.allDevices = calenderSchedule.allDevices.substring(0, 50) + '...';
		}

		cacheFetcher<UpdateScheduleResponse>(
			`${process.env.REACT_APP_API_URL}schedule/${schedule.id}`,
			cacheKeys.schedules,
			(data, cacheKey) => {
				if (data.success) {
					const oldLocalStorage: LocalstorageData<CalenderSchedule[]> = JSON.parse(localStorage.getItem(cacheKey) || '[]');
					const oldData = oldLocalStorage.data;
					const calenderSchedule: CalenderSchedule = {
						id: data.message.id,
						name: schedule.name,
						devices: mappedDevices,
						events: schedule.events,
						allDevices: mappedDevices.join(', '),
						temperatures: schedule.temperatures,
					};
					if (calenderSchedule.allDevices.length > 50) {
						calenderSchedule.allDevices = calenderSchedule.allDevices.substring(0, 50) + '...';
					}

					const oldScheduleIndex = oldData.findIndex((s) => s.id === schedule.id);
					oldData[oldScheduleIndex] = calenderSchedule;

					const moved = moveDevicesSchedule(schedule.devices, [...oldData]);

					const newLocalStorage: LocalstorageData<CalenderSchedule[]> = {
						createdDate: new Date(),
						data: moved,
					};
					localStorage.setItem(cacheKey, JSON.stringify(newLocalStorage));
				}
			},
			false,
			{
				method: 'PUT',
				body: JSON.stringify({
					name: schedule.name,
					events: schedule.events,
					devices: mappedDevices,
					temperatures: schedule.temperatures,
				}),
			}
		)
			.then((data) => {
				const oldSchedules = [...schedules];
				const oldScheduleIndex = oldSchedules.findIndex((s) => s.id === schedule.id);
				oldSchedules[oldScheduleIndex] = calenderSchedule;

				const newSchedules = moveDevicesSchedule(schedule.devices, [...oldSchedules]);

				setSchedules(newSchedules);
				resetDialogInfo(false);
				openSnackbar('success', 'Schedule updated successfully!');
			})
			.catch((error) => {
				console.error(error);
				openSnackbar('error', 'Failed to update schedule');
			});
	};

	const columns = [
		{
			id: 'name',
			title: 'Name',
			width: 250,
			priority: 1,
			isKey: true,
		},

		{
			id: 'allDevices',
			title: 'Devices',
			width: 200,
			priority: 3,
		},
	];

	return (
		<Card
			title="Scheduling"
			headerActions={
				<PrimaryButton
					onClick={() => {
						resetDialogInfo(true);
					}}>
					Add new schedule
				</PrimaryButton>
			}>
			<IOTTableWrapper
				columns={columns}
				data={schedules}
				onEdit={(eventItem) => {
					setDialogInfo({open: true, mode: 'edit', eventToEdit: eventItem});
				}}
				onDelete={(itemToDelete) => {
					setDeleteDialog({open: true, schedule: itemToDelete});
				}}
				isLoading={isLoading}
			/>
			<SchedulingDialog
				schedule={dialogInfo.eventToEdit}
				open={dialogInfo.open}
				allClientDevices={devices}
				onClose={() => {
					resetDialogInfo(false);
				}}
				onSave={(schedule) => {
					if (dialogInfo.mode === 'edit') {
						handleUpdateSchedule(schedule);
					} else {
						handleAddSchedule(schedule);
					}
				}}
			/>
			<DeleteDialog
				title={deleteDialog.schedule?.name || ''}
				open={deleteDialog.open}
				handleDelete={() => {
					if (!deleteDialog.schedule) return;
					handleDelete(deleteDialog.schedule);
					setDeleteDialog((prev) => ({...prev, open: false}));
				}}
				onClose={() => setDeleteDialog((prev) => ({...prev, open: false}))}
				itemName={deleteDialog.schedule?.name || ''}
			/>
		</Card>
	);
};

export default Scheduling;
