import React, {useState, useEffect} from 'react';
import {AgChartsReact} from 'ag-charts-react';
import {AgChartOptions, AgDonutSeriesOptions} from 'ag-charts-community';
import {Box} from '@mui/material';
import {useWindowDimensions} from '@Iot-Bee/standard-web-library';
import {fills, insideOutsideFills} from '../themes';

interface DonutChartProps {
	theme: ChartTheme;
	donutData: DonutData[];
}
export const DonutChart = ({theme, donutData}: DonutChartProps) => {
	const [options, setOptions] = useState<AgChartOptions>({});
	const {screenwidth} = useWindowDimensions();

	const myTheme = {
		baseTheme: theme.baseTheme,
		palette: {
			fills: theme.mode === 'total' ? insideOutsideFills : fills,
			strokes: theme.mode === 'total' ? insideOutsideFills : fills,
		},
	};
	useEffect(() => {
		const seriesOptions: AgDonutSeriesOptions = {
			type: 'donut',
			legendItemKey: 'asset',
			angleKey: 'amount',
			innerRadiusRatio: 0.6,
			showInLegend: donutData.length > 10 ? false : true,
			innerLabels: [
				{
					text: String(parseFloat(donutData.reduce((acc, item) => acc + item.amount, 0).toFixed(0))) + ' kWh',
					margin: 4,
					fontSize: calculateFontSize(),
					color: theme.textColor,
				},
			],
		};
		
		window.addEventListener('resize', () => {
			const newFontSize = calculateFontSize();
			if (seriesOptions && seriesOptions.innerLabels) {
				seriesOptions.innerLabels[0].fontSize = newFontSize;
			}
		});

		setOptions({
			data: donutData,
			theme: myTheme,
			title: {
				text: theme.displayText,
			},
			series: [seriesOptions],
			background: {
				fill: theme.backgroundColor,
			},
		});
	}, [donutData]);

	const calculateFontSize = () => {
		const baseSize = 6; // Base font size for smallest screens
		const maxSize = 20; // Maximum font size for largest screens
		const maxWidth = 1920; // The width at which the font size will be maxSize
		const maxHeight = 1080; // The height at which the font size will be maxSize

		// Calculate width-based and height-based font size
		const widthFontSize = baseSize + (maxSize - baseSize) * (window.innerWidth / maxWidth);
		const heightFontSize = baseSize + (maxSize - baseSize) * (window.innerHeight / maxHeight);

		// Use the smaller of the two font sizes to ensure it fits both dimensions
		const fontSize = Math.min(maxSize, widthFontSize, heightFontSize);

		return fontSize;
	};

	return (
		<Box sx={{width: screenwidth > 768 ? '33.333%' : '100%', height: '100%'}}>
			<Box sx={{width: '100%', height: screenwidth > 768 ? '100%' : '330px'}}>
				<AgChartsReact options={options} />
			</Box>
		</Box>
	);
};
