import React, { FC, Fragment, MouseEvent } from 'react';
import { Box } from '@mui/material';
import { HandleType } from '../Types/Types';

interface ResizeBoxesProps {
	handleResizeStart: (e: MouseEvent, direction: HandleType) => void;
	id: string;
	show?: boolean;
}

const ResizeBoxes: FC<ResizeBoxesProps> = ({ handleResizeStart, id, show = false }) => {
	return (
		<Fragment>
			<Box
				id={`${id}-top`}
				className="resize-handle top"
				onMouseDown={(e) => handleResizeStart(e, 'top')}
				sx={{ position: 'absolute', top: 0, left: 10, height: '10px', width: 'calc(100% - 20px)', backgroundColor: show ? 'red' : 'transparent', cursor: 'ns-resize' }}
			/>
			<Box
				id={`${id}-top-right`}
				className="resize-handle top-right"
				onMouseDown={(e) => handleResizeStart(e, 'top-right')}
				sx={{ position: 'absolute', top: 0, right: 0, height: '10px', width: '10px', backgroundColor: show ? 'black' : 'transparent', cursor: 'nesw-resize' }}
			/>
			<Box
				id={`${id}-right`}
				className="resize-handle right"
				onMouseDown={(e) => handleResizeStart(e, 'right')}
				sx={{ position: 'absolute', top: '10px', right: 0, height: 'calc(100% - 20px)', width: '10px', backgroundColor: show ? 'green' : 'transparent', cursor: 'ew-resize' }}
			/>
			<Box
				id={`${id}-bottom-right`}
				className="resize-handle bottom-right"
				onMouseDown={(e) => handleResizeStart(e, 'bottom-right')}
				sx={{ position: 'absolute', bottom: 0, right: 0, height: '10px', width: '10px', backgroundColor: show ? 'white' : 'transparent', cursor: 'nwse-resize' }}
			/>
			<Box
				id={`${id}-bottom`}
				className="resize-handle bottom"
				onMouseDown={(e) => handleResizeStart(e, 'bottom')}
				sx={{ position: 'absolute', bottom: 0, left: '10px', height: '10px', width: 'calc(100% - 20px)', backgroundColor: show ? 'purple' : 'transparent', cursor: 'ns-resize' }}
			/>
			<Box
				id={`${id}-bottom-left`}
				className="resize-handle bottom-left"
				onMouseDown={(e) => handleResizeStart(e, 'bottom-left')}
				sx={{ position: 'absolute', bottom: 0, left: 0, height: '10px', width: '10px', backgroundColor: show ? 'yellow' : 'transparent', cursor: 'nesw-resize' }}
			/>
			<Box
				id={`${id}-left`}
				className="resize-handle left"
				onMouseDown={(e) => handleResizeStart(e, 'left')}
				sx={{ position: 'absolute', top: '10px', left: 0, height: 'calc(100% - 20px)', width: '10px', backgroundColor: show ? 'orange' : 'transparent', cursor: 'ew-resize' }}
			/>
			<Box
				id={`${id}-top-left`}
				className="resize-handle top-left"
				onMouseDown={(e) => handleResizeStart(e, 'top-left')}
				sx={{ position: 'absolute', top: 0, left: 0, height: '10px', width: '10px', backgroundColor: show ? 'blue' : 'transparent', cursor: 'nwse-resize' }}
			/>
		</Fragment>
	);
};

export default ResizeBoxes;
