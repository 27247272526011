import { LocalstorageData } from '../Types/LocalStorageData';
import FetchResponse from '../Types/FetchResponse';

// this is what the items in cache (localstorage) is called
type CacheKey =
	| 'userDevices'
	| `calibrationsMap-${string}`
	| 'alerts'
	| 'clientIdSensors'
	| 'calibrations'
	| 'clientDataUsage'
	| `${string}-data`
	| 'deviceSettings'
	| 'schedules'
	| 'webusers'
	| 'settings'
	| 'deviceTags';

// these are types to make the maps more readable
type CacheKeys = 'userDevices' | 'alerts' | 'clientIdSensors' | 'calibrations' | 'clientDataUsage' | 'deviceSettings' | 'schedules' | 'webusers' | 'settings' | 'deviceTags';
type CalculationKeys = 'calibrationsMap' | 'data';

export const calculatedCacheKeys: Record<CalculationKeys, (deviceId: string) => CacheKey> = {
	calibrationsMap: (deviceId: string) => `calibrationsMap-${deviceId}`,
	data: (deviceId: string) => `${deviceId}-data`,
} as const;

export const cacheKeys: Record<CacheKeys, CacheKey> = {
	userDevices: 'userDevices',
	alerts: 'alerts',
	clientIdSensors: 'clientIdSensors',
	calibrations: 'calibrations',
	clientDataUsage: 'clientDataUsage',
	deviceSettings: 'deviceSettings',
	schedules: 'schedules',
	webusers: 'webusers',
	settings: 'settings',
	deviceTags: 'deviceTags',
} as const;

function cacheFetcher<T>(
	url: string,
	cacheKey: CacheKey,
	localStorageUpdateCallback: (data: FetchResponse<T>, cacheKey: string) => T | void,
	useLocalstorageResult?: boolean,
	options: RequestInit = {}
): Promise<T> {
	return new Promise((resolve, reject) => {
		if (options.method === undefined || options.method === 'GET') {
			const cachedData = localStorage.getItem(cacheKey);
			const parsedData = JSON.parse(cachedData || 'null'); // if the data is not valid JSON, it will return null
			if (cachedData && 'data' in parsedData && parsedData.data.length > 0) {
				// check if the data is older than 1 hour
				const localstorageData: LocalstorageData<T> = JSON.parse(cachedData);
				const createdDate = new Date(localstorageData.createdDate).getTime();
				const now = new Date().getTime();
				const timeDifference = now - createdDate;
				const oneHour = 60 * 60 * 1000;
				if (timeDifference < oneHour) {
					return resolve(localstorageData.data);
				}
			}
		}

		fetcher(url, options)
			.then((response) => {
				response
					.json()
					.then((data: FetchResponse<T>) => {
						if (data.success === false) {
							return reject(data.message);
						} else {
							const value = localStorageUpdateCallback(data, cacheKey);

							if (useLocalstorageResult) {
								if (value !== undefined) {
									return resolve(value);
								} else {
									console.error('The local storage update callback did not return a value');
									return resolve(data.message);
								}
							}
							return resolve(data.message);
						}
					})
					.catch((e) => {
						return reject(e);
					});
			})
			.catch((e) => {
				return reject(e);
			});
	});
}

function reloadCacheData<T>(
	url: string,
	cacheKey: CacheKey,
	localStorageUpdateCallback: (data: FetchResponse<T>, cacheKey: string) => T | void,
	useLocalstorageResult?: boolean,
	options: RequestInit = {}
): Promise<T> {
	return new Promise(async (resolve, _reject) => {
		localStorage.removeItem(cacheKey);
		const data = await cacheFetcher<T>(url, cacheKey, localStorageUpdateCallback, useLocalstorageResult, options);
		return resolve(data);
	});
}

function fetcher(url: string, options?: RequestInit): Promise<Response> {
	return new Promise<Response>((resolve, reject) => {
		const accessToken = localStorage.getItem('accesstoken');
		const refreshToken = localStorage.getItem('refreshtoken');
		const headers = (options && options.headers) || {};

		if (headers['Content-Type'] === undefined) {
			headers['Content-Type'] = 'application/json';
		}

		headers['accesstoken'] = accessToken;
		headers['refreshtoken'] = refreshToken;

		const newOptions = {
			...options,
			headers: { ...headers },
		};

		fetch(url, { ...newOptions })
			.then((res) => {
				//if the response is unauthorized, clear the local storage and redirect to the login page
				if (res.status === 401) {
					console.log('Unauthorized');
					localStorage.clear();
					window.location.href = '/login';
					return reject('Unauthorized');
				}

				//if the response has a new access token, update the local storage (this happens when the access token expires and a new one is generated by the refresh token)
				const newAccessToken = res.headers.get('newAccessToken');
				if (newAccessToken !== null) {
					localStorage.setItem('accesstoken', newAccessToken);
				}

				resolve(res);
			})
			.catch((e) => {
				return reject(e);
			});
	});
}

function noCacheFetcher(url: string, options?: RequestInit): Promise<Response> {
	return new Promise((resolve, _reject) => {
		const accessToken = localStorage.getItem('accesstoken');
		const refreshToken = localStorage.getItem('refreshtoken');
		const headers = (options && options.headers) || {};

		if (headers['Content-Type'] === undefined) {
			headers['Content-Type'] = 'application/json';
		}

		headers['accesstoken'] = accessToken;
		headers['refreshtoken'] = refreshToken;

		const newOptions = {
			...options,
			headers: { ...headers },
		};

		fetch(url, { ...newOptions })
			.then((res) => {
				//if the response is unauthorized, clear the local storage and redirect to the login page
				if (res.status === 401) {
					console.log('Unauthorized');
					localStorage.clear();
					window.location.href = '/login';
				}

				//if the response has a new access token, update the local storage (this happens when the access token expires and a new one is generated by the refresh token)
				const newAccessToken = res.headers.get('newAccessToken');
				if (newAccessToken !== null) {
					localStorage.setItem('accesstoken', newAccessToken);
				}

				return resolve(res);
			})
			.catch((e) => {
				return resolve(e);
			});
	});
}

// export default fetcher;
export { cacheFetcher, reloadCacheData, noCacheFetcher };
