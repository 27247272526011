import { TimeStamp, weekDays } from '../../../Types/types';

// Events are in the format: {day: 'monday', time: '08:00'}

export function orderEventStartAndEnd(timestampA: TimeStamp, timestampB: TimeStamp, eventId: number) {
	const aStartDay = weekDays.indexOf(timestampA.day);

	const bStartDay = weekDays.indexOf(timestampB.day);

	if (aStartDay < bStartDay) {
		return { start: timestampA, end: timestampB, id: eventId };
	}

	if (aStartDay > bStartDay) {
		return { start: timestampB, end: timestampA, id: eventId };
	}

	const [aHour, aMinute] = timestampA.time.split(':').map((n) => parseInt(n));
	const [bHour, bMinute] = timestampB.time.split(':').map((n) => parseInt(n));

	if (aHour < bHour) {
		return { start: timestampA, end: timestampB, id: eventId };
	}

	if (aHour > bHour) {
		return { start: timestampB, end: timestampA, id: eventId };
	}

	if (aMinute < bMinute) {
		return { start: timestampA, end: timestampB, id: eventId };
	}

	if (aMinute > bMinute) {
		return { start: timestampB, end: timestampA, id: eventId };
	}

	return { start: timestampA, end: timestampB, id: eventId };
}
