import React, {FC, useEffect, useState} from 'react';
import Stack from '@mui/material/Stack';
import {styled} from '@mui/material/styles';
import {useNavigate} from 'react-router-dom';
import {Dropdown, OutlinedButton, RegularText, TextHeader, useSnackbar, useWindowDimensions} from '@Iot-Bee/standard-web-library';

import {Card, ActivityIcon, GraphIcon, ChartIcon, DataOverView} from '@Iot-Bee/standard-web-library';
import SavingsTwoToneIcon from '@mui/icons-material/SavingsTwoTone';
import Co2Icon from '@mui/icons-material/Co2';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';

import {cacheFetcher, cacheKeys} from '../../utils/fetch';
import {userDevicesLocalStorageUpdateCallback} from '../../utils/localstorageHandlers';
import {Device} from '../../Types/types';

import {DonutChart} from './components/DonutChart';
import DashboardDataFilter, {formatValueLabel} from './components/DashboardDataFilter';
import {Box, Divider, Menu, MenuItem} from '@mui/material';
import {BarChart} from './components/BarChart';
import {dayTheme, nightTheme, totalTheme} from './themes';

import html2canvas from 'html2canvas-pro';
import jsPDF from 'jspdf';
import dayjs from 'dayjs';

import generatePDF from 'react-to-pdf';

const PageSection = styled('section')(() => ({
	padding: '20px',
}));

interface DashboardData {
	activeDevices: number;
	dataUsed: string;
	dataUsedPerDevice: string;
}
const SectionText = styled(RegularText)(() => ({
	marginBottom: '15px',
	marginTop: '1px',
}));

export const getIsoWeek = (date: Date): string => {
	const tempDate = new Date(date.getTime());
	tempDate.setUTCHours(0, 0, 0, 0);
	tempDate.setUTCDate(tempDate.getUTCDate() + 4 - (tempDate.getUTCDay() || 7)); // Move to the Thursday in the current week
	const yearStart = new Date(Date.UTC(tempDate.getUTCFullYear(), 0, 1));
	const weekNumber = Math.ceil(((tempDate.getTime() - yearStart.getTime()) / 86400000 + 1) / 7);
	return `${tempDate.getUTCFullYear()}-W${weekNumber.toString().padStart(2, '0')}`;
};

export const getIsoDay = (date: Date): string => {
	return date.toISOString().split('T')[0];
};

export const getIsoMonth = (date: Date): string => {
	const year = date.getFullYear();
	const month = (date.getMonth() + 1).toString().padStart(2, '0');
	return `${year}-${month}`;
};

export const getIsoYear = (date: Date): string => {
	return String(date.getFullYear());
};

const exportAsPDF = (containerId: string) => {
	const targetContainer = () => document.getElementById(containerId);
	generatePDF(targetContainer, {filename: 'formValues.pdf'});
};

const options = {
	async: true,
	foreignObjectRendering: false,
	logging: false,
};

const exportChartAsPDF = (chartContainerId) => {
	const input = document.getElementById(chartContainerId);
	if (input) {
		html2canvas(input, options).then((canvas) => {
			const imgData = canvas.toDataURL('image/png');
			const pdf = new jsPDF({
				orientation: 'landscape', // Adjust the orientation based on your chart layout
				unit: 'px',
				format: [canvas.width, canvas.height],
			});

			pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);
			pdf.save('kwh-consumption.pdf');
		});
	}
};

const exportDataAsCSVFirsSolution = (data: BarchartObject[], selectedDevices: string[], selectedTags: string[], workingHoursRange: number[], fileName: string) => {
	if (data.length === 0) return; // Early return if no data

	// Extract headers from the first object and add new headers for the additional lists/range
	const headers = [...Object.keys(data[0]), 'Selected Devices', 'Selected Tags', 'Working Hours Start', 'Working Hours End'];

	// Convert data to CSV format, including the new data
	const csvContent = [
		headers.join(','), // Header row (only once)
		...data.map((row) => {
			const rowData = [
				...headers.slice(0, Object.keys(data[0]).length).map((header) => String(row[header] !== undefined ? row[header] : '')), // existing data
				`"${selectedDevices.join(', ')}"`, // add devices as comma-separated string within quotes
				`"${selectedTags.join(', ')}"`, // add tags as comma-separated string within quotes
				`"${formatValueLabel(workingHoursRange[0])}"`, // Working Hours Start formatted
				`"${formatValueLabel(workingHoursRange[1])}"`, // Working Hours End formatted
			];
			return rowData.join(',');
		}),
	].join('\n');

	// Create a Blob and download it
	const blob = new Blob([csvContent], {type: 'text/csv;charset=utf-8;'});
	const link = document.createElement('a');
	link.href = URL.createObjectURL(blob);
	link.download = fileName;
	link.style.display = 'none';

	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};

const exportDataAsCSV = (data: BarchartObject[], selectedDevices: string[], workingHoursRange: number[], fileName: string) => {
	if (data.length === 0) return; // Early return if no data

	// Define headers for the new CSV structure
	const headers = ['Date', 'kWh', 'Tag', 'Selected Devices', 'Working Hours Start', 'Working Hours End'];

	// Convert data to CSV format, including the new structure
	const csvContent = [
		headers.join(','), // Header row (only once)
		...data.flatMap((row) => {
			// Extract date and map over the object keys (excluding the first entry)
			const date = row.timeGroup; // Assuming `timeGroup` holds the date

			return Object.keys(row)
				.slice(1)
				.map((key) => {
					const kWh = row[key] !== undefined ? row[key] : ''; // Extract the kWh value for the current key

					return [
						date, // Date column
						kWh, // kWh column
						key, // Tag column (key is the tag name)
						`"${selectedDevices.join(', ')}"`, // Devices column
						`"${formatValueLabel(workingHoursRange[0])}"`, // Working Hours Start formatted
						`"${formatValueLabel(workingHoursRange[1])}"`, // Working Hours End formatted
					].join(',');
				});
		}),
	].join('\n');

	// Create a Blob and download it
	const blob = new Blob([csvContent], {type: 'text/csv;charset=utf-8;'});
	const link = document.createElement('a');
	link.href = URL.createObjectURL(blob);
	link.download = fileName;
	link.style.display = 'none';

	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};

export const DashboardPage: FC = () => {
	const {
		actions: {openSnackbar},
	} = useSnackbar();
	type TimeGroupingInterval = 'day' | 'week' | 'month' | 'year';
	type GroupBy = 'Tags' | 'Working Hours';

	const [totalKWhConsumption, setTotalKWhConsumption] = useState<number>(0);

	const [dashboardData, setDashboardData] = useState<DashboardData>({} as DashboardData);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	const [devices, setDevices] = useState<Device[]>({} as Device[]);
	const [selectedDevices, setSelectedDevices] = useState<string[]>([]);
	const [devicesData, setDevicesData] = useState<DeviceData[]>([]);
	const [workingHoursRange, setWorkingHoursRange] = useState([540, 1020]);
	const [fromDate, setFromDate] = useState(dayjs(new Date()).startOf('month'));
	const [toDate, setToDate] = useState(dayjs(new Date()).endOf('day'));

	const [selectedTags, setSelectedTags] = useState<string[]>([]);
	const [donutDayData, setDonutDayData] = useState<DonutData[]>([]);
	const [donutNightData, setDonutNightData] = useState<DonutData[]>([]);
	const [donutTotalData, setDonutTotalData] = useState<DonutData[]>([]);
	const [hasESTdevice, setHasESTdevice] = useState(true);
	const [barchartData, setBarchartData] = useState<BarchartObject[]>([]);

	const [timePeriod, setTimePeriod] = useState<TimeGroupingInterval>('day');
	const [groupBy, setGroupBy] = useState<GroupBy>('Tags');
	const [anchorEl, setAnchorEl] = useState(null);
	const [deviceIdFriendlynameMap, setDeviceIdFriendlynameMap] = useState<Map<string, string>>(new Map());

	const currentKWhPrice = 2.8;
	const chartContainerId = 'dashboard-chart-container';
	const dataOverviewContainer = 'data-overview-container';
	const timePeriodOptions: TimeGroupingInterval[] = ['day', 'week', 'month', 'year'];
	const groupByOptions: GroupBy[] = ['Tags', 'Working Hours'];

	const transformTimeEntryToBarchartObject = (list: TimeEntry[], selectedTags: string[]): BarchartObject[] => {
		return list.map((item) => {
			const transformedItem: BarchartObject = {
				timeGroup: item.time,
			};

			// Initialize all selected tags with 0
			selectedTags.forEach((tag) => {
				transformedItem[tag] = 0;
			});

			// Set the value for each tag if it exists in the data
			item.data.forEach((timeData) => {
				if (selectedTags.includes(timeData.tag)) {
					transformedItem[timeData.tag] = timeData.accumulatedValue;
				}
			});

			return transformedItem;
		});
	};
	useEffect(() => {
		if (devices.length > 0) {
			let hasEST = false;
			devices.forEach((device) => {
				if (device.deviceType === 'energySavingTool') {
					hasEST = true;
				}
			});
			setHasESTdevice(hasEST);
		}
		//Get only the selected devices data
		const updatedDevices = devicesData.filter((device) => selectedDevices.includes(deviceIdFriendlynameMap.get(device.sensorId) || device.sensorId) && selectedTags.includes(device.tags[0]));

		if (groupBy === 'Tags') {
			const timeEntries = calculateConsumptionInTimeGroupingInterval(updatedDevices, timePeriod);
			setBarchartData(transformTimeEntryToBarchartObject(timeEntries, selectedTags));
		}

		setTotalKWhConsumption(devicesData.reduce((acc, item) => acc + item.data[item.data.length - 1].accumulatedValue, 0));

		const [dayData, nightData, totalData] = calculateInsideAndOutsideWorkingHoursConsumption(updatedDevices, workingHoursRange, timePeriod);

		setDonutDayData(dayData);
		setDonutNightData(nightData);
		setDonutTotalData(totalData);
		//Only fetch data if needed
		cacheFetcher<Device[]>(process.env.REACT_APP_API_URL + `getids`, cacheKeys.userDevices, userDevicesLocalStorageUpdateCallback, true)
			.then((data) => {
				setDevices(data);
				const totalMB: number = data.reduce((accumulator: number, currentItem) => {
					return accumulator + parseFloat(currentItem.dataUsage.split(' ')[0]);
				}, 0);

				setDashboardData({
					activeDevices: data.length,
					dataUsed: totalMB.toFixed(1) + ' MB', // GB
					dataUsedPerDevice: (totalMB / data.length).toFixed(1) + ' MB', // mb
				});
			})
			.catch((error) => {
				console.error(error);
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, [workingHoursRange, selectedDevices, selectedTags, timePeriod, groupBy, devicesData]);

	// Function to convert a timestamp to minutes since midnight (local time)
	const timestampToMinutes = (timestamp) => {
		const date = new Date(timestamp);
		const hours = date.getUTCHours();
		const minutes = date.getUTCMinutes();
		return hours * 60 + minutes;
	};

	const calculateConsumptionInsideAndOutsideTimeRangePerDay = (dataValues: SensorDataValues[], startRange: number, endRange: number): [number, number] => {
		const dataInRange: SensorDataValues[] = [];
		let consumptionOutsideWh = 0;
		if (dataValues.length > 0) {
			const totalConsumptionPerDay = dataValues[dataValues.length - 1].accumulatedValue - dataValues[0].accumulatedValue;

			for (let i = 0; i < dataValues.length; i++) {
				const data = dataValues[i];
				const entryTime = timestampToMinutes(data.timestamp);
				if (entryTime >= startRange && entryTime <= endRange) {
					//Value of previous day is within time frame, need to check if first value of current day also lies within the timeframe
					if (i === 0) {
						const entryTimeNextValue = timestampToMinutes(dataValues[1].timestamp);
						if (entryTimeNextValue >= startRange && entryTimeNextValue <= endRange) {
							dataInRange.push(data);
						}
					} else {
						dataInRange.push(data);
					}
				}
			}
			let consumptionInsideWH = 0;
			if (dataInRange.length > 0) {
				consumptionInsideWH = dataInRange[dataInRange.length - 1].accumulatedValue - dataInRange[0].accumulatedValue;
			}
			consumptionOutsideWh = totalConsumptionPerDay - consumptionInsideWH;
			return [consumptionInsideWH, consumptionOutsideWh];
		}
		return [0, consumptionOutsideWh];
	};

	// Helper function to get the key for grouping
	const getGroupingKey = (date: Date, timePeriod: TimeGroupingInterval): string => {
		switch (timePeriod) {
			case 'day':
				return getIsoDay(date); // YYYY-MM-DD
			case 'week':
				return getIsoWeek(date); // YYYY-W## (ISO week number)
			case 'month':
				return getIsoMonth(date);
			case 'year':
				return getIsoYear(date); // YYYY
			default:
				throw new Error(`Unsupported timePeriod value: ${timePeriod}`);
		}
	};

	const groupDataByTime = (deviceDataList: DeviceData[], timePeriod: TimeGroupingInterval): DeviceDataPerTimeFrame[] => {
		// Initialize an empty array to hold the result
		const result: {
			sensorId: string;
			data: SensorDataValues[][];
			tags: string;
		}[] = [];

		// Iterate through each DeviceData object in the input list
		deviceDataList.forEach((deviceData) => {
			let latestDataPointFromPreviousPeriod: SensorDataValues;
			// Initialize a dictionary to group data by the specified period (day/week/year)
			const groupedByPeriod: {[key: string]: SensorDataValues[]} = {};

			// Iterate through each data point in the current DeviceData object's data array
			deviceData.data.forEach((dataPoint) => {
				// Create a new Date object from the timestamp
				const date = new Date(dataPoint.timestamp);
				// Get the key for the current grouping period (day/week/year)
				const periodKey = getGroupingKey(date, timePeriod);

				// If there is no array for this period in the dictionary, create one and push the latest data point from the previous period as the first value
				if (!groupedByPeriod[periodKey]) {
					groupedByPeriod[periodKey] = [];
					if (latestDataPointFromPreviousPeriod !== undefined) {
						groupedByPeriod[periodKey].push(latestDataPointFromPreviousPeriod);
					}
				}

				// Add the current data point to the array for its period
				latestDataPointFromPreviousPeriod = dataPoint;
				groupedByPeriod[periodKey].push(dataPoint);
			});

			// Convert the grouped data in the dictionary to arrays
			const dataArray: SensorDataValues[][] = Object.keys(groupedByPeriod).map((periodKey) => groupedByPeriod[periodKey]);

			// Add the sensorId and data to the result array
			result.push({
				sensorId: deviceData.sensorId,
				data: dataArray,
				tags: deviceData.tags?.[0] ?? 'No tags',
			});
		});
		return result;
	};

	const getAccumulatedValueWithinTimeframe = (timeFrameArray: SensorDataValues[]) => {
		return timeFrameArray[timeFrameArray.length - 1].accumulatedValue - timeFrameArray[0].accumulatedValue;
	};

	const convertToList = (timeDictionary: TimeDictionary): TimeEntry[] => {
		return Object.keys(timeDictionary).map((key) => ({
			time: key,
			data: timeDictionary[key],
		}));
	};

	function sortTimeDictionaryByKeys(dict: TimeDictionary): TimeDictionary {
		// Convert the object to an array of key-value pairs
		const entries = Object.entries(dict);

		// Sort the array by keys
		entries.sort(([keyA], [keyB]) => keyA.localeCompare(keyB));

		// Convert sorted entries back to an object
		const sortedDict: TimeDictionary = Object.fromEntries(entries);

		return sortedDict;
	}

	const calculateConsumptionInTimeGroupingInterval = (devices: DeviceData[], timePeriod: TimeGroupingInterval): TimeEntry[] => {
		const timeDictionary: TimeDictionary = {};
		const perTimeFrameData: DeviceDataPerTimeFrame[] = groupDataByTime(devices, timePeriod);
		perTimeFrameData.forEach((deviceData) => {
			deviceData.data.forEach((timeFrameArray) => {
				const key: string = getGroupingKey(new Date(timeFrameArray[timeFrameArray.length - 1].timestamp), timePeriod);
				if (!timeDictionary[key]) {
					// If the key does not exist, create a new list for this key
					timeDictionary[key] = [];
				}
				const accValue = getAccumulatedValueWithinTimeframe(timeFrameArray);
				const existingData = timeDictionary[key].find((data) => data.tag === deviceData.tags);

				if (existingData) {
					// If the object with the current tag exists, update its accumulatedValue
					existingData.accumulatedValue += accValue;
				} else {
					const timeData: TimeData = {
						tag: deviceData.tags,
						accumulatedValue: accValue,
					};
					// If the object with the current tag does not exist, add the new TimeData object
					timeDictionary[key].push(timeData);
				}
			});
		});
		const list = convertToList(sortTimeDictionaryByKeys(timeDictionary));
		return list;
	};

	const calculateInsideAndOutsideWorkingHoursConsumption = (devices: DeviceData[], hourRange: number[], timePeriod: TimeGroupingInterval) => {
		const [startRange, endRange] = hourRange;
		//'day' timePeriod key is needed for donut chart data, so leave it hard coded
		const perDayData: DeviceDataPerTimeFrame[] = groupDataByTime(devices, 'day');
		const dayData: DonutData[] = [];
		const nightData: DonutData[] = [];
		const totalData: DonutData[] = [];

		if (devices.length < 1) {
			setBarchartData([]);
		}

		const insideAndOutsideConsumptionPerTimePeriodDict = {};
		perDayData.forEach((device) => {
			const firstValueInTimeFrame = Number(device.data[0][0].accumulatedValue);
			const indexOfLastMeasuredValueInTimeFrame = device.data[device.data.length - 1].length - 1;
			const lastValueInTimeFrame = Number(device.data[device.data.length - 1][indexOfLastMeasuredValueInTimeFrame].accumulatedValue);
			const totalConsumptionWithinTimeFrame = lastValueInTimeFrame - firstValueInTimeFrame;
			let totalConsumptionInsideWH = 0;
			device.data.forEach((day) => {
				const [consumptionInsideWhPerDay, consumptionOutsideWhPerDay] = calculateConsumptionInsideAndOutsideTimeRangePerDay(day, startRange, endRange);
				const groupingKey = getGroupingKey(new Date(day[day.length - 1].timestamp), timePeriod);
				if (!insideAndOutsideConsumptionPerTimePeriodDict[groupingKey]) {
					const nightDayData: NightDayConsumpiton = {
						insideWh: consumptionInsideWhPerDay,
						outsideWh: consumptionOutsideWhPerDay,
					};
					insideAndOutsideConsumptionPerTimePeriodDict[groupingKey] = nightDayData;
				} else {
					insideAndOutsideConsumptionPerTimePeriodDict[groupingKey].insideWh += consumptionInsideWhPerDay;
					insideAndOutsideConsumptionPerTimePeriodDict[groupingKey].outsideWh += consumptionOutsideWhPerDay;
				}

				totalConsumptionInsideWH += consumptionInsideWhPerDay;
			});
			if (groupBy === 'Working Hours') {
				const insideAndOutsideConsumptionPerTimePeriodList: BarchartObject[] = Object.keys(insideAndOutsideConsumptionPerTimePeriodDict).map((key) => ({
					timeGroup: key,
					insideWh: insideAndOutsideConsumptionPerTimePeriodDict[key].insideWh,
					outsideWh: insideAndOutsideConsumptionPerTimePeriodDict[key].outsideWh,
				}));

				insideAndOutsideConsumptionPerTimePeriodList.sort((a, b) => a.timeGroup.localeCompare(b.timeGroup));
				setBarchartData(insideAndOutsideConsumptionPerTimePeriodList);
			}

			const totalConsumptionOutsideWH = totalConsumptionWithinTimeFrame - totalConsumptionInsideWH;

			const dayD: DonutData = {
				asset: device.tags,
				amount: Math.floor(totalConsumptionInsideWH),
				//amount: totalConsumptionInsideWH,
			};
			const nightD: DonutData = {
				asset: device.tags,
				amount: Math.floor(totalConsumptionOutsideWH),
				//amount: totalConsumptionOutsideWH,
			};

			const existingIndex = dayData.findIndex((data) => data.asset === device.tags);
			if (existingIndex !== -1) {
				dayData[existingIndex].amount += Math.floor(totalConsumptionInsideWH);
				//dayData[existingIndex].amount += totalConsumptionInsideWH;
				nightData[existingIndex].amount += Math.floor(totalConsumptionOutsideWH);
				//nightData[existingIndex].amount += totalConsumptionOutsideWH;
			} else {
				dayData.push(dayD);
				nightData.push(nightD);
			}
		});

		let totalAmountInRange = dayData.reduce((acc, item) => acc + item.amount, 0);
		let totalAmountOutRange = nightData.reduce((acc, item) => acc + item.amount, 0);
		if (totalAmountInRange > 0 || totalAmountOutRange > 0) {
			const totalInRange: DonutData = {
				asset: 'Inside Working Hours',
				amount: Math.ceil(totalAmountInRange),
			};
			const totalOutRange: DonutData = {
				asset: 'Outside Working Hours',
				amount: Math.ceil(totalAmountOutRange),
			};

			totalData.push(totalInRange, totalOutRange);
		}
		return [dayData, nightData, totalData];
	};

	//Estimating that we save them 40%
	const totalKwHInTimePeriod = donutTotalData.reduce((acc, item) => acc + item.amount, 0);
	const cO2AmountPerKWH = 0.132;

	const dataOverviewItems = [
		{
			title: 'Active devices',
			value: dashboardData.activeDevices,
			icon: <ActivityIcon />,
		},
		//{title: 'Data used', value: dashboardData.dataUsed, icon: <GraphIcon />},
		{
			title: 'Total kWh consumption',
			value: String(totalKWhConsumption.toFixed(0)) + ' kWh',
			//icon: <SavingsTwoToneIcon sx={{color: '#140C47'}} />,
		},
		//ask how to calculate this (do we save them on average 40%? --> so the current total kwh is 60% of what they used before?)
		/*{
			title: 'CO2 savings in time period',
			value: dashboardData.dataUsedPerDevice,
			icon: <ChartIcon />,
		},*/
		{
			title: 'Estimated CO2 consumption',
			value: String((totalKwHInTimePeriod * cO2AmountPerKWH).toFixed(0)) + ' Kg',
			//icon: <Co2Icon sx={{fontSize: '40px', color: '#140C47'}} />,
		},
	];

	const handleExportClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleExportClose = () => {
		setAnchorEl(null);
	};

	const handleExportCSV = () => {
		if (barchartData.length === 0) {
			openSnackbar('error', 'No data available!');
		} else {
			exportDataAsCSV(barchartData, selectedDevices, workingHoursRange, 'kwh-consumption.csv');
		}
		handleExportClose();
	};

	const handleExportPDF = () => {
		if (barchartData.length === 0) {
			openSnackbar('error', 'No data available!');
		} else {
			exportChartAsPDF(chartContainerId);
			//exportAsPDF(dataOverviewContainer);
		}
		handleExportClose();
	};

	const navigate = useNavigate();
	const {screenwidth} = useWindowDimensions();

	return (
		<Stack direction="column" spacing={3}>
			<Card title="Overview">
				<DataOverView data={dataOverviewItems} isLoading={isLoading} />
			</Card>

			{hasESTdevice ? (
				<Box id="dashboard-chart-container">
					<Card title="Consumption Overview">
						<DashboardDataFilter
							devicesData={devicesData}
							setDevicesData={setDevicesData}
							selectedDevices={selectedDevices}
							setSelectedDevices={setSelectedDevices}
							selectedTags={selectedTags}
							setSelectedTags={setSelectedTags}
							workingHoursRange={workingHoursRange}
							setWorkingHoursRange={setWorkingHoursRange}
							devices={devices}
							isLoading={isLoading}
							fromDate={fromDate}
							setFromDate={setFromDate}
							toDate={toDate}
							setToDate={setToDate}
							setDeviceIdFriendlynameMap={setDeviceIdFriendlynameMap}
						/>
						<Box sx={{padding: screenwidth > 768 ? '11px' : '0px'}}>
							<Box sx={{display: 'flex', backgroundColor: 'rgba(12, 92, 248, 0.1)', padding: '5px'}}>
								<Box sx={{display: 'flex', width: '99%', alignItems: 'center'}}>
									<Dropdown
										sx={{width: screenwidth > 768 ? '10%' : '30%', marginRight: '10px'}}
										label=""
										placeholder="Time Period"
										options={timePeriodOptions}
										value={timePeriod}
										onChange={(event) => setTimePeriod(event.target.value as TimeGroupingInterval)}></Dropdown>
									<Dropdown
										sx={{width: screenwidth > 768 ? '10%' : '30%'}}
										label=""
										placeholder="Group by"
										options={groupByOptions}
										value={groupBy}
										onChange={(event) => setGroupBy(event.target.value as GroupBy)}></Dropdown>
								</Box>
								<SystemUpdateAltIcon
									sx={{
										color: '#140C47',
										marginRight: '10px',
										fontSize: '30px',
										cursor: 'pointer',
										'&:hover': {
											backgroundColor: 'rgba(12, 92, 248, 0.1)',
											borderRadius: '10%',
										},
									}}
									onClick={handleExportClick}
								/>
								<Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleExportClose}>
									<MenuItem onClick={handleExportCSV}>Export as CSV</MenuItem>
									{/*<MenuItem onClick={handleExportPDF}>Export as PDF</MenuItem>*/}
								</Menu>
							</Box>

							<Box sx={{height: screenwidth > 768 ? '33vh' : '50vh'}}>
								<BarChart barchartData={barchartData} groupBy={groupBy}></BarChart>
							</Box>
							<Box
								sx={{
									display: screenwidth > 768 ? 'flex' : 'block',
									height: screenwidth > 768 ? '35vh' : '100%',
									marginTop: '20px',
								}}>
								<DonutChart theme={nightTheme} donutData={donutNightData} />
								<DonutChart theme={totalTheme} donutData={donutTotalData} />
								<DonutChart theme={dayTheme} donutData={donutDayData} />
							</Box>
						</Box>
					</Card>
				</Box>
			) : (
				<Card title="Welcome and get started">
					<PageSection>
						<TextHeader>Your devices</TextHeader>
						<SectionText>See all of your devices and manage your data right here in the Hive.</SectionText>
						<OutlinedButton onClick={() => navigate('devices')}>Manage your devices</OutlinedButton>
					</PageSection>
					<Divider />
					<PageSection>
						<TextHeader>Setup alerts</TextHeader>
						<SectionText>Setup alerts and automatic alarms on you devices - or simply connect your data with your API through HTTP requests.</SectionText>
						<OutlinedButton onClick={() => navigate('alerts')}>Setup alerts</OutlinedButton>
					</PageSection>
					<Divider />
					<PageSection>
						<TextHeader>Need any help</TextHeader>
						<SectionText>If you need any help then feel free to contact us at contact@iotbee.net or +4551531632. </SectionText>
						<OutlinedButton>Contact support</OutlinedButton>
					</PageSection>
				</Card>
			)}
		</Stack>
	);
};

export default DashboardPage;
