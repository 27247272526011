import React, { FC, Fragment, useEffect, useMemo, useRef, useState, MouseEvent } from 'react';
import { Box, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';

import { RegularText, BrandAccentBlue } from '@Iot-Bee/standard-web-library';

import TableCellContent from './TableCellContent';
import Event from './Event';
import { debounce } from 'lodash';
import { isEventValid } from '../Utils/validPosition';
import CreateEvent from './CreateEvent';
import { WeekDay, hours, weekDays, TimeFormat, CalenderScheduleEvent, NewCalenderScheduleEvent } from '../../../Types/types';
import { utcTimestampToLocale } from '../Utils/TimeConverter';

interface CalenderProps {
	events: { id: number; start: { day: WeekDay; time: TimeFormat }; end: { day: WeekDay; time: TimeFormat } }[];
	addEvent: (event: NewCalenderScheduleEvent) => void;
	deleteEvent: (eventId: number) => void;
	updateEvent: (calenderEvent: CalenderScheduleEvent, newEvent: NewCalenderScheduleEvent) => void;
	editable: boolean;
}

const Calender: FC<CalenderProps> = ({ events, addEvent, deleteEvent, updateEvent, editable }) => {
	const [markersPrHour, setMarkersPrHour] = useState<any>(4);

	// this is the time that the mouse is hovering over
	const [hoverTime, setHoverTime] = useState<any>(null);
	const [canHover, setCanHover] = useState<any>(true);

	// this is the time that the mouse is pressed down on
	const [mouseDown, setMouseDown] = useState<any>(null);

	const [scrollTop, setScrollTop] = useState<any>(0);

	const tableRef = useRef<any>(null);
	const container = useRef<any>(null);

	useEffect(() => {
		const handleScroll = debounce(() => {
			if (container.current === null) return;
			setScrollTop(container.current.scrollTop);
		}, 10);

		const currentContainer = container.current;

		if (currentContainer) {
			currentContainer.addEventListener('scroll', handleScroll);
		}

		return () => {
			if (currentContainer) {
				currentContainer.removeEventListener('scroll', handleScroll);
			}
		};
	}, [container.current]);

	const handleMouseOver = (e: MouseEvent<HTMLTableCellElement>) => {
		if (!editable) return;
		const target = e.target as HTMLTableCellElement;
		const id = target.id;
		if (id) {
			const [day, time] = id.split('-');
			setHoverTime({ day, time });
		}
	};

	const handleMouseDown = (e: MouseEvent<HTMLTableCellElement>) => {
		if (!editable) return;
		const target = e.target as HTMLTableCellElement;
		const id = target.id;
		document.body.style.userSelect = 'none'; // prevent text selection/marking
		if (id && mouseDown === null) {
			// if not, create a new event
			const [day, time] = id.split('-');
			if (day === '') return;
			setMouseDown({ day, time });
		}
	};

	const handleMouseUp = (e: MouseEvent<HTMLTableCellElement>) => {
		if (!editable) return;
		const target = e.target as HTMLTableCellElement;
		const id = target.id;
		document.body.style.userSelect = 'auto'; // enable text selection/marking
		if (id) {
			const [day, time] = id.split('-');

			if (mouseDown === null || hoverTime === null) return;

			// check that the dragged range is valid
			if (mouseDown.day === hoverTime.day && mouseDown.time === hoverTime.time) {
				setMouseDown(null);
				return;
			}

			if (day !== '' && mouseDown.day !== '') {
				addEvent({ start: mouseDown, end: hoverTime });
			}

			setMouseDown(null);
		}
	};

	const calculatedDragGrid = useMemo<[number, number]>(() => {
		if (tableRef.current === null) return [0, 0];
		const parentContainer = tableRef.current;
		// calculate the height of a single cell
		const parentRect = parentContainer.getBoundingClientRect();
		const totalTableHeight = parentContainer.scrollHeight;
		const cellHeight = totalTableHeight / (hours.length * markersPrHour);

		// calculate the width of a single cell
		const cellWidth = parentRect.width / weekDays.length;
		return [Math.round(cellWidth), Math.round(cellHeight)];
	}, [markersPrHour, tableRef.current]);

	const handleDeleteEvent = (eventId) => {
		deleteEvent(eventId);
	};

	const handleMoveEvent = (calenderEvent: CalenderScheduleEvent, newEvent: CalenderScheduleEvent) => {
		// validate the new positon
		const isValid = isEventValid(newEvent, events);

		if (isValid) {
			updateEvent(calenderEvent, newEvent);
		}
	};

	const updateEventSizeAndPosition = (calenderEvent: CalenderScheduleEvent, newEvent: CalenderScheduleEvent) => {
		const isValid = isEventValid(newEvent, events);
		if (isValid) {
			updateEvent(calenderEvent, newEvent);
		}
	};

	return (
		<Box position="relative" id="calender-parent" sx={{ maxHeight: '85%', overflow: 'scroll', boxShadow: '0px 2px 16px rgba(20, 12, 71, 0.1)', borderRadius: '6px' }} ref={container}>
			<Table stickyHeader ref={tableRef} id="calender-table" sx={{ borderCollapse: 'collapse' }}>
				<TableHead>
					<TableRow>
						{weekDays.map((day) => {
							return (
								<TableCell key={day} sx={{ width: `12.5%`, padding: '6px 0px', m: 0, textAlign: 'center', borderCollapse: 'collapse' }}>
									<RegularText sx={{ color: hoverTime !== null && hoverTime.day === day ? BrandAccentBlue : 'black' }}>{day}</RegularText>
								</TableCell>
							);
						})}
					</TableRow>
				</TableHead>

				<TableBody
					id="calender-table-body"
					onMouseLeave={() => {
						setHoverTime(null);
					}}
					sx={{ paddingBottom: '10px', marginBottom: '10px' }}
				>
					{hours.map((hour, hourIndex) => {
						const markersTime: string[] = [];
						const oneMarker = 60 / markersPrHour;
						for (let i = 0; i < markersPrHour; i++) {
							const h = hour < 10 ? `0${hour}` : hour;
							const minute = i * oneMarker < 10 ? `0${i * oneMarker}` : i * oneMarker;
							const time = `${h}:${minute}`;
							// for 24 only push 24:00
							if (hour === 24 && i === 0) {
								markersTime.push('23:59');
							} else if (hour !== 24) {
								markersTime.push(time);
							}
						}
						return (
							<Fragment key={`${hour}`}>
								{markersTime.map((time, markersIndex) => {
									return (
										<TableRow key={`${time}`} sx={{ p: 0, m: 0, borderCollapse: 'collapse', boxSizing: 'border-box' }}>
											{weekDays.map((day) => {
												const isHovered = hoverTime && hoverTime.time === time;
												const timeSplit = time.split(':');
												return (
													<TableCell
														key={`${day}-${time}`}
														id={`${day}-${time}`}
														sx={{
															position: 'relative',
															p: 0.5,
															m: 0,
															boxSizing: 'border-box',
															borderBottom:
																isHovered && day !== ''
																	? `1px solid ${BrandAccentBlue}`
																	: day === '' || (timeSplit[1] !== '00' && timeSplit[1] !== '59')
																	? 'unset'
																	: 'auto',
															borderRight: hourIndex === 0 && markersIndex === 0 ? 'unset' : '1px solid #E0E0E0',
															borderCollapse: 'collapse',
															width: `${100 / 8}%`,
															height: '10px',
														}}
														onMouseOver={(e) => {
															if (canHover) handleMouseOver(e);
														}}
														onMouseDown={(e) => {
															handleMouseDown(e);
														}}
														onMouseUp={(e) => {
															handleMouseUp(e);
														}}
													>
														<TableCellContent day={day} time={`${time.split(':')[0]}:${time.split(':')[1]}`} isHovered={isHovered} />
													</TableCell>
												);
											})}
										</TableRow>
									);
								})}
							</Fragment>
						);
					})}
				</TableBody>
			</Table>
			<CreateEvent startElement={mouseDown} endElement={hoverTime} markersPrHour={markersPrHour} parentScrollTop={scrollTop} dragGrid={calculatedDragGrid} />
			{events.map((event) => {
				// events are added in UTC time, so they need to be converted to local time
				const localtimeEvent = utcTimestampToLocale(event);
				return (
					<Event
						key={`${localtimeEvent.start.day}-${localtimeEvent.start.time}/${localtimeEvent.end.day}-${localtimeEvent.end.time}`}
						startElement={localtimeEvent.start}
						endElement={localtimeEvent.end}
						eventId={localtimeEvent.id}
						deleteEvent={handleDeleteEvent}
						dragGrid={calculatedDragGrid}
						handleMoveEvent={handleMoveEvent}
						updateEventSizeAndPosition={updateEventSizeAndPosition}
						markersPrHour={markersPrHour}
						toggleHover={setCanHover}
						parentScrollTop={scrollTop}
						validatePosition={(newEvent) => isEventValid(newEvent, events)}
						editable={editable}
					/>
				);
			})}
		</Box>
	);
};

export default Calender;
