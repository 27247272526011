import { DeviceNameAndUsed, DeviceSetting } from '../../../Types/types';

export function moveDevicesSetting(devices: DeviceNameAndUsed[], deviceSetting: DeviceSetting[]) {
	const deviceSettingsCopy = [...deviceSetting];
	// go trhoug every device
	devices.forEach((device) => {
		// if device has the movefrom key
		if (device.moveFrom !== undefined) {
			// find the schedule with the name of the movefrom key
			const oldSchedule = deviceSettingsCopy.find((s) => s.name === device.moveFrom);
			if (!oldSchedule) {
				console.error('old schedule not found');
				return;
			}
			// remove the device from the schedule with the name of the movefrom key
			oldSchedule.devices = oldSchedule.devices.filter((d) => d !== device.name);
			oldSchedule.allDevices = oldSchedule.devices.join(', ');
			if (oldSchedule.allDevices.length > 50) {
				oldSchedule.allDevices = oldSchedule.allDevices.substring(0, 50) + '...';
			}
		}
		if (device.isUsed === '') return;
		// add the device to the schedule with the name of the isUsed key
		const newSchedule = deviceSettingsCopy.find((s) => s.name === device.isUsed);
		if (!newSchedule) {
			console.error('new schedule not found');
			return;
		}
	});
	// return the new schedules
	return deviceSettingsCopy;
}
