import React, { FC } from 'react';
import { TextField, Autocomplete, Checkbox, FormControl, MenuItem, InputLabel } from '@mui/material';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { DeviceNameAndUsed } from '../../Types/types';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface AutoCompleteProps {
	options: DeviceNameAndUsed[];
	value: DeviceNameAndUsed[];
	onChange: (value: DeviceNameAndUsed) => void;
	label: string;
	sx?: any;
	itemName: string;
}

const AutoComplete: FC<AutoCompleteProps> = ({ options, value, onChange, label, sx, itemName, ...rest }) => {
	return (
		<FormControl sx={{ position: 'relative', width: '100%', maxWidth: '410px', ...sx }} {...rest}>
			<InputLabel id={label} sx={{ textTransform: 'capitalize', position: 'absolute', top: -65, left: -10 }}>
				{label}
			</InputLabel>
			<Autocomplete
				sx={{ m: 0, p: 0, position: 'absolute', zIndex: 100, backgroundColor: 'white', width: '100%', top: -24 }}
				multiple
				options={options}
				disableCloseOnSelect
				disableClearable
				getOptionLabel={(option) => option.name}
				isOptionEqualToValue={(option, value) => option.name === value.name}
				value={value}
				onChange={(event, newValue) => {
					// this happens when you click the cross on an object, and the newValue is the remaining objects
					// find the difference between the old value and the new value
					const removed = value.filter((v) => !newValue.includes(v))[0];
					onChange(removed); // the parent checks if the removed object is in the list, if it is, it removes it
				}}
				renderOption={(props, option, { selected }) => {
					return (
						<MenuItem
							{...props}
							value={option.name}
							sx={{ opacity: option.isUsed !== '' && option.isUsed !== itemName ? 0.5 : 1 }}
							onClick={() => {
								onChange(option);
							}}
						>
							<Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
							{option.name}
						</MenuItem>
					);
				}}
				renderInput={(params) => {
					return <TextField {...params} placeholder={value.length <= 0 ? 'Devices' : undefined} />;
				}}
				size="small"
				limitTags={2}
			/>
		</FormControl>
	);
};

export default AutoComplete;
