import React, { useState } from 'react';

import { Typography } from '@mui/material';

import { MapContainer, TileLayer, Marker, Popup, Circle, ZoomControl } from 'react-leaflet';

const Map = ({ simNumber }) => {
	const [lng, setLng] = useState(12.592113);
	const [lat, setLat] = useState(55.686346);

	const [mapData, setMapData] = useState<Array<any>>([]);

	/*useEffect(() => {
		cacheFetcher(`${process.env.REACT_APP_API_URL}getlocation/${simNumber}`, 'mapData', {
			method: 'GET',
		})
			.then((data) => {
				const parsed = JSON.parse(data);
				const [lat, lng] = parsed.usage[0].place.geoJson.coordinates;
				// Set the center of the map to the first location

				const points = parsed.usage.map((point) => {
					const [lat, lng] = point.place.geoJson.coordinates;
					return {
						lat,
						lng,
						radius: point.place.radius,
						time: point.usageTime,
					};
				});

				setMapData(points);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);*/

	const getDateString = (date) => {
		const d = new Date(date);
		return `${d.toDateString()} ${d.toLocaleTimeString()}`;
	};

	return (
		<MapContainer
			//@ts-ignore, this is a bug in the types
			// check the props here: https://github.com/PaulLeCam/react-leaflet/blob/master/packages/react-leaflet/src/MapContainer.tsx#L10
			center={[lat, lng]}
			zoom={13}
			style={{ height: '100%', minHeight: '100px', width: '100%', borderRadius: '6px', boxShadow: '0px 2px 16px rgba(20, 12, 71, 0.1)' }}
		>
			<TileLayer //@ts-ignore, this is a bug in the types
				attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
				url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
			/>
			{mapData.map((point) => {
				return (
					<Marker key={`${point.lat},${point.lng}`} position={[point.lat, point.lng]}>
						<Popup>
							<Typography variant="body2" sx={{ margin: 0, padding: 0, fontSize: '11px' }}>
								The IotBee was here <br />
								at {getDateString(point.time)}
							</Typography>
						</Popup>
					</Marker>
				);
			})}

			{mapData.map((point, index) => {
				const center = [point.lat, point.lng];
				const radius = point.radius;
				const key = `${point.lat},${point.lng},${index}`;

				return <Circle key={key} center={center} pathOptions={{ fillColor: 'blue', fillOpacity: 0.2, weight: 1 }} />;
			})}
		</MapContainer>
	);
};

export default Map;
