import React, {FC} from 'react';
import {useEffect, useState} from 'react';

import {FormDialog, InputField, TextfieldSelect, ErrorText, RegularText, MultiSelect} from '@Iot-Bee/standard-web-library';

import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {InputAdornment, Box, IconButton, InputLabel, TextField} from '@mui/material';

import SetCustomHeaders from './SetCustomHeaders';
import {cacheFetcher, cacheKeys} from '../../../utils/fetch';
import {localStorageUpdateCallback} from '../../../utils/localstorageHandlers';
import {Alert, Device, DeviceWithSensor} from '../../../Types/types';
import {List, ListItemText} from '@mui/material';

//export the function in order to be able to test it
export const getDeviceSensorIntersection = (devices: string[], dict: {[key: string]: string[]}, allDevices: Device[]) => {
	// Device is selected by friendly name, need to find the correct device id
	const selectedIds: string[] = [];
	devices.forEach((element) => {
		const device = allDevices.find((elm) => elm.deviceName === element);
		if (device) {
			selectedIds.push(device.id);
		} else {
			selectedIds.push(element);
		}
	});
	return selectedIds.reduce<string[]>((acc: string[], deviceId: string | number, index: number) => {
		let sensors = dict[deviceId] || [];

		// Check if the current device is an energySavingTool
		const device = allDevices.find((elm) => elm.id === deviceId);
		if (device?.deviceType === 'energySavingTool') {
			sensors = sensors.filter((sensor) => !sensor.toLowerCase().startsWith('shelly'));
		}

		sensors = sensors.filter((sensor) => sensor.trim() !== '');

		if (index === 0) {
			// Initialize with the sensors of the first selected device
			return sensors;
		}
		// Compute intersection with the next device's sensors
		return acc.filter((sensor) => sensors.includes(sensor));
	}, []);
};

const actions = ['email', 'HTTP', 'MQTT'];

interface AlertDialogProps {
	alertItem: null | Alert;
	onClose: () => void;
	onSave: (data: Alert) => void;
	isEditDialog: boolean;
	conditionValidator: (e: string) => void;
	conditionError: string;
	clientIdDevices: Device[];
}

const AlertDialog: FC<AlertDialogProps> = ({alertItem, onClose, onSave, isEditDialog, conditionValidator, conditionError, clientIdDevices}) => {
	const [alertData, setAlertData] = useState<Alert>(
		alertItem ?? {
			id: -1,
			name: '',
			condition: '',
			deviceIds: [],
			sensorType: [],
			action: '',
			recipient: '',
			triggerInterval: 20,
			message: '',
			subject: '',
			customheaders: [{key: '', value: ''}],
			mqttCredentials: {port: 0, username: '', password: ''},
		}
	); // Custom headers are set in SetCustomHeaders.jsx
	const [clientIdSensors, setClientIdSensors] = useState<string[]>([]);
	const [showPassword, setShowPassword] = useState(false);

	const [deviceWithSensors, setDeviceWithSensors] = useState<{
		[key: string]: string[];
	}>({});
	useEffect(() => {
		cacheFetcher<DeviceWithSensor[]>(`${process.env.REACT_APP_API_URL}getclientidsensors`, cacheKeys.clientIdSensors, localStorageUpdateCallback)
			.then((data) => {
				const deviceSensorMap: {[key: string]: string[]} = {};
				data.forEach((sensor) => {
					if (!deviceSensorMap[sensor.deviceId]) {
						deviceSensorMap[sensor.deviceId] = [];
					}
					deviceSensorMap[sensor.deviceId].push(sensor.sensor);
				});
				setDeviceWithSensors(deviceSensorMap);
				setClientIdSensors(getDeviceSensorIntersection(alertData.deviceIds, deviceSensorMap, clientIdDevices));
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	const handleDevicesChange = (e: string[]) => {
		setAlertData((state) => ({
			...state,
			sensorType: [],
			deviceIds: e,
		}));

		setClientIdSensors(getDeviceSensorIntersection(e, deviceWithSensors, clientIdDevices));
	};

	const handleSensorTypeChange = (e) => {
		setAlertData((state) => ({
			...state,
			sensorType: e,
		}));
	};

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;
		setAlertData((state) => ({
			...state,
			mqttCredentials: {
				...state.mqttCredentials,
				password: value,
			},
		}));
	};

	const actionText = alertItem?.id ? 'Update' : 'Add';
	return (
		<FormDialog
			title={`${actionText} alert`}
			buttonText={`${actionText} alert`}
			open={true}
			onClose={onClose}
			onSave={() => {
				//Convert from friendly name to device ids
				const selectedIds: string[] = [];
				alertData.deviceIds.forEach((element) => {
					const device = clientIdDevices.find((elm) => elm.deviceName === element);
					if (device) {
						selectedIds.push(device.id);
					}
				});
				alertData.deviceIds = selectedIds;
				onSave(alertData);
			}}
			maxWidth="xs">
			<InputField
				label="Name"
				value={String(alertData.name)}
				onChange={(value) =>
					setAlertData((state) => ({
						...state,
						name: value,
					}))
				}
				sx={{pb: 5, display: 'block'}}
				fullWidth
				required
			/>
			<RegularText sx={{fontSize: 14}}>Device ID *</RegularText>
			<MultiSelect
				options={clientIdDevices.map((item) => item.deviceName || item.id)}
				value={alertData.deviceIds}
				selectAllOption={true}
				onChange={handleDevicesChange}
				size="small"
				sx={{pb: 5}}
				fullWidth
				required
			/>
			<RegularText sx={{fontSize: 14}}>
				Sensor type *{' '}
				{alertData.deviceIds.length === 0
					? ' - (Please select a device ID to see the available sensors.)'
					: clientIdSensors.length === 0
					? ' - (No common sensors found among the selected devices.)'
					: ''}
			</RegularText>
			<MultiSelect
				options={clientIdSensors.map((item) => item)}
				value={alertData.sensorType}
				selectAllOption={true}
				onChange={handleSensorTypeChange}
				sx={{pb: 5}}
				size="small"
				fullWidth
				required
			/>
			<Box sx={{pb: 5}}>
				<InputField
					label="Condition"
					type="string"
					value={String(alertData.condition)}
					onChange={(value) => {
						setAlertData((state) => ({
							...state,
							condition: value,
						}));
					}}
					onBlur={(e) => {
						conditionValidator(e);
					}}
					sx={{display: 'block'}}
					required
					tooltipText={
						<Box>
							<RegularText sx={{fontSize: 'small'}}>The $ will be substituted with the sensor value.</RegularText>
							<RegularText sx={{fontSize: 'small'}}>For example, if the sensor value is 11, the condition "$&gt;10" will be true and an alert will be triggered.</RegularText>
							<RegularText sx={{fontSize: 'small'}}>Available operators:</RegularText>
							<List sx={{p: 0}}>
								<ListItemText sx={{color: 'black'}}>
									<RegularText sx={{fontSize: 'small'}}>
										<b>&gt;</b>, greater than - $&gt;10
									</RegularText>
								</ListItemText>
								<ListItemText sx={{color: 'black'}}>
									<RegularText sx={{fontSize: 'small'}}>
										<b>&lt;</b>, less than - $&lt;10
									</RegularText>
								</ListItemText>
								<ListItemText sx={{color: 'black'}}>
									<RegularText sx={{fontSize: 'small'}}>
										<b>&gt;=</b>, greater than or equal to - $&gt;=10
									</RegularText>
								</ListItemText>
								<ListItemText sx={{color: 'black'}}>
									<RegularText sx={{fontSize: 'small'}}>
										<b>&lt;=</b>, less than or equal to - $&lt;=10
									</RegularText>
								</ListItemText>
								<ListItemText sx={{color: 'black'}}>
									<RegularText sx={{fontSize: 'small'}}>
										<b>==</b>, equal to - $==10
									</RegularText>
								</ListItemText>
								<ListItemText sx={{color: 'black'}}>
									<RegularText sx={{fontSize: 'small'}}>
										<b>!=</b>, not equal to - $!=10
									</RegularText>
								</ListItemText>
							</List>
						</Box>
					}
					fullWidth
					placeholder="$==10"
				/>
				{conditionError && <ErrorText>{conditionError}</ErrorText>}
			</Box>
			<InputField
				label="Minutes between alerts"
				type="number"
				value={alertData.triggerInterval.toString()}
				onChange={(num) => {
					setAlertData((state) => ({
						...state,
						triggerInterval: +num,
					}));
				}}
				sx={{pb: 5, display: 'block'}}
				required
			/>
			<InputField
				label="Message"
				value={String(alertData.message)}
				onChange={(value) =>
					setAlertData((state) => ({
						...state,
						message: value,
					}))
				}
				sx={{pb: 5, display: 'block'}}
				fullWidth
				required
			/>
			<RegularText sx={{fontSize: 14}}>Action *</RegularText>
			<TextfieldSelect
				options={actions}
				value={String(alertData.action)}
				placeholder="Select action"
				sx={{pb: 5}}
				onChange={(e) =>
					setAlertData((state) => ({
						...state,
						action: e.target.value as 'email' | 'HTTP' | 'MQTT',
					}))
				}
				required
				fullWidth
			/>
			<InputField
				label={alertData.action === '' ? 'Email address, HTTP url, or MQTT broker' : alertData.action === 'email' ? 'Recipient' : alertData.action === 'HTTP' ? 'HTTP url' : 'MQTT Broker URL'}
				type={alertData.action === '' ? 'text' : alertData.action === 'email' ? 'email' : alertData.action === 'HTTP' ? 'url' : alertData.action === 'MQTT' ? 'text' : 'url'}
				value={alertData.recipient}
				disabled={alertData.action === ''}
				sx={{pb: 5}}
				onChange={(value) =>
					setAlertData((state) => ({
						...state,
						recipient: value,
					}))
				}
				required
				fullWidth
			/>
			{alertData.action === 'email' && (
				<InputField
					label="Message subject"
					value={String(alertData.subject)}
					onChange={(value) =>
						setAlertData((state) => ({
							...state,
							subject: value,
						}))
					}
					sx={{pb: 5}}
					fullWidth
					required
				/>
			)}
			{alertData.action === 'HTTP' && <SetCustomHeaders onChange={(newData) => setAlertData((state) => ({...state, customheaders: newData}))} startHeaders={alertData.customheaders} />}
			{alertData.action === 'MQTT' && (
				<Box>
					<InputField
						label="Port"
						type="number"
						value={String(alertData.mqttCredentials.port)}
						onChange={(e) => {
							setAlertData((state) => ({
								...state,
								mqttCredentials: {
									...state.mqttCredentials,
									port: Number(e),
								},
							}));
						}}
						sx={{pb: 5, display: 'block'}}
						required
					/>
					<Box sx={{display: 'flex'}}>
						<InputField
							label="Username"
							value={String(alertData.mqttCredentials.username)}
							onChange={(value) =>
								setAlertData((state) => ({
									...state,
									mqttCredentials: {
										port: state.mqttCredentials.port,
										username: value,
										password: state.mqttCredentials.password,
									},
								}))
							}
							sx={{marginRight: '10px', width: '60%'}}
							fullWidth
							required
						/>
						<Box>
							<InputLabel shrink color="primary" sx={{fontSize: '17.5px', fontFamily: 'DM Sans', textTransform: 'capitalize'}}>
								Password *
							</InputLabel>
							<TextField
								type={showPassword ? 'text' : 'password'}
								value={String(alertData.mqttCredentials.password)}
								onChange={handlePasswordChange}
								sx={{pb: 5}}
								fullWidth
								required
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
												{showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
											</IconButton>
										</InputAdornment>
									),
								}}
								size="small"
							/>
						</Box>
					</Box>
				</Box>
			)}
		</FormDialog>
	);
};

export default AlertDialog;
