import React, { FC } from 'react';
import { Box, Dialog } from '@mui/material';
import { CardTitle, RegularText, RedButton, GreenButton, Card, AlertRed } from '@Iot-Bee/standard-web-library';
import ReportIcon from '@mui/icons-material/Report';

interface MoveDeviceDialogProps {
	moveDeviceDialogInfo: { isOpen: boolean; device: string; onItem: string };
	resetMoveDeviceDialogInfo: () => void;
	handleMoveDevice: (device: { name: string; isUsed: string; moveFrom: string }) => void;
	itemName: string;
}

const MoveDeviceDialog: FC<MoveDeviceDialogProps> = ({ moveDeviceDialogInfo, resetMoveDeviceDialogInfo, handleMoveDevice, itemName }) => {
	return (
		<Dialog
			open={moveDeviceDialogInfo.isOpen}
			onClose={() => {
				resetMoveDeviceDialogInfo();
			}}
		>
			<Card
				title={
					<CardTitle sx={{ display: 'flex', alignItems: 'center' }}>
						<ReportIcon sx={{ mr: 2, color: AlertRed }} /> Device already has schedule
					</CardTitle>
				}
			>
				<Box sx={{ width: 300, height: 200, px: 8, py: 4, boxSizing: 'border-box' }}>
					<RegularText>
						The device <span style={{ fontWeight: '600', textDecoration: 'underline' }}>{moveDeviceDialogInfo.device}</span> is already used on schedule{' '}
						<span style={{ fontWeight: '600', textDecoration: 'underline' }}>{moveDeviceDialogInfo.onItem}</span>
					</RegularText>
					<Box sx={{ mt: 8 }}>
						<RegularText textAlign="center">Do you want to move it to this schedule?</RegularText>
						<Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
							<RedButton
								sx={{ fontSize: 14 }}
								onClick={() => {
									resetMoveDeviceDialogInfo();
								}}
							>
								No
							</RedButton>
							<GreenButton
								sx={{ fontSize: 14 }}
								onClick={() => {
									handleMoveDevice({ name: moveDeviceDialogInfo.device, isUsed: itemName, moveFrom: moveDeviceDialogInfo.onItem });
									resetMoveDeviceDialogInfo();
								}}
							>
								Yes, move it
							</GreenButton>
						</Box>
					</Box>
				</Box>
			</Card>
		</Dialog>
	);
};

export default MoveDeviceDialog;
