import FetchResponse from '../Types/FetchResponse';
import {
	Device,
	Sensor,
	Calibration,
	CalenderSchedule,
	Schedule,
	ServerDevice,
	TimeFormat,
	DeviceSetting,
	ServerDeviceSetting,
	TimeSetting,
	ValueSetting,
	DynamicSetting,
	dynamicOperators,
	DynamicOperators,
	logicalOperators,
	LogicalOperators,
} from '../Types/types';
import {LocalstorageData} from '../Types/LocalStorageData';
import {utcTimestampToLocale} from '../pages/Scheduling/Utils/TimeConverter';

function getStatus(lastPing: string, frequency: number): 'Active' | 'Inactive' {
	const now = new Date().getTime();

	const timeZoneOffset = new Date().getTimezoneOffset() * 60 * 1000;

	const lastPingDate = new Date(lastPing).getTime() - timeZoneOffset;

	const secondsElapsed = (now - lastPingDate) / 1000;

	const extraBuffer = frequency * 2;

	return secondsElapsed <= extraBuffer ? 'Active' : 'Inactive';
}

export function userDevicesLocalStorageUpdateCallback(data: FetchResponse<any>, cachekey = 'userDevices'): Device[] {
	if (data.success) {
		const res: Device[] = data.message.map((device: ServerDevice) => {
			return {
				deviceName: device.friendlyName || device.id,
				status: device.isActive ? 'Active' : 'Inactive',
				id: device.id,
				calibrations: undefined,
				dataUsage: device.dataUsage + ' MB',
				tags: device.deviceTags.map((tag) => tag.name).join(', '),
				deviceType: device.deviceType,
			};
		});
		const localStorageData: LocalstorageData<Device[]> = {
			createdDate: new Date(),
			data: res,
		};
		localStorage.setItem(cachekey, JSON.stringify(localStorageData));
		return res;
	}
	return [];
}

export function userDeviceTagsLocalStorageUpdateCallback(data: FetchResponse<any>, cacheKey = 'deviceTags'): string[] {
	if (data.success) {
		const res: string[] = data.message.map((tag) => tag.tags);
		const localStorageData: LocalstorageData<string[]> = {
			createdDate: new Date(),
			data: res,
		};
		localStorage.setItem(cacheKey, JSON.stringify(localStorageData));
		return res;
	}
	return [];
}

export function calibrationsMapLocalStorageUpdateCallback(data: FetchResponse<any>, cacheKey = 'calibrationsMap'): {[key: string]: Calibration} {
	if (data.success) {
		const map = {};
		data.message.forEach((element: Sensor) => {
			map[element.sensor] = element.calibration;
		});
		const localStorageData: LocalstorageData<{[key: string]: Calibration}> = {
			createdDate: new Date(),
			data: map,
		};
		localStorage.setItem(cacheKey, JSON.stringify(localStorageData));
		return map;
	}
	return {};
}

const dayConverter = (day) => {
	const smallDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
	const bigDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
	let smallIndex = smallDays.indexOf(day);
	let bigIndex = bigDays.indexOf(day);
	if (smallIndex !== -1) {
		return bigDays[smallIndex];
	} else if (bigIndex !== -1) {
		return smallDays[bigIndex];
	} else {
		return day;
	}
};

export const backendScheduleToCalenderSchedule = (schedule: Schedule): CalenderSchedule => {
	const calenderSchedule = {
		id: schedule.id,
		name: schedule.name,
		devices: schedule.devices.map((device) => {
			return device.friendlyName ? device.friendlyName : device.id;
		}),
		events: schedule.scheduleEvents.map((event) => {
			return {
				id: event.id,
				start: {
					day: dayConverter(event.days[0]),
					time: (event.startTime.split(':')[0] + ':' + event.startTime.split(':')[1]) as TimeFormat,
				},
				end: {
					day: dayConverter(event.days[event.days.length - 1]),
					time: (event.endTime.split(':')[0] + ':' + event.endTime.split(':')[1]) as TimeFormat,
				},
			};
		}),
		allDevices: '',
		temperatures: {on: 0, off: 0},
	};
	return calenderSchedule;
};

export function schedulingLocalStorageUpdateCallback(data: FetchResponse<any[]>, cacheKey = 'schedules'): CalenderSchedule[] {
	if (data.success) {
		const mapped = data.message.map((schedule) => {
			const calenderSchedule = backendScheduleToCalenderSchedule(schedule);
			calenderSchedule.allDevices = schedule.devices
				.map((device: ServerDevice) => {
					return device.friendlyName ? device.friendlyName : device.id;
				})
				.join(', ');
			if (calenderSchedule.allDevices.length > 50) {
				calenderSchedule.allDevices = calenderSchedule.allDevices.substring(0, 50) + '...';
			}
			return calenderSchedule;
		});

		const localStorageData: LocalstorageData<CalenderSchedule[]> = {
			createdDate: new Date(),
			data: mapped,
		};

		localStorage.setItem(cacheKey, JSON.stringify(localStorageData));

		return mapped;
	}
	return [];
}

function createSettingValue(value: number | string, operator: string | undefined): TimeSetting | ValueSetting | DynamicSetting {
	if (operator) {
		if (dynamicOperators.includes(operator as DynamicOperators)) {
			return {value: value as string, operator: operator as DynamicOperators} as DynamicSetting;
		}

		if (logicalOperators.includes(operator as LogicalOperators)) {
			return {value: value as string, operator: operator as LogicalOperators} as ValueSetting;
		}

		return value as TimeSetting;
	} else {
		if (typeof value === 'number') {
			return value as TimeSetting;
		} else {
			return value as unknown as TimeSetting;
		}
	}
}

export function serverDeviceSettingToBrowser(deviceSetting: ServerDeviceSetting): DeviceSetting {
	const operationsCopy = [...deviceSetting.deviceSettingOperations];
	let allDevices = deviceSetting.devices
		.map((device) => {
			return device.friendlyName ? device.friendlyName : device.imei;
		})
		.join(', ');

	if (allDevices.length > 50) {
		allDevices = allDevices.substring(0, 50) + '...';
	}
	return {
		id: deviceSetting.id,
		name: deviceSetting.name,
		devices: deviceSetting.devices.map((device) => {
			return device.friendlyName ? device.friendlyName : device.imei;
		}),
		transmissionSettings: operationsCopy
			.filter((operation) => {
				if (operation.settingType === 'transmit') {
					return true;
				}
			})
			.map((operation) => {
				return {
					type: operation.type as 'time' | 'value' | 'dynamic',
					value: createSettingValue(operation.value, operation.operation),
					connection: operation.connection as 'and' | 'or' | 'finished',
				};
			}),
		collectionSettings: operationsCopy
			.filter((operation) => {
				if (operation.settingType === 'collect') {
					return true;
				}
			})
			.map((operation) => {
				return {
					type: operation.type as 'time' | 'value' | 'dynamic',
					value: createSettingValue(operation.value, operation.operation),
					connection: operation.connection as 'and' | 'or' | 'finished',
				};
			}),
		allDevices: allDevices,
	};
}

export function deviceSettingsLocalStorageUpdateCallback(data: FetchResponse<any[]>, cacheKey = 'deviceSettings'): DeviceSetting[] {
	if (data.success) {
		const mapped = data.message.map((deviceSetting) => {
			return serverDeviceSettingToBrowser(deviceSetting);
		});

		const localStorageData: LocalstorageData<DeviceSetting[]> = {
			createdDate: new Date(),
			data: mapped,
		};

		localStorage.setItem(cacheKey, JSON.stringify(localStorageData));

		return mapped;
	}
	return [];
}

export function localStorageUpdateCallback<T>(data: FetchResponse<T>, cacheKey: string): T {
	const localStorageData: LocalstorageData<T> = {
		createdDate: new Date(),
		data: data.message,
	};

	localStorage.setItem(cacheKey, JSON.stringify(localStorageData));
	return data.message;
}

export function appendToArrayInLocalStorage<T>(newItem: T, cacheKey: string): void {
	// Retrieve existing data from local storage
	const existingDataStr = localStorage.getItem(cacheKey);

	if (existingDataStr) {
		// If data exists, parse it and check if it's an array
		const existingData: LocalstorageData<T[]> = JSON.parse(existingDataStr);

		if (Array.isArray(existingData.data)) {
			// If it's an array, append the new item to it
			existingData.data.push(newItem);

			// Update the created date
			existingData.createdDate = new Date();

			// Save the updated data back to local storage
			localStorage.setItem(cacheKey, JSON.stringify(existingData));
		} else {
			// If it's not an array, handle the error or adjust behavior accordingly
			console.error('Existing data is not an array.');
		}
	} else {
		// If no data exists yet, create a new entry with the new item
		const newData: LocalstorageData<T[]> = {
			createdDate: new Date(),
			data: [newItem],
		};

		// Save the new data to local storage
		localStorage.setItem(cacheKey, JSON.stringify(newData));
	}
}

export function updateDeviceTagsArrayInLocalStorage(cacheKeyTags: string, cacheKeyDevices: string): void {
	const existingDataStr = localStorage.getItem(cacheKeyDevices);
	if (existingDataStr) {
		const existingData: LocalstorageData<any> = JSON.parse(existingDataStr);
		if (Array.isArray(existingData.data)) {
			// Extract tags
			const tagsArray = existingData.data.map((item) => item.tags).filter((tag) => tag !== '');

			// Remove duplicates using a Set
			const distinctTagsSet = new Set(tagsArray);

			// Convert Set back to an array
			const distinctTags = Array.from(distinctTagsSet);
			//overwrite the existing data which is Device[] to a string[]
			existingData.createdDate = new Date();
			existingData.data = distinctTags;
			localStorage.setItem(cacheKeyTags, JSON.stringify(existingData));
		}
	}
	/*const localStorageDataStr =  localStorage.getItem(cacheKey);
	if(localStorageDataStr){
		const localStorageData = JSON.parse(localStorageDataStr);
		if(Array.isArray(localStorageData.data)){
			if(localStorageData.data.includes(newTag)){
				return;
			}
			localStorageData.data.push(newTag);
			localStorageData.date = new Date();
			localStorage.setItem(cacheKey, JSON.stringify(localStorageData));
		}
		
	}
	return;*/
}

export function updateItemInArrayInLocalStorage<T>(predicate: (item: T) => boolean, newItem: T, cacheKey: string): void {
	// Retrieve existing data from local storage
	const existingDataStr = localStorage.getItem(cacheKey);

	if (existingDataStr) {
		// If data exists, parse it and check if it's an array
		const existingData: LocalstorageData<T[]> = JSON.parse(existingDataStr);

		if (Array.isArray(existingData.data)) {
			// If it's an array, find the index of the item based on the predicate function
			const index = existingData.data.findIndex(predicate);

			if (index !== -1) {
				// If the item is found, replace it with the new item
				existingData.data[index] = newItem;

				// Update the created date
				existingData.createdDate = new Date();

				// Save the updated data back to local storage
				localStorage.setItem(cacheKey, JSON.stringify(existingData));
			} else {
				// Handle item not found error
				console.error('Item not found.');
			}
		} else {
			// If it's not an array, handle the error
			console.error('Existing data is not an array.');
		}
	} else {
		// If no data exists yet, handle the error
		console.error('No existing data found.');
	}
}

export function deleteItemFromArrayInLocalStorage<T>(predicate: (item: T) => boolean, cacheKey: string): void {
	// Retrieve existing data from local storage
	const existingDataStr = localStorage.getItem(cacheKey);

	if (existingDataStr) {
		// If data exists, parse it and check if it's an array
		const existingData: LocalstorageData<T[]> = JSON.parse(existingDataStr);

		if (Array.isArray(existingData.data)) {
			// If it's an array, find the index of the item based on the predicate function
			const index = existingData.data.findIndex(predicate);

			if (index !== -1) {
				// If the item is found, remove it from the array
				existingData.data.splice(index, 1);

				// Update the created date
				existingData.createdDate = new Date();

				// Save the updated data back to local storage
				localStorage.setItem(cacheKey, JSON.stringify(existingData));
			} else {
				// Handle item not found error
				console.error('Item not found.');
			}
		} else {
			// If it's not an array, handle the error
			console.error('Existing data is not an array.');
		}
	} else {
		// If no data exists yet, handle the error
		console.error('No existing data found.');
	}
}
