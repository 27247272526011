export const nightTheme: ChartTheme = {
	mode: 'night',
	baseTheme: 'ag-default-dark',
	textColor: 'white',
	displayText: 'Outside Working Hours',
	backgroundColor: '#140C47',
};

export const dayTheme: ChartTheme = {
	mode: 'day',
	baseTheme: 'ag-default',
	textColor: 'black',
	displayText: 'Inside Working Hours',
	backgroundColor: 'rgba(12, 92, 248, 0.1)',
};

export const totalTheme: ChartTheme = {
	mode: 'total',
	baseTheme: 'ag-default-dark',
	textColor: 'white',
	displayText: 'Total',
	backgroundColor: '#8282A3',
};

export const insideOutsideFills = [
	'#455d7a', // Existing dark blue
	'#f95959', // Existing red
];

export const fills = [
	'#66bfbf', // Existing teal
	'#393e46', // Existing dark grey
	'#5c5470', // Existing muted purple
	'#f96d00', // Existing orange
	'#74b9ff', // New light blue
	'#e3e3e3', // Existing light grey
	'#fd79a8', // New bright pink
	'#55efc4', // New mint green
	'#d6a5c0', // Soft Lavender
	'#003366', // Deep Blue
	'#9bba9f', // Light Sage Green
	'#f4c542', // Golden Yellow
];
