import React, {useState, useEffect} from 'react';
import {Box, Stack, Slider, Typography} from '@mui/material';
import {SubtleGrey, useWindowDimensions, MultiSelect, Dropdown, useSnackbar} from '@Iot-Bee/standard-web-library';
import DateSelector from '../../../components/form/DateSelector';
import {noCacheFetcher, cacheFetcher, cacheKeys} from '../../../utils/fetch';
import dayjs, {Dayjs} from 'dayjs';
import {userDeviceTagsLocalStorageUpdateCallback} from '../../../utils/localstorageHandlers';
import {color} from 'highcharts';

export const formatValueLabel = (value) => {
	const hours = Math.floor(value / 60);
	const minutes = value % 60;
	return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
};

const DashboardDataFilter = ({
	selectedDevices,
	setSelectedDevices,
	selectedTags,
	setSelectedTags,
	devicesData,
	setDevicesData,
	workingHoursRange,
	setWorkingHoursRange,
	devices,
	isLoading,
	fromDate,
	setFromDate,
	toDate,
	setToDate,
	setDeviceIdFriendlynameMap,
}) => {
	const [availableTags, setAvailableTags] = useState<string[]>(['Select All']);
	const [availableDevices, setAvailableDevices] = useState<string[]>(['Select All']);
	const [fromDateError, setFromDateError] = useState<Boolean>(false);
	const [toDateError, setToDateError] = useState<Boolean>(true);
	const {
		actions: {openSnackbar},
	} = useSnackbar();

	const formatDateToMySQLTimestamp = (date: Dayjs) => {
		const year = date.format('YYYY');
		const month = date.format('MM');
		const day = date.format('DD');
		const hours = date.format('HH');
		const minutes = date.format('mm');
		const seconds = date.format('ss');

		return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
	};

	const fetchDashboardData = async (fromDate: Dayjs, toDate: Dayjs, devices: any) => {
		if (devices.length > 0) {
			const url =
				process.env.REACT_APP_API_URL +
				`getdashboardkwh/?from=${formatDateToMySQLTimestamp(fromDate)}&to=${formatDateToMySQLTimestamp(toDate)}
			&label=${encodeURIComponent('kwh')}`;

			try {
				const response = await noCacheFetcher(url, {
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
					},
				});
				const data: DashboardServerResponse = await response.json();

				data.message.sort((a, b) => new Date(a.time).getTime() - new Date(b.time).getTime());

				const deviceTags = new Set<string>();
				const deviceWithData = data.message.reduce((acc, curr) => {
					if (!acc[curr.deviceId]) {
						const device = devices.find((dev) => dev.id === curr.deviceId);
						const tags = device.tags === null || device.tags === undefined || device.tags === '' ? 'No tags' : device.tags;
						deviceTags.add(tags);
						acc[curr.deviceId] = {
							sensorId: curr.deviceId,
							data: [],
							tags: [tags],
						};
					}
					acc[curr.deviceId].data.push({
						timestamp: curr.time,
						accumulatedValue: Number(curr.value.toFixed(3)),
					});
					return acc;
				}, {});

				const devicesArray: DeviceData[] = Object.values(deviceWithData);
				setDevicesData(devicesArray);

				const allSensorIds = devicesArray.map((device) => device.sensorId);
				const tagsArray = Array.from(deviceTags);

				// Return all the necessary data
				return {
					allSensorIds,
					tagsArray,
				};
			} catch (error) {
				console.error('Error fetching data:', error);
				return null;
			}
		}
		return null;
	};

	useEffect(() => {
		if (devices.length > 0) {
			const friendlyNameIdMap = new Map();
			devices.forEach((device) => {
				const value = device.deviceName && device.deviceName.trim() !== '' ? device.deviceName : device.id;
				friendlyNameIdMap.set(device.id, value);
			});
			setDeviceIdFriendlynameMap(friendlyNameIdMap);
			fetchDashboardData(fromDate, toDate, devices).then((result) => {
				if (result) {
					const {allSensorIds, tagsArray} = result;
					const friendlyNames: string[] = [];
					allSensorIds.forEach((id) => {
						const friendlyName: string = friendlyNameIdMap.get(id); // Get the value for the given id from the Map
						if (friendlyName) {
							// If a friendly name exists for the id, add it to the friendlyNames array
							friendlyNames.push(friendlyName);
						} else {
							// If no friendly name exists, add the id instead
							friendlyNames.push(id);
						}
					});
					setSelectedDevices(friendlyNames);
					setAvailableDevices(friendlyNames);
					setSelectedTags(tagsArray);
					setAvailableTags(tagsArray);
				}
			});
		}
	}, [isLoading]);

	const handleDeviceChange = (value) => {
		setSelectedDevices(value);
	};

	const handleTagsChange = (value) => {
		setSelectedTags(value);
	};

	const handleChangeWorkingHours = (event, newRange) => {
		setWorkingHoursRange(newRange);
	};

	const handleFromDateChange = (event) => {
		const newFromDate = dayjs(event);
		if (newFromDate.isAfter(dayjs(toDate))) {
			openSnackbar('error', 'You set the from date to be after to date!');
			setFromDateError(true);
		}
		setFromDateError(false);
		setFromDate(event);
		fetchDashboardData(event, toDate, devices);
	};

	const handleToDateChange = (event) => {
		const newToDate = dayjs(event);
		if (newToDate.isBefore(dayjs(fromDate))) {
			openSnackbar('error', 'You set the to date to be before from date!');
			setToDateError(true);
		}
		setToDateError(false);
		setToDate(event);
		fetchDashboardData(fromDate, event, devices);
	};

	const {screenwidth} = useWindowDimensions();

	if (screenwidth > 768) {
		return (
			<Stack direction="row" spacing={10} sx={{p: '20px', background: SubtleGrey, alignItems: 'center'}}>
				<MultiSelect options={availableDevices} value={selectedDevices} selectAllOption={true} onChange={handleDeviceChange} label="Devices" size="small" sx={{width: '15%'}} />
				<MultiSelect options={availableTags} value={selectedTags} selectAllOption={true} onChange={handleTagsChange} label="Tags" size="small" sx={{width: '15%'}} />

				<DateSelector label="From date" value={fromDate} onChange={handleFromDateChange} sx={{width: '15%'}} />
				<DateSelector sx={{marginLeft: '20px', marginRight: '30px', width: '15%'}} label="To date" value={toDate} onChange={handleToDateChange} />

				<Box sx={{width: '25%'}}>
					<Typography id="input-slider" gutterBottom>
						Working Hours: {formatValueLabel(workingHoursRange[0])} - {formatValueLabel(workingHoursRange[1])}
					</Typography>
					<Slider
						sx={{width: '100%'}}
						getAriaLabel={() => 'Temperature range'}
						value={workingHoursRange}
						onChange={handleChangeWorkingHours}
						min={0}
						step={30}
						max={1439}
						valueLabelDisplay="auto"
						valueLabelFormat={formatValueLabel}
					/>
				</Box>
			</Stack>
		);
	} else {
		return (
			<Box
				sx={{
					p: '10px',
					background: 'SubtleGrey',
					display: 'flex',
					flexFlow: 'row wrap',
					justifyContent: 'center',
					gap: '10px',
					alignItems: 'center',
				}}>
				<MultiSelect
					options={devicesData.map((device) => device.sensorId)}
					value={selectedDevices}
					onChange={handleDeviceChange}
					label="Devices"
					size="small"
					sx={{width: '330px', marginBottom: '15px'}}
				/>
				<MultiSelect options={availableTags} value={selectedTags} onChange={handleTagsChange} label="Tags" size="small" sx={{width: '330px', marginBottom: '15px'}} />
				<Box>
					<DateSelector label="From date" value={fromDate} onChange={handleFromDateChange} />
					<DateSelector sx={{marginLeft: '20px', backgound: fromDateError ? 'red' : 'inherit'}} label="To date" value={toDate} onChange={handleToDateChange} />
				</Box>
				<Box>
					<Typography id="input-slider" gutterBottom>
						Working Hours
					</Typography>
					<Slider
						sx={{width: '330px'}}
						getAriaLabel={() => 'Temperature range'}
						value={workingHoursRange}
						onChange={handleChangeWorkingHours}
						min={0}
						step={30}
						max={1439}
						valueLabelDisplay="auto"
						valueLabelFormat={formatValueLabel}
					/>
				</Box>
			</Box>
		);
	}
};

export default DashboardDataFilter;
