import React, { FC } from 'react';
import { Card } from '@Iot-Bee/standard-web-library';
import { Box } from '@mui/material';

import DataFilter from './DataFilter';

import StaticData from './StaticData';

const DeviceDataCard: FC = () => {
	return (
		<Card title="Data">
			<Box>
				<DataFilter />
				<StaticData />
			</Box>
		</Card>
	);
};

export default DeviceDataCard;
