import React, {FC, useRef, useState} from 'react';

import {FormDialog, InputField, Dropdown} from '@Iot-Bee/standard-web-library';
import {Device, DeviceTag} from '../../../Types/types';
import {Box, Autocomplete, TextField, Chip, Select, InputLabel, MenuItem} from '@mui/material';

interface DeviceDialogProps {
	device: Device | null;
	open: boolean;
	onClose: () => void;
	onSave: (device: Device) => void;
}

const DeviceDialog: FC<DeviceDialogProps> = ({device, open, onClose, onSave}) => {
	const [deviceData, setDeviceData] = useState<Device>(device ?? ({} as Device));

	const [typedTag, setTypedTag] = useState('');
	const {deviceName, id} = deviceData;

	const [selectedTag, setSelectedTag] = useState(device?.tags ? device.tags.split(', ')[0] : '');

	const [standardTags, setStandardTags] = useState(['Crane/Lift', 'Container', 'Drying', 'City', 'Construction', 'Lighting', 'Other Total']);

	const [displayedStandardTags, setDisplayedStandardTags] = useState(standardTags);

	// State to manage selected tags
	const [tags, setTags] = useState<string[]>(device?.tags ? device.tags.split(', ') : []);

	//The following commented out code should not be removed, it is for assigning multiple tags to one device in the future
	/*
  // Handle tag changes
  const handleTagChangeBySelect = (event, newTags) => {
    setTags(newTags);
    setDeviceData((state) => ({
      ...state,
      tags: newTags.join(", "),
    }));
  };

  const handleTagChangeByInput = (event, value) => {
    setTypedTag(value);
    if (value.includes(",") && value.length > 1) {
      const val = value.split(",")[0];
      if (!tags.includes(val)) {
        setTags((prevTags) => {
          const newTags = [...prevTags, val];
          setDeviceData((state) => ({
            ...state,
            tags: newTags.join(", "),
          }));
          return newTags;
        });
      }
      setTypedTag("");
    }
  };

  //Prevent deleting a chip when backspace is enterd
  const handleKeyDown = (event) => {
    if (event.key === "Backspace" && !typedTag) {
      event.stopPropagation();
      event.preventDefault();
    }
  };*/

	const handleTagChange = (event, value) => {
		//setSelectedTag(event.target.value);
		setSelectedTag(value);
		setDeviceData((state) => ({
			...state,
			tags: value,
		}));
	};

	const handleTagChangeByInput = (event, value) => {
		//setSelectedTag(event.target.value);
		setTypedTag(value);
		setDeviceData((state) => ({
			...state,
			tags: value,
		}));
	};

	return (
		<FormDialog
			title={`Update device`}
			buttonText={`Update device`}
			open={open}
			onClose={onClose}
			onSave={() => {
				onSave(deviceData);
			}}
			maxWidth="xs">
			<Box sx={{mb: 4}}>
				<InputField
					label="Device name"
					value={deviceName}
					onChange={(value) =>
						setDeviceData((state) => ({
							...state,
							deviceName: value,
						}))
					}
					fullWidth
					required
				/>
			</Box>
			<Box sx={{mb: 8}}>
				<InputField
					label="ID"
					value={id}
					disabled
					onChange={(value) =>
						setDeviceData((state) => ({
							...state,
							id: value,
						}))
					}
					required
					fullWidth
				/>
			</Box>
			<Box sx={{mb: 4}}>
				{/*<Autocomplete
          multiple
          freeSolo
          options={displayedStandardTags}
          value={tags}
          inputValue={typedTag}
          onChange={handleTagChangeBySelect}
          onInputChange={handleTagChangeByInput}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                variant="outlined"
                label={option}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Select or Create Tags"
              placeholder="Add a tag"
              onKeyDown={handleKeyDown}
            />
          )}
        />*/}
				{/*This is only for selecting single tags, the above one is for selecting multiple tags*/}
				{device?.deviceType === 'energySavingTool' && (
					<Autocomplete
						freeSolo
						options={standardTags}
						value={selectedTag}
						inputValue={typedTag}
						onChange={handleTagChange}
						onInputChange={handleTagChangeByInput}
						renderInput={(params) => <TextField {...params} label="Tags" variant="outlined" fullWidth />}
						sx={{width: '100%'}}
					/>
				)}
			</Box>
		</FormDialog>
	);
};

export default DeviceDialog;
