import React, { createContext, useRef, useContext, useEffect } from 'react';
import { Calibration, Device, DeviceData } from '../Types/types';
import { LocalstorageData } from '../Types/LocalStorageData';

const defaultState = {
	getUserDevicesCache: () => {
		return [] as Device[];
	},
	setUserDevicesCache: (data: Device[]) => {},

	getCalibrationsCache: () => {},
	setCalibrationsCache: (data: Calibration[]) => {},
	addCalibrationToCache: (calibration: Calibration) => {},
	editCalibrationInCache: (calibration: Calibration) => {},
	deleteCalibrationFromCache: (calibration: Calibration) => {},

	getCalibrationsMapCache: (deviceId: string) => {},
	setCalibrationsMapCache: (data: any, deviceId: string) => {},
};

export const CacheHandlerContext = createContext(defaultState);

export const CacheHandlerProvider = ({ children }) => {
	// Load initial state from localStorage or use an empty array as the default
	const userDevices = useRef<Device[]>([]);

	const setUserDevicesCache = (data: Device[]) => {
		const localData: LocalstorageData<Device[]> = {
			createdDate: new Date(),
			data,
		};
		localStorage.setItem('userDevices', JSON.stringify(localData));
		userDevices.current = data;
	};

	const getUserDevicesCache = () => {
		const fromRef = userDevices.current;
		if (fromRef.length > 0) {
			return fromRef;
		} else {
			const savedData = localStorage.getItem('userDevices');
			return savedData ? (JSON.parse(savedData).data as Device[]) : ([] as Device[]);
		}
	};

	useEffect(() => {
		const savedData = localStorage.getItem('tableData');
		if (savedData) {
			userDevices.current = JSON.parse(savedData);
		}
	}, []);

	const calibrationsRef = useRef<Calibration[]>([]);

	const setCalibrationsCache = (data) => {
		const localData: LocalstorageData<any> = {
			createdDate: new Date(),
			data,
		};
		localStorage.setItem('calibrations', JSON.stringify(localData));
		calibrationsRef.current = data;
	};

	const getCalibrationsCache = () => {
		const fromRef = calibrationsRef.current;
		if (fromRef.length > 0) {
			return fromRef;
		} else {
			const savedData = localStorage.getItem('calibrations');
			return savedData ? JSON.parse(savedData) : [];
		}
	};

	const addCalibrationToCache = (calibration: Calibration) => {
		const savedData = localStorage.getItem('calibrations');
		const localData: LocalstorageData<Calibration[]> = savedData ? JSON.parse(savedData) : ({} as LocalstorageData<Calibration[]>);
		localData.data.push(calibration);
		const newLocalData: LocalstorageData<any> = {
			createdDate: new Date(),
			data: localData.data,
		};
		localStorage.setItem('calibrations', JSON.stringify(newLocalData));
		calibrationsRef.current = localData.data;
	};

	const editCalibrationInCache = (calibration: Calibration) => {
		const savedData = localStorage.getItem('calibrations');
		const localData: LocalstorageData<Calibration[]> = savedData ? JSON.parse(savedData) : ({} as LocalstorageData<Calibration[]>);
		const newData = localData.data.map((c: Calibration) => (c.id === calibration.id ? calibration : c));
		const newLocalData: LocalstorageData<any> = {
			createdDate: new Date(),
			data: newData,
		};
		localStorage.setItem('calibrations', JSON.stringify(newLocalData));
		calibrationsRef.current = newData;
	};

	const deleteCalibrationFromCache = (calibration: Calibration) => {
		const savedData = localStorage.getItem('calibrations');
		const localData: LocalstorageData<Calibration[]> = savedData ? JSON.parse(savedData) : ({} as LocalstorageData<Calibration[]>);
		const newData = localData.data.filter((c: Calibration) => c.id !== calibration.id);
		const newLocalData: LocalstorageData<any> = {
			createdDate: new Date(),
			data: newData,
		};
		localStorage.setItem('calibrations', JSON.stringify(newLocalData));
		calibrationsRef.current = newData;
	};

	const calibrationsMapRef = useRef(() => {
		const savedData = localStorage.getItem('calibrationsMap');
		return savedData ? JSON.parse(savedData) : [];
	});

	const setCalibrationsMapCache = (data, deviceId) => {
		const localData: LocalstorageData<any> = {
			createdDate: new Date(),
			data,
		};
		localStorage.setItem(`calibrationsMap-${deviceId}`, JSON.stringify(localData));
		calibrationsMapRef.current = data;
	};

	const getCalibrationsMapCache = (deviceId) => {
		const fromRef = calibrationsMapRef.current;
		if (fromRef.length > 0) {
			return fromRef;
		} else {
			const savedData = localStorage.getItem(`calibrationsMap-${deviceId}`);
			return savedData ? JSON.parse(savedData) : [];
		}
	};

	const alertsRef = useRef(() => {
		const savedData = localStorage.getItem('alerts');
		return savedData ? JSON.parse(savedData) : [];
	});

	const setAlertsCache = (data) => {
		localStorage.setItem('alerts', JSON.stringify(data));
		alertsRef.current = data;
	};

	const getAlerts = () => {
		const fromRef = alertsRef.current;
		if (fromRef.length > 0) {
			return fromRef;
		} else {
			const savedData = localStorage.getItem('alerts');
			return savedData ? JSON.parse(savedData) : [];
		}
	};

	return (
		<CacheHandlerContext.Provider
			value={{
				getUserDevicesCache,
				setUserDevicesCache,

				getCalibrationsCache,
				setCalibrationsCache,
				addCalibrationToCache,
				editCalibrationInCache,
				deleteCalibrationFromCache,

				getCalibrationsMapCache,
				setCalibrationsMapCache,
			}}
		>
			{children}
		</CacheHandlerContext.Provider>
	);
};

function useCache() {
	const cacheContext = useContext(CacheHandlerContext);

	if (!cacheContext) {
		throw new Error('useCache must be used within a CacheHandlerProvider');
	}

	return cacheContext;
}

export { useCache };
