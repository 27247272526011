import React, { FC, useState } from 'react';

import { FormDialog, InputField } from '@Iot-Bee/standard-web-library';
import { Calibration } from '../../../Types/types';

interface CalibrationDialogProps {
	calibration: null | Calibration;
	onClose: () => void;
	onSave: (data: Calibration) => void;
}

const CalibrationDialog: FC<CalibrationDialogProps> = ({ calibration, onClose, onSave }) => {
	const [calibrationData, setCalibrationData] = useState<Calibration>(calibration ?? { id: -1, name: '', value: '' });
	const actionText = calibration?.id ? 'Update' : 'Add';

	return (
		<FormDialog
			title={`${actionText} calibration`}
			buttonText={actionText}
			open={true}
			onClose={onClose}
			onSave={() => {
				onSave(calibrationData);
			}}
			maxWidth="xs"
		>
			<InputField
				label="Name"
				placeholder="Ticks to liter"
				value={calibrationData.name}
				onChange={(value) =>
					setCalibrationData((state) => ({
						...state,
						name: value,
					}))
				}
				sx={{ pb: 5, display: 'block' }}
				required
			/>
			<InputField
				label="Value"
				placeholder="$/400"
				value={calibrationData.value}
				onChange={(value) =>
					setCalibrationData((state) => ({
						...state,
						value: value,
					}))
				}
				sx={{ pb: 5, display: 'block' }}
				required
			/>
		</FormDialog>
	);
};

export default CalibrationDialog;
