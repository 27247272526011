import { CalenderScheduleEvent } from '../../../Types/types';

export function eventIsValid(event: CalenderScheduleEvent) {
	//TODO: remove any
	if (event.start.day === '' || (event.start.time as any) === '' || event.end.day === '' || (event.end.time as any) === '') {
		return false;
	}
	if (event.start.day === event.end.day && event.start.time === event.end.time) {
		return false;
	}
	const [startHour, startMinute] = event.start.time.split(':').map(Number);
	const [endHour, endMinute] = event.end.time.split(':').map(Number);
	if (startHour === endHour && startMinute === endMinute) {
		return false;
	}
	return true;
}
