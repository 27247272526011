import React, {FC} from 'react';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import PropTypes from 'prop-types';
import dayjs, {Dayjs} from 'dayjs';

interface DateSelectorProps {
	label: string;
	value: Dayjs;
	onChange: (date: Dayjs) => void;
	disableDayFunction?: (date: Dayjs) => boolean;
	dateFormat?: string;
	error?: boolean;
	[key: string]: any;
}

const DateSelector: FC<DateSelectorProps> = ({label, value, onChange, disableDayFunction, dateFormat, error, ...rest}) => {
	const handleChange = (date: Dayjs | null) => {
		if (!date) return;
		onChange(date);
	};

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<DatePicker
				disableFuture
				label={label}
				shouldDisableDate={(date) => {
					if (disableDayFunction) {
						return disableDayFunction(date);
					}
					return false;
				}}
				onChange={handleChange}
				value={dayjs(value)}
				format={dateFormat || 'DD/MM/YYYY'}
				slotProps={{
					textField: {
						size: 'small',
						inputProps: {
							sx: {
								fontSize: 'clamp(12px, 1.5vw, 14px)',
								paddingTop: '10px',
								paddingBottom: '10px',
							},
						},
					},
				}}
				{...rest}
			/>
		</LocalizationProvider>
	);
};

export default DateSelector;
