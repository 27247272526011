import { TimeStamp, weekDays } from '../../../Types/types';

const findElementFromTime = (event: TimeStamp) => {
	const elementId = `${event.day}-${event.time}`;
	const element = document.getElementById(elementId);
	return element ? element.getBoundingClientRect() : null;
};

const calculateEventPosition = (
	startPosition: DOMRect,
	endPosition: DOMRect,
	startElement: TimeStamp,
	endElement: TimeStamp,
	parentScrollTop: number,
	dragGrid: [number, number],
	isResizing?: { dir: string; top: number; left: number; width: number; height: number }
) => {
	const startDayIsFirst = weekDays.indexOf(startElement.day) <= weekDays.indexOf(endElement.day);

	const deltaX = startDayIsFirst ? endPosition.right - startPosition.left : endPosition.left - startPosition.right;

	const parentContainer = document.getElementById('calender-parent');
	if (!parentContainer) return { top: 0, left: 0, width: 0, height: 0 };
	const parentRect = parentContainer.getBoundingClientRect();

	const standardPos = {
		top: startDayIsFirst
			? Math.min(startPosition.bottom, endPosition.bottom) - parentRect.top + parentScrollTop
			: Math.min(startPosition.bottom, endPosition.bottom) - parentRect.top + parentScrollTop,
		left: Math.min(startPosition.left, endPosition.left) - parentRect.left + 10,
		width: Math.abs(deltaX) - 30,
		height: Math.abs(endPosition.bottom - startPosition.bottom),
	};

	// 0,0 is the top left corner of the parent container
	// dragGrid is the size of the grid we want to snap to [width, height]

	if (!isResizing) return standardPos;

	if (isResizing.dir === 'top' || isResizing.dir.includes('top')) {
		standardPos.top = Math.round((standardPos.top + isResizing.top) / dragGrid[1]) * dragGrid[1];
		standardPos.height = Math.round((standardPos.height - isResizing.top) / dragGrid[1]) * dragGrid[1];
	}

	if (isResizing.dir === 'left' || isResizing.dir.includes('left')) {
		standardPos.left = standardPos.left + isResizing.left;
		standardPos.width = standardPos.width - isResizing.left;
	}

	if (isResizing.dir === 'bottom' || isResizing.dir.includes('bottom')) {
		standardPos.height = Math.round((standardPos.height + isResizing.height) / dragGrid[1]) * dragGrid[1];
	}

	if (isResizing.dir === 'right' || isResizing.dir.includes('right')) {
		standardPos.width = standardPos.width + isResizing.width;
	}

	return standardPos;
};

const calculateTemptext = (startElement: TimeStamp, endElement: TimeStamp, tempText: { start: TimeStamp; end: TimeStamp } | { error: string } | null, elementHeigh?: number) => {
	if (!startElement || !endElement) return '1';

	if (tempText !== null && 'error' in tempText) return tempText.error;

	const timeA = tempText ? tempText.start : startElement;
	const timeB = tempText ? tempText.end : endElement;

	if (!timeA || !timeB) return '2';

	const isSameDay = timeA.day === timeB.day;

	const eventHeight = elementHeigh ? elementHeigh : 0;

	if (isSameDay || eventHeight < 80) {
		const smallestTime = timeA.time < timeB.time ? timeA.time : timeB.time;
		const biggestTime = timeA.time > timeB.time ? timeA.time : timeB.time;

		return `${smallestTime} - ${biggestTime}`;
	}

	const smallestDay = weekDays.indexOf(timeA.day) < weekDays.indexOf(timeB.day) ? timeA.day : timeB.day;
	const biggestDay = weekDays.indexOf(timeA.day) > weekDays.indexOf(timeB.day) ? timeA.day : timeB.day;

	const smallestTime = timeA.time < timeB.time ? timeA.time : timeB.time;
	const biggestTime = timeA.time > timeB.time ? timeA.time : timeB.time;

	return `${smallestDay} ${smallestTime} - ${biggestDay} ${biggestTime}`;
};

export { findElementFromTime, calculateEventPosition, calculateTemptext };
