import React, { FC, Fragment, useEffect, useState } from 'react';
import { Box, IconButton, InputLabel, TextField, InputAdornment } from '@mui/material';
import { RegularText, TextfieldSelect, InputField } from '@Iot-Bee/standard-web-library';
import { Setting, settingTypes, timeIntervals, logicalOperators, dynamicOperators, connectorOptions } from '../../../Types/types';
import type { ValueSetting, TimeSetting, DynamicSetting } from '../../../Types/types';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';

interface SettingCreatorProps {
	settings: Setting[];
	setSettings: React.Dispatch<React.SetStateAction<Setting[]>>;
}

const SettingCreator: FC<SettingCreatorProps> = ({ settings, setSettings }) => {
	// early final index is the first index of the setting that has a connection of 'finished'
	const [earlyFinalIndex, setEarlyFinalIndex] = useState<number>(settings.findIndex((setting) => setting.connection === 'finished')); // -1 means no early final

	useEffect(() => {
		const newEarlyFinalIndex = settings.findIndex((setting) => setting.connection === 'finished');
		setEarlyFinalIndex(newEarlyFinalIndex);
	}, [settings]);

	const isTimeSetting = (value: ValueSetting | TimeSetting | DynamicSetting): value is TimeSetting => {
		return typeof value === 'number';
	};

	const isValueSetting = (value: ValueSetting | TimeSetting | DynamicSetting): value is ValueSetting => {
		return typeof value === 'object' && 'operator' in value && 'value' in value;
	};

	const isDynamicSetting = (value: ValueSetting | TimeSetting | DynamicSetting): value is DynamicSetting => {
		return typeof value === 'object' && 'operator' in value && 'value' in value;
	};

	const handleOperatorChange = (index: number, value: string) => {
		const newSettings = [...settings];
		if (index === settings.length - 1 && value === 'or') {
			newSettings.push({
				type: 'time',
				value: 120,
				connection: 'finished',
			});
			newSettings[index].connection = value as 'or';
			setSettings(newSettings);
		} else if (index !== settings.length - 1 && value === 'finished') {
			newSettings[index].connection = value as 'finished';
			setSettings(newSettings);
		} else {
			newSettings[index].connection = value as 'or' | 'finished';
			setSettings(newSettings);
		}

		if (earlyFinalIndex !== -1) {
			// find first index of finished
			const newEarlyFinalIndex = newSettings.findIndex((setting) => setting.connection === 'finished');
			setEarlyFinalIndex(newEarlyFinalIndex);
		}
	};

	return (
		<Fragment>
			{settings.map((setting, index) => {
				const hasBeenFinished = earlyFinalIndex !== -1 && index > earlyFinalIndex;
				return (
					<Box key={index} sx={{ display: 'flex', alignContent: 'baseline', my: 2, opacity: hasBeenFinished ? 0.3 : 1 }}>
						<IconButton
							onClick={() => {
								setSettings((prev) => {
									const newSettings = [...prev];
									newSettings.splice(index, 1);
									return newSettings;
								});
							}}
							sx={{ mt: 6, mx: 0, pl: 0, opacity: index === 0 ? 0.6 : 1 }}
							disabled={index === 0}
						>
							<DeleteOutlineRoundedIcon sx={{ p: 0, m: 0 }} />
						</IconButton>

						<Box sx={{ mr: 2 }}>
							<InputLabel sx={{ transform: 'translate(0, -1.5px) scale(0.75)', fontSize: '17.5px', fontFamily: 'DM Sans', textTransform: 'capitalize' }}>Type</InputLabel>
							<TextfieldSelect
								value={setting.type}
								options={settingTypes}
								onChange={(e) =>
									setSettings((prev) => {
										const newSettings = [...prev];
										newSettings[index].type = e.target.value as (typeof settingTypes)[number];
										return newSettings;
									})
								}
								sx={{ width: '110px', m: 0 }}
								disabled={index === 0}
							/>
						</Box>
						<Box sx={{ mr: 2 }}>
							{setting.type === 'time' ? (
								<Fragment>
									<InputLabel sx={{ transform: 'translate(0, -1.5px) scale(0.75)', fontSize: '17.5px', fontFamily: 'DM Sans' }}>Seconds</InputLabel>
									<TextfieldSelect
										value={setting.value.toString()}
										options={timeIntervals.map((num) => num.toString())}
										onChange={(e) => {
											const value = parseInt(e.target.value);
											setSettings((prev) => {
												const newSettings = [...prev];
												newSettings[index].value = value;
												return newSettings;
											});
										}}
										sx={{ width: '120px', m: 0 }}
									/>
								</Fragment>
							) : setting.type === 'value' ? (
								<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
									<RegularText sx={{ mr: 2, p: 0, fontSize: '14px', mt: 6 }}>Sensor Reading</RegularText>
									<Box>
										<InputLabel sx={{ transform: 'translate(0, -1.5px) scale(0.75)', fontSize: '17.5px', fontFamily: 'DM Sans' }}>Operator</InputLabel>
										<TextfieldSelect
											value={isValueSetting(setting.value) ? setting.value.operator : '='}
											options={logicalOperators}
											onChange={(e) => {
												const value = e.target.value as (typeof logicalOperators)[number];
												setSettings((prev) => {
													const newSettings = [...prev];
													newSettings[index].value = { operator: value, value: '' };
													return newSettings;
												});
											}}
											sx={{ width: '60px', m: 0 }}
										/>
									</Box>
									<InputField
										label="Value"
										value={isValueSetting(setting.value) ? setting.value.value : ''}
										onChange={(value) => {
											setSettings((prev) => {
												const newSettings = [...prev];
												newSettings[index].value = { operator: isValueSetting(setting.value) ? setting.value.operator : '=', value: value };
												return newSettings;
											});
										}}
										sx={{ width: '80px' }}
									/>
								</Box>
							) : (
								<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
									<RegularText sx={{ mr: 2, p: 0, fontSize: '14px', mt: 6, width: '12ch' }}>Sensor Reading is</RegularText>
									<Box>
										<InputLabel sx={{ transform: 'translate(0, -1.5px) scale(0.75)', fontSize: '17.5px', fontFamily: 'DM Sans' }}>Value</InputLabel>
										<TextField
											sx={{
												width: '65px',
												mr: 2,
												'& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
													display: 'none',
												},
												'& input[type=number]': {
													MozAppearance: 'textfield',
													padding: '0.5rem 0px 0.5rem 6px',
												},
											}}
											value={`${isDynamicSetting(setting.value) ? setting.value.value : ''}`}
											type="number"
											size="small"
											onChange={(e) => {
												setSettings((prev) => {
													const newSettings = [...prev];
													newSettings[index].value = {
														operator: isDynamicSetting(setting.value) ? setting.value.operator : 'larger',
														value: e.target.value,
													};
													return newSettings;
												});
											}}
											InputProps={{
												endAdornment: (
													<InputAdornment position="end" sx={{ p: 0, pr: 1 }}>
														%
													</InputAdornment>
												),
												style: { WebkitAppearance: 'none', margin: 0, padding: 0, width: '100%' },
											}}
										/>
									</Box>
									<Box>
										<InputLabel sx={{ transform: 'translate(0, -1.5px) scale(0.75)', fontSize: '17.5px', fontFamily: 'DM Sans' }}>Operator</InputLabel>
										<TextfieldSelect
											value={isDynamicSetting(setting.value) ? setting.value.operator : 'larger'}
											options={dynamicOperators}
											onChange={(e) => {
												const operator = e.target.value as (typeof dynamicOperators)[number];
												setSettings((prev) => {
													const newSettings = [...prev];
													newSettings[index].value = { operator: operator, value: isDynamicSetting(setting.value) ? setting.value.value : '' };
													return newSettings;
												});
											}}
											sx={{ width: '100px', m: 0 }}
										/>
									</Box>
								</Box>
							)}
						</Box>

						<Box>
							<InputLabel sx={{ transform: 'translate(0, -1.5px) scale(0.75)', fontSize: '17.5px', fontFamily: 'DM Sans', textTransform: 'capitalize' }}>Connection</InputLabel>
							<TextfieldSelect value={setting.connection} options={connectorOptions} onChange={(e) => handleOperatorChange(index, e.target.value)} sx={{ width: '120px', m: 0 }} />
						</Box>
					</Box>
				);
			})}
		</Fragment>
	);
};

export default SettingCreator;
