import React from 'react';
import { Stack } from '@mui/material';
import DeviceStatusCard from './components/DeviceStatusCard';
import DeviceDataCard from './components/DeviceDataCard';
import { ChartContextProvider } from './components/ChartContext';

const DeviceOverview = () => {
	return (
		<Stack direction="column" spacing={5}>
			<ChartContextProvider>
				<DeviceStatusCard />
				<DeviceDataCard />
			</ChartContextProvider>
		</Stack>
	);
};

export default DeviceOverview;
