import React, {useEffect, useState} from 'react';
import {AgChartsReact} from 'ag-charts-react';
import {AgChartOptions} from 'ag-charts-community';
import {dayTheme, fills, insideOutsideFills} from '../themes';

interface BarChartProps {
	barchartData: BarchartObject[];
	groupBy: any;
}

export const BarChart = ({barchartData, groupBy}: BarChartProps) => {
	const createBarChartSeries = (barchartData: BarchartObject[]): BarChartSeriesObject[] => {
		if (barchartData.length === 0) {
			return [];
		}
		const seriesList: BarChartSeriesObject[] = [];
		const keys = Object.keys(barchartData[0]).filter((key) => key !== 'timeGroup');

		keys.forEach((key) => {
			seriesList.push({
				type: 'bar',
				xKey: 'timeGroup',
				yKey: key,
				yName: key === 'insideWh' ? 'Inside working hours' : key === 'outsideWh' ? 'Outside working hours' : key.charAt(0).toUpperCase() + key.slice(1),
				stacked: true,
			});
		});
		return seriesList;
	};

	/*const limitToMaxItems = (list, maxItems = 40) => {
		const itemCount = list.length;

		if (itemCount <= maxItems) {
			return list; // No need to reduce the list if it's already within the limit
		}

		// Determine how many items to keep excluding the first and last
		const middleItemsCount = maxItems - 2;
		const reductionFactor = Math.ceil((itemCount - 2) / middleItemsCount);

		const reducedList = list.filter((_, index) => {
			if (index === 0 || index === itemCount - 1) {
				return true; // Always include the first and last items
			}
			return (index - 1) % reductionFactor === 0; // Select items based on reduction factor
		});

		// Ensure that the list has exactly maxItems by adjusting for any potential off-by-one issues
		if (reducedList.length > maxItems) {
			reducedList.splice(1, reducedList.length - maxItems); // Adjust middle items to fit maxItems
		}

		return reducedList;
	};*/

	const myTheme = {
		baseTheme: dayTheme.baseTheme,
		palette: {
			fills: groupBy === 'Tags' ? fills : insideOutsideFills,
			strokes: groupBy === 'Tags' ? fills : insideOutsideFills,
		},
	};

	const [options, setOptions] = useState<AgChartOptions>({});

	useEffect(() => {
		setOptions({
			title: {
				text: `Power usage grouped by ${groupBy}`,
			},
			data: barchartData,
			series: createBarChartSeries(barchartData),
			background: {
				fill: dayTheme.backgroundColor,
			},
			theme: myTheme,
			axes: [
				{
					type: 'number',
					title: {text: 'kWh'},
					position: 'left',
					gridLine: {style: [{stroke: 'lightgray'}]},
				},
				{
					type: 'category',
					position: 'bottom',
					tick: {},
					label: {
						minSpacing: 1,
					},
				},
			],
		});
	}, [barchartData]);

	return <AgChartsReact options={options} />;
};
