import { CalenderScheduleEvent, TimeFormat, weekDays } from '../../../Types/types';
import { orderEventStartAndEnd } from './sortCalenderEvents';
import { utcTimestampToLocale } from './TimeConverter';

// the "newEvent" is the event that is being dragged
export function isEventValid(newEvent: CalenderScheduleEvent, existingEvents: CalenderScheduleEvent[]) {
	const copies = [...existingEvents];
	const newEventCopy = { ...newEvent };

	//order the events
	const orderedEvents = copies.map((event) => {
		const ordered = orderEventStartAndEnd(event.start, event.end, event.id);
		const localEvent = utcTimestampToLocale(ordered);
		return localEvent;
	});

	// this has not yet been converted to UTC, so we dont need to convert to local
	// it will be converted to UTC when it is added to the schedule
	const newOrderedEvent = orderEventStartAndEnd(newEventCopy.start, newEventCopy.end, newEventCopy.id);

	const {
		start: { day: startDay, time: startTime },
		end: { day: endDay, time: endTime },
	} = newOrderedEvent;

	const indexedStartDay = weekDays.indexOf(startDay);
	const indexedEndDays = weekDays.indexOf(endDay);

	// index 0 is the column with the times
	if (indexedStartDay === -1 || indexedEndDays === -1 || indexedStartDay === 0 || indexedEndDays === 0) {
		return false;
	}

	// check that the earliest time hour is after 0
	const [startHour, startMinute] = startTime.split(':').map(Number);
	const [endHour, endMinute] = endTime.split(':').map(Number);
	if (isNaN(startHour) || isNaN(endHour) || startHour < 0 || endHour < 0 || startHour > 24 || endHour > 24 || (startHour === 24 && startMinute > 0) || (endHour === 24 && endMinute > 0)) {
		return false;
	}

	for (const event of orderedEvents) {
		const {
			start: { day: existingStartDay, time: existingStartTime },
			end: { day: existingEndDay, time: existingEndTime },
		} = event;

		const indexedExistingStartDay = weekDays.indexOf(existingStartDay);
		const indexedExistingEndDays = weekDays.indexOf(existingEndDay);

		// skip the event if it is the same as the new event
		if (newEvent.id === event.id) {
			continue;
		}

		// four checks to see if the new event day is valid
		// check if the startDay is within the range of the existing event
		const startDayIsWithinRange = isDayWithinRange(indexedStartDay, indexedExistingStartDay, indexedExistingEndDays);
		// check if the endDay is within the range of the existing event
		const endDayIsWithinRange = isDayWithinRange(indexedEndDays, indexedExistingStartDay, indexedExistingEndDays);
		// check if the existingStartDay is within the range of the new event
		const existingStartDayIsWithinRange = isDayWithinRange(indexedExistingStartDay, indexedStartDay, indexedEndDays);
		// check if the existingEndDay is within the range of the new event
		const existingEndDayIsWithinRange = isDayWithinRange(indexedExistingEndDays, indexedStartDay, indexedEndDays);

		// if some of the checks are true, wee need to check the time
		if (startDayIsWithinRange || endDayIsWithinRange || existingStartDayIsWithinRange || existingEndDayIsWithinRange) {
			// some days overlap so we need to check if the time overlaps

			// four checks to see if the new event time is valid
			// check if the StartTime is within the range of the existing event
			const startTimeIsWithinRange = startDayIsWithinRange ? isTimeWithinRange(startTime, existingStartTime, existingEndTime) : false;
			// check if the EndTime is within the range of the existing event
			const endTimeIsWithinRange = endDayIsWithinRange ? isTimeWithinRange(endTime, existingStartTime, existingEndTime) : false;
			// check if the ExistingEndTime is within the range of the new event
			const existingEndTimeIsWithinRange = existingEndDayIsWithinRange ? isTimeWithinRange(existingStartTime, startTime, endTime) : false;
			// check if the ExistingStartTime is within the range of the new event
			const existingStartTimeIsWithinRange = existingStartDayIsWithinRange ? isTimeWithinRange(existingEndTime, startTime, endTime) : false;

			// if some of the checks are true, the event is invalid
			if (startTimeIsWithinRange || endTimeIsWithinRange || existingStartTimeIsWithinRange || existingEndTimeIsWithinRange) {
				return false;
			}
			return true;
		}
	}
	return true;
}

function isDayWithinRange(day: number, startDay: number, endDay: number) {
	// sort startDay and endDay
	if (startDay > endDay) {
		const temp = startDay;
		startDay = endDay;
		endDay = temp;
	}

	if (day >= startDay && day <= endDay) {
		return true;
	}
	return false;
}

function isTimeWithinRange(time: TimeFormat, startTime: TimeFormat, endTime: TimeFormat) {
	const [hour, minute] = time.split(':').map(Number);
	let [startHour, startMinute] = startTime.split(':').map(Number);
	let [endHour, endMinute] = endTime.split(':').map(Number);

	// sort startHour and endHour
	if (startHour > endHour) {
		const temp = startHour;
		const tempStartMinute = startMinute;
		startHour = endHour;
		startMinute = endMinute;
		endHour = temp;
		endMinute = tempStartMinute;
	}

	// sort startMinute and endMinute
	if (startHour === endHour && startMinute > endMinute) {
		const temp = startMinute;
		startMinute = endMinute;
		endMinute = temp;
	}

	// check if the hour is within the range
	if (hour >= startHour && hour <= endHour) {
		// if the hour is the same as the startHour, check if the minute is before the startMinute, so the total time is before the start time
		if (hour === startHour && minute < startMinute) {
			return false; // the time is before the start time - valid
		}
		// if the hour is the same as the endHour, check if the minute is after the endMinute, so the total time is after the end time
		if (hour === endHour && minute > endMinute) {
			return false; // the time is after the end time - valid
		}
		// if the hour is within the range, and the minute is within the range, then time is invalid
		return true;
	}
	// if the hour is not within the range, the time is valid
	return false;
}
