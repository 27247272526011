import React, { useState, useEffect, useMemo, useRef } from 'react';
import Stack from '@mui/material/Stack';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';

import { BrandDarkBlue, CardContainer, TextHeader, CardTitle, PrimaryButton, useSnackbar, IOTTableWrapper, DeleteDialog } from '@Iot-Bee/standard-web-library';

import DeviceDialog from './components/DeviceDialog';

import { cacheFetcher, cacheKeys } from '../../utils/fetch';
import { updateItemInArrayInLocalStorage, userDevicesLocalStorageUpdateCallback, updateDeviceTagsArrayInLocalStorage } from '../../utils/localstorageHandlers';
import { Device } from '../../Types/types';

export function DevicesPage() {
	const navigate = useNavigate();

	const [devices, setDevices] = useState<Device[]>([]);
	const [rowIdsSelected, setRowIdsSelected] = useState<string[]>([]);
	const [deviceToEdit, setDeviceToEdit] = useState<Device | null>(null);
	const [dialogOpen, setDialogOpen] = useState<boolean>(false);
	const [devicesIsLoading, setDevicesIsLoading] = useState<boolean>(true);
	const [tableOrdering, setTableOrdering] = useState<{ column: string; direction: 'asc' | 'desc' }>({ column: '', direction: 'asc' });

	const loaded = useRef(false);

	const {
		actions: { openSnackbar },
	} = useSnackbar();

	const columnHeaderClick = (column: string) => {
		const direction = tableOrdering.column === column && tableOrdering.direction === 'asc' ? 'desc' : 'asc';
		setTableOrdering({ column, direction });
		setDevices(
			[...devices].sort((a, b) => {
				if (a[column] < b[column]) {
					return direction === 'asc' ? -1 : 1;
				}
				if (a[column] > b[column]) {
					return direction === 'asc' ? 1 : -1;
				}
				return 0;
			})
		);
	};

	// id is used to fetch the data from the object that we use
	// title is the title of the column/the displayed text
	// width is the width of the column (not used)
	// onClick is the function that is called when the column is clicked
	// priority is the priority of the column, for mobile table, depending on screensize the amount of priorities are displayed
	const columns = [
		{
			id: 'deviceName',
			title: 'Device',
			width: 250,
			onClick: (device: Device) => {
				navigate(`/devices/${device.id}`);
			},
			priority: 1,
			isKey: true,
			columnHeaderClick,
		},
		{
			id: 'status',
			title: 'Status',
			width: 200,
			priority: 2,
			columnHeaderClick,
		},

		{
			id: 'dataUsage',
			title: 'Data Usage',
			width: 200,
			priority: 3,
			columnHeaderClick,
		},
		{
			id: 'id',
			title: 'ID',
			width: 150,
			priority: 4,
			columnHeaderClick,
		},
		{
			id: 'tags',
			title: 'Tags',
			width: 300,
			priority: 4,
		},
	];

	const fetchData = () => {
		return new Promise<void>((resolve, reject) => {
			cacheFetcher<Device[]>(process.env.REACT_APP_API_URL + `getids`, cacheKeys.userDevices, userDevicesLocalStorageUpdateCallback, true)
				.then((data) => {
					setDevices(data);
					loaded.current = true;
					resolve();
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	const handleDeviceUpdate = (deviceData: Device) => {
		const { deviceName, id, tags } = deviceData;
		cacheFetcher<string>(
			`${process.env.REACT_APP_API_URL}updatedevice`,
			cacheKeys.userDevices,
			(deviceName) => {
				if (deviceName.success) {
					updateItemInArrayInLocalStorage<Device>((d) => d.id === id, deviceData, cacheKeys.userDevices);
					updateDeviceTagsArrayInLocalStorage(cacheKeys.deviceTags, cacheKeys.userDevices);
				}
			},
			false,
			{
				method: 'PUT',
				headers: new Headers({
					// Your header content
					'Content-Type': 'application/json',
				}),
				body: JSON.stringify({
					id: id,
					name: deviceName,
					tags: tags,
				}),
			}
		)
			.then((res: string) => {
				const oldDevices = devices;
				const index = oldDevices.findIndex((d) => d.id === id);
				oldDevices[index].deviceName = deviceName;
				oldDevices[index].tags = tags;
				setDevices([...oldDevices]);
				setDialogOpen(false);
				openSnackbar('success', "Device's name updated successfully");
			})
			.catch((err) => {
				console.log(err);
				openSnackbar('error', "Couldn't update device's name");
			});
	};

	useEffect(() => {
		fetchData().then(() => {
			setDevicesIsLoading(false);
		});
	}, []);

	return (
		<CardContainer
			header={
				<Stack
					direction="row"
					justifyContent="space-between"
					alignContent="center"
					alignItems="center"
					sx={{
						bgcolor: rowIdsSelected.length > 0 ? BrandDarkBlue : '#ffffff',
						flexGrow: '1',
						height: '100%',
						padding: '0 20px',
						borderRadius: '6px',
					}}
				>
					{rowIdsSelected.length > 0 ? (
						<>
							<TextHeader sx={{ color: '#fff' }}>{rowIdsSelected.length} selected</TextHeader>
							<EditIcon sx={{ color: '#fff', cursor: 'pointer' }} />
						</>
					) : (
						<>
							<CardTitle>Devices</CardTitle>
						</>
					)}
				</Stack>
			}
		>
			<IOTTableWrapper
				columns={columns}
				data={devices}
				rowIdsSelected={rowIdsSelected}
				setRowIdsSelected={setRowIdsSelected}
				withCheckboxes
				onEdit={(device) => {
					setDeviceToEdit(device);
					setDialogOpen(true);
				}}
				isLoading={devicesIsLoading}
				tableOrdering={tableOrdering}
			/>
			{dialogOpen && (
				<DeviceDialog
					device={deviceToEdit}
					open={dialogOpen}
					onClose={() => {
						setDialogOpen(false);
					}}
					onSave={(e) => handleDeviceUpdate(e)}
				/>
			)}
		</CardContainer>
	);
}

export default DevicesPage;
