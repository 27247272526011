import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const AlertsIcon = ({ strokeColor, ...props }) => (
	<SvgIcon sx={{ width: 24, height: 24, fill: 'none' }} viewBox="0 0 24 24" fill="none" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12 17.8476C17.6392 17.8476 20.2481 17.1242 20.5 14.2205C20.5 11.3188 18.6812 11.5054 18.6812 7.94511C18.6812 5.16414 16.0452 2 12 2C7.95477 2 5.31885 5.16414 5.31885 7.94511C5.31885 11.5054 3.5 11.3188 3.5 14.2205C3.75295 17.1352 6.36177 17.8476 12 17.8476Z"
			stroke={strokeColor || 'white'}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path d="M14.3887 20.8574C13.0246 22.3721 10.8966 22.3901 9.51941 20.8574" stroke={strokeColor || 'white'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
	</SvgIcon>
);

export default AlertsIcon;
