import React, {useRef, useContext, useEffect, useMemo, useState} from 'react';
import Highcharts, {isNumber} from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import ExportData from 'highcharts/modules/export-data';
import ChartContainer from '../../../components/ChartContainer';
import {ChartContext} from './ChartContext';
import HighchartsReact from 'highcharts-react-official';
import BeatLoader from 'react-spinners/BeatLoader';
import {Box} from '@mui/material';
import {FadeLoader} from 'react-spinners';

import getDefaultGraphOptions from './defaultGraphSettings';
import {RegularText, GreenColor, useSnackbar} from '@Iot-Bee/standard-web-library';
import dayjs from 'dayjs';
import {DeviceData} from '../../../Types/types';
import {socket} from './socket';
import {Socket} from 'socket.io-client';

interface CsvRowData {
	sensor: string;
	time: string;
	value: number;
	'Tick change'?: number;
	'calibrated value': number;
}

const StaticData = () => {
	const container = useRef<HighchartsReact.RefObject>(null);
	const socketconnection = useRef<Socket | null>(null);
	const [downloading, setDownloading] = useState<{downloading: Boolean; statusMessage: string}>({downloading: false, statusMessage: ''});

	const {
		actions: {openSnackbar},
	} = useSnackbar();

	const {graphData, loadingGraphData, sensor, calibration, deviceData, historicData, deviceId, showDataPoints, isPowerDevice, fromDate, toDate} = useContext(ChartContext);

	useEffect(() => {
		if (container.current) {
			if (container.current.chart) {
				container.current.chart.zoomOut();
			}
		}
	}, [sensor]);

	useEffect(() => {
		if (!socketconnection.current) return;

		let dataBucket: CsvRowData[] = [];

		const socketConnection = getSocketConnection();
		socketConnection.on('sendData', (data: {success: boolean; message: CsvRowData[]; updateMessage: string}) => {
			const {success, message, updateMessage} = data;
			if (success) {
				if (updateMessage) {
					setDownloading({downloading: true, statusMessage: updateMessage});
				}
				dataBucket.push(...message);
			}
		});

		socketConnection.on('downloadFinished', (data: {success: boolean; message: CsvRowData[]; headers: string[]}) => {
			const {success, message, headers} = data;
			if (success) {
				dataBucket.push(...message);

				// sort the data by time, descending
				const sortedData = dataBucket.sort((a, b) => {
					const aDate = dayjs(a.time);
					const bDate = dayjs(b.time);
					return aDate.isAfter(bDate) ? -1 : 1;
				});

				const csvHeaders = headers.join(',') + '\n';

				// Join each row with commas and then join rows with newlines
				const csvContent = sortedData
					.map((row) => {
						const values = Object.values(row);
						return values.join(',');
					})
					.join('\n');
				const blob = new Blob([csvHeaders, ...csvContent], {type: 'text/csv;charset=utf-8;'});
				const objURL = window.URL.createObjectURL(blob);

				const link = document.createElement('a');
				link.setAttribute('href', objURL);
				link.setAttribute('download', `${deviceId}-${sensor}.csv`);
				link.style.visibility = 'hidden';
				document.body.appendChild(link);
				link.click();

				dataBucket = [];

				window.URL.revokeObjectURL(objURL);

				// Remove the link
				document.body.removeChild(link);
			}
			setDownloading({downloading: false, statusMessage: ''});
		});

		socketConnection.on('downloadUpdates', (data) => {
			const {success, message} = data;
			setDownloading({downloading: true, statusMessage: message});
		});

		socketConnection.on('disconnect', () => {
			setDownloading({downloading: false, statusMessage: ''});
		});

		socketConnection.on('error', (err) => {
			console.error(err);
		});

		return () => {
			socketConnection.off('downloadAllData');
			socketConnection.off('downloadFinished');
			socketConnection.off('downloadUpdates');
			socketConnection.off('disconnect');
			socketConnection.off('error');
			socketConnection.off('connect_error');
		};
	}, [socketconnection.current?.connected]);

	const getSocketConnection = () => {
		if (socketconnection.current) {
			return socketconnection.current;
		} else {
			socketconnection.current = socket.connect();
			return socketconnection.current;
		}
	};

	Highcharts.SVGRenderer.prototype.symbols.download = function (x: number, y: number, w: number, h: number) {
		var path = [
			// Arrow stem
			'M',
			x + w * 0.5,
			y,
			'L',
			x + w * 0.5,
			y + h * 0.7,
			// Arrow head
			'M',
			x + w * 0.3,
			y + h * 0.5,
			'L',
			x + w * 0.5,
			y + h * 0.7,
			'L',
			x + w * 0.7,
			y + h * 0.5,
			// Box
			'M',
			x,
			y + h * 0.9,
			'L',
			x,
			y + h,
			'L',
			x + w,
			y + h,
			'L',
			x + w,
			y + h * 0.9,
		];
		return path;
	};
	Exporting(Highcharts);
	ExportData(Highcharts);

	const neededMenuItems = [
		'viewFullscreen',
		'downloadPNG',
		'downloadPDF',
		{
			text: "Download graph's CSV",
			onclick: () => {
				let csvContent = '';
				const fullData = [...historicData, ...deviceData];
				const titleKeys = Object.keys(fullData[0]);

				let filtered = titleKeys.filter((key) => key !== 'accumulatedValue');

				const hasCalibration = calibration !== null;

				if (isPowerDevice) {
					filtered.push('Tick Change');
				}

				if (hasCalibration) {
					filtered.push(`calibrated value ( ${calibration.value} )`);
				}

				// allheaders.push('accumulated_value');

				const headers = filtered.join(',');

				csvContent += headers + '\r\n';

				const keys = [...filtered];

				const data: DeviceData[] = fullData;

				data.forEach((item: DeviceData, dataIndex: number) => {
					const itemDate = dayjs(new Date(item.time));
					if (itemDate.isAfter(fromDate) && itemDate.isBefore(toDate)) {
						if (item.sensor === sensor) {
							keys.forEach((key, keysIndex) => {
								if (key.startsWith('calibrated value')) {
									if (hasCalibration) {
										const yValueFinal = eval(calibration.value.replaceAll('$', item.value));
										csvContent += yValueFinal.toFixed(2);
									}
								} else if (key === 'Tick Change') {
									if (dataIndex === 0) {
										csvContent += 0;
									} else {
										csvContent += (+item.value - +data[dataIndex - 1].value).toFixed(2);
									}
								} else if (key === 'time') {
									csvContent += dayjs(new Date(item[key])).format('YYYY-MM-DD HH:mm:ss');
								} else {
									if (isNumber(item[key])) {
										csvContent += item[key].toFixed(2);
									} else {
										if (key === 'value') {
											csvContent += (+item[key]).toFixed(2);
										} else {
											csvContent += item[key];
										}
									}
								}
								if (keysIndex < keys.length - 1) {
									csvContent += ',';
								}
							});
							csvContent += '\r\n';
						}
					}
				});

				const blob = new Blob([csvContent], {type: 'text/csv;charset=utf-8;'});
				const objURL = window.URL.createObjectURL(blob);

				// const device = tableData.find((item) => item.id === deviceId);
				const device = {deviceName: deviceId};

				const link = document.createElement('a');
				link.setAttribute('href', objURL);
				link.setAttribute('download', `${device.deviceName}_${sensor}.csv`);
				link.style.visibility = 'hidden';
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			},
		},
		{
			text: 'Download all data CSV',
			onclick: () => {
				// connect to the socket
				if (!deviceId || !sensor) return;

				const accessToken = localStorage.getItem('accesstoken');
				const refreshToken = localStorage.getItem('refreshtoken');
				getSocketConnection().emit('downloadAllData', {imei: deviceId, sensor, accessToken, refreshToken});
				setDownloading({downloading: true, statusMessage: 'Download request sent'});
			},
		},
	];

	const options = useMemo(() => {
		return {
			...getDefaultGraphOptions(showDataPoints, graphData, sensor),
			lang: {
				downloadCSV: 'Download graph CSV',
				downloadXLS: 'Download graph XLS',
				downloadPNG: 'Download graph PNG',
				downloadPDF: 'Download graph PDF',
			},
			exporting: {
				enabled: true,
				buttons: {
					contextButton: {
						menuItems: neededMenuItems,
						align: 'right',
						x: 0,
						y: -26,
						symbol: 'download', //symbol: 'none', //"menu", "menuball" ,
						symbolFill: '#FFFFFF',
						// text: 'Export',
						textAlign: 'center',
						theme: {
							fill: GreenColor,
							padding: 6,
						},
					},
				},
			},
		};
	}, [graphData, showDataPoints, sensor]);

	return (
		<ChartContainer sx={{position: 'relative'}}>
			<HighchartsReact highcharts={Highcharts} options={options} ref={container} />
			<Box
				sx={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					color: 'white',
					fontSize: '1.5rem',
				}}>
				{loadingGraphData.loading ? (
					<BeatLoader color={GreenColor} size={18} speedMultiplier={0.6} />
				) : graphData.length === 0 && loadingGraphData.success ? (
					<RegularText sx={{color: GreenColor, fontSize: 'large'}}>No data found</RegularText>
				) : null}
			</Box>
			{downloading.downloading && (
				<Box
					sx={{
						position: 'fixed',
						bottom: '20px',
						right: '25px',
						background: 'rgba(255, 255, 255, 0.35)',
						boxShadow: '0 4px 30px rgba(0, 0, 0, 0.2)',
						backdropFilter: 'blur(6px)',
						padding: '8px',
						borderRadius: 4,
						minWidth: 200,
						textAlign: 'center',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}>
					<Box>
						<RegularText>{downloading.statusMessage}</RegularText>
						<RegularText sx={{fontSize: '11px', textDecoration: 'underline', opacity: 0.8}}>Do not refresh the page!</RegularText>
					</Box>
					<Box sx={{ml: 2}}>
						<FadeLoader color="black" height={10} />
					</Box>
				</Box>
			)}
		</ChartContainer>
	);
};

export default StaticData;
