import React, {FC, Fragment, useEffect, useState} from 'react';
import Calender from './Calender';

import {FormDialog, InputField, RegularText, OutlinedButton} from '@Iot-Bee/standard-web-library';

import {eventIsValid} from '../Utils/eventIsValid';
import {Box} from '@mui/material';
import AutoComplete from '../../../components/form/AutoComplete';
import MoveDeviceDialog from './MoveDeviceDialog';
import {CalenderSchedule, CalenderScheduleEvent, DeviceNameAndUsed, EditSchedule, NewCalenderScheduleEvent} from '../../../Types/types';
import {timeStampToUtc} from '../Utils/TimeConverter';

interface SchedulingDialogProps {
	open: boolean;
	schedule: CalenderSchedule;
	onClose: () => void;
	onSave: (schedule: EditSchedule) => void;
	allClientDevices: DeviceNameAndUsed[];
}

const SchedulingDialog: FC<SchedulingDialogProps> = ({open, schedule, onClose, onSave, allClientDevices}) => {
	const [edit, setEdit] = useState<{isActive: boolean; isDirty: boolean}>({isActive: true, isDirty: false}); // isDirty === changes made, could also check current info against original for more precision
	const [originalSchedule, setOriginalSchedule] = useState<CalenderSchedule>({} as CalenderSchedule);
	const [scheduleEvents, setScheduleEvents] = useState<CalenderScheduleEvent[]>([]);
	const [scheduleDevices, setScheduleDevices] = useState<DeviceNameAndUsed[]>([]);
	const [scheduleName, setScheduleName] = useState<string>('');
	const [showResetButton, setShowResetButton] = useState<boolean>(false);
	const [temperatures, setTemperatures] = useState<{on: number | undefined; off: number | undefined}>({on: undefined, off: undefined});

	const [moveDeviceDialogInfo, setMoveDeviceDialogInfo] = useState<{
		isOpen: boolean;
		device: string;
		onItem: string;
	}>({isOpen: false, device: '', onItem: ''});

	const isNewSchedule = Object.keys(schedule).length <= 1 && 'id' in schedule; // if the schedule is new, it only has an id

	const resetMoveDeviceDialogInfo = () => {
		setMoveDeviceDialogInfo((prev) => {
			return {...prev, isOpen: false, device: '', onItem: ''};
		});
	};

	useEffect(() => {
		if (!isNewSchedule) {
			setScheduleDevices(
				schedule.devices.map((device) => {
					return {name: device, isUsed: ''};
				})
			);
			setScheduleEvents(schedule.events);
			setScheduleName(schedule.name);
			setOriginalSchedule(schedule);
			setEdit({isActive: false, isDirty: false});
			setShowResetButton(true);
		} else {
			setScheduleDevices([]);
			setScheduleEvents([]);
			setScheduleName('');
			setEdit({isActive: true, isDirty: false});
			setOriginalSchedule({id: schedule.id} as CalenderSchedule);
			resetMoveDeviceDialogInfo();
			setShowResetButton(false);
		}
	}, [schedule]);

	const handleAddEvent = (event: NewCalenderScheduleEvent) => {
		let id = 1;
		if (scheduleEvents.length > 0) {
			//find largest id and add 1
			id =
				Math.max(
					...scheduleEvents.map((e) => {
						return e.id;
					})
				) + 1;
		}
		// convert to utc, when drawn it will be converted back to local time
		const utcEvent = timeStampToUtc({...event, id});
		const newEvent = {...utcEvent};
		if (eventIsValid(newEvent)) {
			setScheduleEvents([...scheduleEvents, newEvent]);
			setEdit((prev) => {
				return {...prev, isDirty: true};
			});
		}
	};

	const handleDeleteEvent = (eventId) => {
		setScheduleEvents((prev) => prev.filter((e) => e.id !== eventId));
		setEdit((prev) => {
			return {...prev, isDirty: true};
		});
	};

	const handleUpdateEvent = (originalEvent: CalenderScheduleEvent, updatedEvent: NewCalenderScheduleEvent) => {
		// convert to utc, when drawn it will be converted back to local time
		const utcEvent = timeStampToUtc({...updatedEvent, id: originalEvent.id});
		setScheduleEvents((prev) =>
			prev.map((e) =>
				e.id === originalEvent.id
					? {
							...e,
							...utcEvent,
					  }
					: e
			)
		);
		setEdit((prev) => {
			return {...prev, isDirty: true};
		});
	};

	const handleReset = () => {
		setScheduleDevices(
			originalSchedule.devices.map((device) => {
				return {name: device, isUsed: ''};
			})
		);
		setScheduleEvents(originalSchedule.events);
		setScheduleName(originalSchedule.name);
		setEdit((prev) => {
			return {...prev, isDirty: false};
		});
	};

	const handleUpdateScheduleDevices = (device: DeviceNameAndUsed) => {
		if (device.isUsed === '' || device.isUsed === originalSchedule.name) {
			// this checks whether to add or remove the device from the schedule
			const exists = scheduleDevices.find((d: DeviceNameAndUsed) => d.name === device.name);
			if (exists) {
				if ('selectAll' in device) {
					setScheduleDevices([]);
				} else {
					setScheduleDevices((prev) => prev.filter((d) => d.name !== device.name));
				}
			} else {
				if ('selectAll' in device) {
					setScheduleDevices(() => [...allClientDevices.filter((d) => d.isUsed === '')]); // all devices that are not used
				} else {
					setScheduleDevices((prev) => [...prev, device]);
				}
			}
			setEdit((prev) => {
				return {...prev, isDirty: true};
			});
		} else {
			// the device is being used on another schedule
			setMoveDeviceDialogInfo((prev) => {
				return {...prev, isOpen: true, device: device.name, onItem: device.isUsed};
			});
		}
	};

	return (
		<FormDialog
			title={isNewSchedule ? 'New Schedule' : `Schedule ${originalSchedule.name}`}
			buttonText={isNewSchedule ? 'Create schedule' : edit.isActive ? 'Save Changes' : `Edit`}
			open={open}
			onClose={onClose}
			onSave={
				edit.isActive
					? () => {
							console.log('scheduleDevices', scheduleDevices);
							let allDevices = scheduleDevices.map((device) => device.name).join(', ');
							if (allDevices.length > 50) {
								allDevices = allDevices.substring(0, 50).concat('...');
							}

							if (temperatures.on === undefined || temperatures.off === undefined) {
								return;
							}

							onSave({
								id: originalSchedule.id,
								name: scheduleName,
								events: scheduleEvents,
								devices: scheduleDevices,
								allDevices: allDevices,
								temperatures: {on: temperatures.on, off: temperatures.off},
							});
					  }
					: () => {
							setEdit({isActive: true, isDirty: false});
					  }
			}
			maxWidth="md">
			<Box sx={{height: '70vh', maxHeight: '800px'}}>
				<Box sx={{display: 'flex', flexFlow: 'row wrap', alignItems: 'baseline', mb: 6}}>
					{edit.isActive ? (
						<Fragment>
							<InputField
								label="Name"
								value={scheduleName}
								onChange={(value) => {
									setScheduleName(value);
								}}
								required
								sx={{maxWidth: '250px', mr: 4}}
							/>
							<AutoComplete
								label="Devices"
								options={allClientDevices}
								sx={{mr: 4, maxWidth: '40%'}}
								value={scheduleDevices}
								onChange={handleUpdateScheduleDevices}
								itemName={originalSchedule.name}
							/>
							<InputField
								label="On Temperature"
								value={temperatures.on ? temperatures.on.toString() : ''}
								onChange={(value) => {
									setTemperatures((prev) => {
										return {...prev, on: +value};
									});
								}}
								sx={{width: '130px', mr: 2, ml: 2}}
								tooltipText={<RegularText sx={{color: 'black', fontSize: 'small'}}>The temperature the device is trying to stay at when on.</RegularText>}
								placeholder="23"
								required
							/>
							<InputField
								label="Off Temperature"
								value={temperatures.off ? temperatures.off.toString() : ''}
								onChange={(value) => {
									setTemperatures((prev) => {
										return {...prev, off: +value};
									});
								}}
								sx={{width: '130px'}}
								tooltipText={
									<RegularText sx={{color: 'black', fontSize: 'small'}}>
										If the devices is off and the temperature goes below this, the device will start and get the temperature up.
									</RegularText>
								}
								placeholder="16"
								required
							/>
							{showResetButton && (
								<OutlinedButton disabled={!edit.isDirty} onClick={handleReset}>
									Reset changes
								</OutlinedButton>
							)}
						</Fragment>
					) : (
						<Fragment>
							<RegularText sx={{mr: 1}}>Applied on devices:</RegularText>
							<RegularText>{scheduleDevices.length > 0 ? scheduleDevices.map((device) => device.name).join(', ') : 'None'}</RegularText>
						</Fragment>
					)}
				</Box>
				<Calender events={scheduleEvents} addEvent={handleAddEvent} deleteEvent={handleDeleteEvent} updateEvent={handleUpdateEvent} editable={edit.isActive} />
			</Box>
			<MoveDeviceDialog
				moveDeviceDialogInfo={moveDeviceDialogInfo}
				resetMoveDeviceDialogInfo={resetMoveDeviceDialogInfo}
				handleMoveDevice={(device) => {
					setScheduleDevices((prev) => [...prev, device]);
				}}
				itemName={scheduleName}
			/>
		</FormDialog>
	);
};

export default SchedulingDialog;
