import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const CalibrationIcon = ({ strokeColor, ...props }) => (
	<SvgIcon sx={{ width: 24, height: 24, fill: 'none' }} viewBox="0 0 24 24" fill="none" {...props}>
		<path d="M10.3303 16.593H4.02954" stroke={strokeColor || 'white'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M13.1404 6.90018H19.4411" stroke={strokeColor || 'white'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M8.72629 6.84625C8.72629 5.5506 7.66813 4.5 6.36314 4.5C5.05816 4.5 4 5.5506 4 6.84625C4 8.14191 5.05816 9.19251 6.36314 9.19251C7.66813 9.19251 8.72629 8.14191 8.72629 6.84625Z"
			stroke={strokeColor || 'white'}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M19.9997 16.5538C19.9997 15.2581 18.9424 14.2075 17.6374 14.2075C16.3316 14.2075 15.2734 15.2581 15.2734 16.5538C15.2734 17.8494 16.3316 18.9 17.6374 18.9C18.9424 18.9 19.9997 17.8494 19.9997 16.5538Z"
			stroke={strokeColor || 'white'}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</SvgIcon>
);

export default CalibrationIcon;
