import React, { FC, useEffect, useState } from 'react';
import { FormDialog, RegularText, InputField } from '@Iot-Bee/standard-web-library';
import { DeviceSetting, UpdateDeviceSetting } from '../../../Types/types';
import { Box } from '@mui/material';
import SettingCreator from './SettingCreator';
import AutoComplete from '../../../components/form/AutoComplete';
import { DeviceNameAndUsed } from '../../../Types/types';
import MoveDeviceDialog from '../../Scheduling/components/MoveDeviceDialog';

interface DeviceSettingDialogProps {
	open: boolean;
	onClose: () => void;
	onSave: (DeviceSetting: UpdateDeviceSetting) => void;
	mode: 'add' | 'edit';
	deviceSettingToEdit: DeviceSetting | null;
	availableDevices: DeviceNameAndUsed[];
}

const DeviceSettingDialog: FC<DeviceSettingDialogProps> = ({ open, onClose, onSave, mode, availableDevices, deviceSettingToEdit }) => {
	// Connections can only be "or" for now
	const [transmissionSettings, setTransmissionSettings] = useState<DeviceSetting['transmissionSettings']>([
		{
			type: 'time',
			value: 120,
			connection: 'finished',
		},
	]);
	const [collectionSettings, setCollectionSettings] = useState<DeviceSetting['collectionSettings']>([
		{
			type: 'time',
			value: 120,
			connection: 'finished',
		},
	]);
	const [settingName, setSettingName] = useState<string>('');
	const [settingDevices, setSettingDevices] = useState<DeviceNameAndUsed[]>([]);
	const [originalSetting, setOriginalSetting] = useState<DeviceSetting | null>(null);
	const [edit, setEdit] = useState<{ isActive: boolean; isDirty: boolean }>({ isActive: true, isDirty: false }); // isDirty === changes made, could also check current info against original for more precision

	const [moveDeviceDialogInfo, setMoveDeviceDialogInfo] = useState<{
		isOpen: boolean;
		device: string;
		onItem: string;
	}>({ isOpen: false, device: '', onItem: '' });

	useEffect(() => {
		if (deviceSettingToEdit) {
			setSettingName(deviceSettingToEdit?.name || '');
			setSettingDevices(
				availableDevices.filter((device) => {
					return deviceSettingToEdit?.devices.includes(device.name);
				})
			);
			setTransmissionSettings(
				deviceSettingToEdit.transmissionSettings || [
					{
						type: 'time',
						value: 120,
						connection: 'finished',
					},
				]
			);
			setCollectionSettings(
				deviceSettingToEdit.collectionSettings || [
					{
						type: 'time',
						value: 120,
						connection: 'finished',
					},
				]
			);
			setOriginalSetting(deviceSettingToEdit);
			setEdit({ isActive: false, isDirty: false });
		} else {
			setSettingName('');
			setSettingDevices([]);
			setTransmissionSettings([
				{
					type: 'time',
					value: 120,
					connection: 'finished',
				},
			]);
			setCollectionSettings([
				{
					type: 'time',
					value: 120,
					connection: 'finished',
				},
			]);
			setOriginalSetting(null);
			setEdit({ isActive: true, isDirty: false });
		}
	}, [deviceSettingToEdit]);

	const handleUpdateSettingDevices = (device: DeviceNameAndUsed) => {
		// it is a new device
		if (!originalSetting) {
			if (device.isUsed !== '') {
				setMoveDeviceDialogInfo((prev) => {
					return { ...prev, isOpen: true, device: device.name, onItem: device.isUsed };
				});
				return;
			}

			const exists = settingDevices.find((d) => d.name === device.name);
			if (exists) {
				if ('selectAll' in device) {
					setSettingDevices([]);
				} else {
					setSettingDevices((prev) => prev.filter((d) => d.name !== device.name));
				}
			} else {
				if ('selectAll' in device) {
					setSettingDevices([...availableDevices.filter((d) => d.isUsed === '')]); // all devices that are not used
				} else {
					setSettingDevices((prev) => [...prev, device]);
				}
			}
			setEdit((prev) => {
				return { ...prev, isDirty: true };
			});
		} else if (device.isUsed === '' || device.isUsed === originalSetting.name) {
			// this checks whether to add or remove the device from the setting
			const exists = originalSetting.devices.find((d: string) => d === device.name);
			if (exists) {
				if ('selectAll' in device) {
					setSettingDevices([]);
				} else {
					setSettingDevices((prev) => prev.filter((d) => d.name !== device.name));
				}
			} else {
				if ('selectAll' in device) {
					setSettingDevices([...availableDevices.filter((d) => d.isUsed === '')]); // all devices that are not used
				} else {
					setSettingDevices((prev) => [...prev, device]);
				}
			}
			setEdit((prev) => {
				return { ...prev, isDirty: true };
			});
		} else {
			// the device is being used on another schedule
			setMoveDeviceDialogInfo((prev) => {
				return { ...prev, isOpen: true, device: device.name, onItem: device.isUsed };
			});
		}
	};

	const resetMoveDeviceDialogInfo = () => {
		setMoveDeviceDialogInfo((prev) => {
			return { ...prev, isOpen: false, device: '', onItem: '' };
		});
	};

	const internalOnSave = () => {
		let allDevices = settingDevices.map((d) => d.name).join(', ');
		if (allDevices.length > 50) {
			allDevices = allDevices.slice(0, 50);
			allDevices += '...';
		}
		onSave({
			id: originalSetting?.id || 0,
			devices: settingDevices,
			name: settingName,
			transmissionSettings,
			collectionSettings,
			allDevices,
		});
	};

	return (
		<FormDialog open={open} onClose={onClose} onSave={internalOnSave} title="Device Setting" buttonText={mode === 'add' ? 'Add setting' : 'Edit setting'}>
			<Box sx={{ display: 'flex', alignItems: 'baseline' }}>
				<InputField label="Setting Name" value={settingName} onChange={(e) => setSettingName(e)} placeholder="General setting" sx={{ mb: 6, mr: 4 }} required />
				<AutoComplete label="Devices" value={settingDevices} onChange={handleUpdateSettingDevices} options={availableDevices} sx={{ mb: 6 }} itemName={settingName} />
			</Box>
			<Box sx={{ mt: 2 }}>
				<Box>
					<RegularText sx={{ fontSize: '15px' }}>When should transmission happen?</RegularText>
					<SettingCreator settings={transmissionSettings} setSettings={setTransmissionSettings} />
				</Box>

				<Box sx={{ mt: 8 }}>
					<RegularText sx={{ fontSize: '15px' }}>When should collection happen?</RegularText>
					<SettingCreator settings={collectionSettings} setSettings={setCollectionSettings} />
				</Box>
			</Box>
			<MoveDeviceDialog
				moveDeviceDialogInfo={moveDeviceDialogInfo}
				resetMoveDeviceDialogInfo={resetMoveDeviceDialogInfo}
				handleMoveDevice={(device) => {
					setSettingDevices((prev) => [...prev, device]);
				}}
				itemName={settingName}
			/>
		</FormDialog>
	);
};

export default DeviceSettingDialog;
