import React, { useEffect, useState } from 'react';
import { IOTTableWrapper, Card, DeleteDialog, PrimaryButton } from '@Iot-Bee/standard-web-library';
import { cacheFetcher, cacheKeys } from '../../utils/fetch';
import { appendToArrayInLocalStorage, deleteItemFromArrayInLocalStorage, deviceSettingsLocalStorageUpdateCallback, userDevicesLocalStorageUpdateCallback } from '../../utils/localstorageHandlers';
import DeviceSettingDialog from './components/DeviceSettingDialog';
import { Device, DeviceNameAndUsed, UpdateDeviceSetting } from '../../Types/types';
import { DeviceSetting } from '../../Types/types';
import { LocalstorageData } from '../../Types/LocalStorageData';
import { moveDevicesSetting } from './util/moveDeviceSetting';

const DeviceSettings = () => {
	const [deviceSettings, setDeviceSettings] = useState<DeviceSetting[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [dialogInfo, setDialogInfo] = useState<{ open: boolean; mode: 'add' | 'edit'; deviceSettingToEdit: DeviceSetting | null }>({ open: false, mode: 'add', deviceSettingToEdit: null });
	const [deleteDialog, setDeleteDialog] = useState<{ open: boolean; deviceSetting: DeviceSetting | null }>({ open: false, deviceSetting: null });
	const [userDevices, setUserDevices] = useState<DeviceNameAndUsed[]>([]);

	useEffect(() => {
		cacheFetcher<DeviceSetting[]>(`${process.env.REACT_APP_API_URL}deviceSettings`, cacheKeys.settings, deviceSettingsLocalStorageUpdateCallback, true)
			.then((deviceSettings) => {
				// user devices are most likely fetched from cache
				cacheFetcher<Device[]>(`${process.env.REACT_APP_API_URL}getids`, cacheKeys.userDevices, userDevicesLocalStorageUpdateCallback, true)
					.then((data) => {
						setUserDevices([
							{ name: 'Select all devices', isUsed: '', selectAll: true },
							...data.map((device) => {
								const isUsed = deviceSettings.find((deviceSetting) => deviceSetting.devices.includes(device.deviceName));
								return { name: device.deviceName, isUsed: isUsed ? isUsed.name : '' };
							}),
						]);
						setDeviceSettings(deviceSettings);
					})
					.catch((e) => {
						console.log('Error fetching user devices', e);
					});
			})
			.catch((e) => {
				console.log('Error fetching device settings', e);
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, []);

	const handleAddSetting = (deviceSetting: UpdateDeviceSetting) => {
		const stringedDevices = deviceSetting.devices.map((device) => device.name);

		const body = {
			name: deviceSetting.name,
			devices: stringedDevices,
			transmissionSettings: deviceSetting.transmissionSettings,
			collectionSettings: deviceSetting.collectionSettings,
		};
		cacheFetcher<DeviceSetting>(
			`${process.env.REACT_APP_API_URL}addDeviceSetting`,
			cacheKeys.settings,
			(addRes, cacheKey) => {
				if (addRes.success) {
					let allDevices = stringedDevices.join(', ');
					if (allDevices.length > 50) {
						allDevices = allDevices.substring(0, 50) + '...';
					}

					const oldLocalStorage: LocalstorageData<DeviceSetting[]> = JSON.parse(localStorage.getItem(cacheKey) || '[]');
					const oldData = oldLocalStorage.data;
					const devices = stringedDevices;
					const newDeviceSetting: DeviceSetting = {
						id: addRes.message.id,
						name: deviceSetting.name,
						devices: devices,
						transmissionSettings: deviceSetting.transmissionSettings,
						collectionSettings: deviceSetting.collectionSettings,
						allDevices,
					};

					const moved = moveDevicesSetting(deviceSetting.devices, [...oldData, newDeviceSetting]);

					const newLocalStorage: LocalstorageData<DeviceSetting[]> = {
						createdDate: new Date(),
						data: moved,
					};
					localStorage.setItem(cacheKey, JSON.stringify(newLocalStorage));
				}
			},
			false,
			{
				method: 'POST',
				body: JSON.stringify(body),
			}
		)
			.then((data) => {
				let allDevices = stringedDevices.join(', ');
				if (allDevices.length > 50) {
					allDevices = allDevices.substring(0, 50) + '...';
				}
				const newDeviceSetting: DeviceSetting = {
					id: data.id,
					name: deviceSetting.name,
					devices: stringedDevices,
					transmissionSettings: deviceSetting.transmissionSettings,
					collectionSettings: deviceSetting.collectionSettings,
					allDevices,
				};

				setDeviceSettings((prev) => {
					const moved = moveDevicesSetting(deviceSetting.devices, [...prev, newDeviceSetting]);
					return moved;
				});
				setDialogInfo((prev) => ({ open: false, mode: prev.mode, deviceSettingToEdit: null }));
				setUserDevices((prev) => {
					return prev.map((device) => {
						if (stringedDevices.includes(device.name)) {
							return { ...device, isUsed: deviceSetting.name };
						}
						return device;
					});
				});
			})
			.catch((e) => {
				console.log('Error adding setting', e);
			});
	};

	const handleDelete = (id: number) => {
		const deviceSetting = deviceSettings.find((deviceSetting) => deviceSetting.id === id);
		if (!deviceSetting) {
			return;
		}
		cacheFetcher(
			`${process.env.REACT_APP_API_URL}device-settings/${id}`,
			cacheKeys.settings,
			(deleteRes) => {
				if (deleteRes.success) {
					deleteItemFromArrayInLocalStorage<DeviceSetting>((item) => item.id === id, cacheKeys.settings);
				}
			},
			false,
			{ method: 'DELETE' }
		)
			.then(() => {
				setDeviceSettings((prev) => prev.filter((deviceSetting) => deviceSetting.id !== id));
				setUserDevices((prev) => {
					return prev.map((device) => {
						if (deviceSetting.devices.includes(device.name)) {
							return { ...device, isUsed: deviceSetting.name };
						}
						return device;
					});
				});
			})
			.catch((e) => {
				console.log('Error deleting device setting', e);
			});
	};

	const handleUpdate = (deviceSetting: UpdateDeviceSetting) => {
		const stringedDevices = deviceSetting.devices.map((device) => device.name);

		const body = {
			name: deviceSetting.name,
			devices: stringedDevices,
			transmissionSettings: deviceSetting.transmissionSettings,
			collectionSettings: deviceSetting.collectionSettings,
		};

		cacheFetcher<DeviceSetting>(
			`${process.env.REACT_APP_API_URL}device-settings/${deviceSetting.id}`,
			cacheKeys.settings,
			(updateRes, cacheKey) => {
				if (updateRes.success) {
					let allDevices = stringedDevices.join(', ');
					if (allDevices.length > 50) {
						allDevices = allDevices.substring(0, 50) + '...';
					}

					const oldLocalStorage: LocalstorageData<DeviceSetting[]> = JSON.parse(localStorage.getItem(cacheKey) || '[]');
					let oldData = oldLocalStorage.data;
					oldData = oldData.filter((deviceSetting) => deviceSetting.id !== updateRes.message.id);
					const devices = stringedDevices;
					const newDeviceSetting: DeviceSetting = {
						id: updateRes.message.id,
						name: deviceSetting.name,
						devices: stringedDevices,
						transmissionSettings: deviceSetting.transmissionSettings,
						collectionSettings: deviceSetting.collectionSettings,
						allDevices,
					};

					const moved = moveDevicesSetting(deviceSetting.devices, [...oldData, newDeviceSetting]);

					const newLocalStorage: LocalstorageData<DeviceSetting[]> = {
						createdDate: new Date(),
						data: moved,
					};

					localStorage.setItem(cacheKey, JSON.stringify(newLocalStorage));
				}
			},
			false,
			{
				method: 'PUT',
				body: JSON.stringify(body),
			}
		).then((data) => {
			let allDevices = stringedDevices.join(', ');
			if (allDevices.length > 50) {
				allDevices = allDevices.substring(0, 50) + '...';
			}
			const newDeviceSetting: DeviceSetting = {
				id: data.id,
				name: deviceSetting.name,
				devices: stringedDevices,
				transmissionSettings: deviceSetting.transmissionSettings,
				collectionSettings: deviceSetting.collectionSettings,
				allDevices,
			};

			setDeviceSettings((prev) => {
				const moved = moveDevicesSetting(deviceSetting.devices, [...prev.filter((deviceSetting) => deviceSetting.id !== data.id), newDeviceSetting]);
				return moved;
			});
			setDialogInfo((prev) => ({ open: false, mode: prev.mode, deviceSettingToEdit: null }));
			setUserDevices((prev) => {
				return prev.map((device) => {
					if (stringedDevices.includes(device.name)) {
						return { ...device, isUsed: deviceSetting.name };
					}
					return device;
				});
			});
		});
	};

	const columns = [
		{
			id: 'name',
			title: 'Name',
			width: 250,
			priority: 1,
			isKey: true,
		},
		{
			id: 'allDevices',
			title: 'Devices',
			width: 200,
			priority: 3,
		},
	];

	return (
		<Card title="Device Settings" headerActions={<PrimaryButton onClick={() => setDialogInfo({ open: true, mode: 'add', deviceSettingToEdit: null })}>Add new Setting</PrimaryButton>}>
			<IOTTableWrapper
				columns={columns}
				data={deviceSettings}
				onEdit={(deviceSetting) => {
					setDialogInfo({ open: true, mode: 'edit', deviceSettingToEdit: deviceSetting });
				}}
				onDelete={(itemToDelete) => {
					setDeleteDialog({ open: true, deviceSetting: itemToDelete });
				}}
				isLoading={isLoading}
			/>
			<DeleteDialog
				title={deleteDialog.deviceSetting?.name || ''}
				open={deleteDialog.open}
				handleDelete={() => {
					handleDelete(deleteDialog.deviceSetting?.id || -1);
					setDeleteDialog((prev) => ({ ...prev, open: false }));
				}}
				onClose={() => setDeleteDialog((prev) => ({ ...prev, open: false }))}
				itemName={deleteDialog.deviceSetting?.name || ''}
			/>
			<DeviceSettingDialog
				open={dialogInfo.open}
				onClose={() => setDialogInfo((prev) => ({ ...prev, open: false }))}
				onSave={dialogInfo.mode === 'add' ? handleAddSetting : handleUpdate}
				mode={dialogInfo.mode}
				deviceSettingToEdit={dialogInfo.deviceSettingToEdit}
				availableDevices={userDevices}
			/>
		</Card>
	);
};

export default DeviceSettings;
