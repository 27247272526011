import React, {useState, useEffect, useMemo} from 'react';

import {Card, PrimaryButton, IOTTableWrapper, useSnackbar, DeleteDialog} from '@Iot-Bee/standard-web-library';

import AlertDialog from './components/AlertDialog';
import {cacheFetcher, cacheKeys} from '../../utils/fetch';
import {
	appendToArrayInLocalStorage,
	deleteItemFromArrayInLocalStorage,
	localStorageUpdateCallback,
	updateItemInArrayInLocalStorage,
	userDevicesLocalStorageUpdateCallback,
} from '../../utils/localstorageHandlers';
import {Alert, Device, ServerAlert} from '../../Types/types';

export function AlertsPage() {
	const [alerts, setAlerts] = useState<Alert[]>([]);
	const [clientIdDevices, setClientIdDevices] = useState<Device[]>([]);

	const [dialogInfo, setDialogInfo] = useState<{
		open: boolean;
		mode: 'add' | 'edit';
		alertToEdit: Alert | null;
	}>({
		open: false,
		mode: 'add',
		alertToEdit: null,
	});
	const [conditionError, setConditionError] = useState<string>('');
	const [deleteDialog, setDeleteDialog] = useState<{
		open: boolean;
		alert: Alert;
	}>({open: false, alert: {} as Alert});

	const [alertsAreLoading, setAlertsAreLoading] = useState<boolean>(true);

	const {
		actions: {openSnackbar},
	} = useSnackbar();

	// id is used to fetch the data from the object that we use
	// title is the title of the column/the displayed text
	// width is the width of the column (not used)
	// onClick is the function that is called when the column is clicked
	// priority is the priority of the column, for mobile table, depending on screensize the amount of priorities are displayed
	const arrayJoinRenderer = (value: any): React.ReactNode => {
		if (Array.isArray(value)) {
			return value.join(', ');
		}
		return value;
	};

	const columns = useMemo(
		() => [
			{
				id: 'name',
				title: 'Name',
				width: 250,
				priority: 1,
				isKey: true,
			},

			{
				id: 'sensorType',
				title: 'Type',
				width: 350,
				priority: 3,
				renderer: arrayJoinRenderer,
			},
			{
				id: 'deviceIds',
				title: 'Device IDs',
				width: 350,
				priority: 2,
				renderer: arrayJoinRenderer,
			},
			{
				id: 'action',
				title: 'Action',
				width: 150,
				//onClick: () => alert("Should send an email!"),
				priority: 4,
			},
			{
				id: 'condition',
				title: 'Condition',
				width: 150,
				priority: 2,
			},
		],
		[]
	);

	const parseHeaders = (headers: string) => {
		// old clojure format
		if (headers.startsWith('{:headers')) {
			let addExtraHeaderField = false;
			const cleanString = headers.replace(/[{}]/g, '').replace(':headers ', '').split(', '); // Remove curly braces

			if (cleanString.length < 4 && cleanString[0] !== '') {
				addExtraHeaderField = true;
			}

			const res: {key: string; value: string}[] = [];
			cleanString.forEach((header) => {
				const [key, value] = header.split(' ');

				if (key && value) {
					res.push({key: key.slice(1, -1), value: value.slice(1, -1)});
				}
			});
			if (addExtraHeaderField) res.push({key: '', value: ''});
			return res;
		} else {
			const jsonHeaders = JSON.parse(headers);
			if (jsonHeaders.length === 0 || (jsonHeaders.length < 4 && jsonHeaders[0].key !== '')) {
				jsonHeaders.push({key: '', value: ''});
			}
			return jsonHeaders;
		}
	};

	const validateCondition = (value: string) => {
		if (value === '') {
			setConditionError('Please enter a value for the calibration');
			return false;
		}

		if (!value.includes('$')) {
			setConditionError('The calibration value must be in the format: $/400');
			return false;
		}

		const mathExpression = value.replaceAll('$', '1');
		try {
			/*jslint evil: true */
			const result = Function("'use strict'; return (" + mathExpression + ')')();
			if (result === undefined || typeof result !== 'boolean') {
				setConditionError('The Condition is not returning a boolean value');
				return false;
			}
		} catch (e) {
			setConditionError('The Condition is not returning a boolean value');
			return false;
		}

		if (conditionError !== '') {
			setConditionError('');
		}
		return true;
	};

	useEffect(() => {
		cacheFetcher<Device[]>(process.env.REACT_APP_API_URL + `getids`, cacheKeys.userDevices, userDevicesLocalStorageUpdateCallback, true).then((allDevices: Device[]) => {
			setClientIdDevices(allDevices);

			cacheFetcher<ServerAlert[]>(`${process.env.REACT_APP_API_URL}getalerts`, cacheKeys.alerts, localStorageUpdateCallback, false, {
				method: 'GET',
				headers: new Headers({
					// Your header content
					'Content-Type': 'application/json',
				}),
			})
				.then((data) => {
					const alerts: Alert[] = [];

					data.forEach((serverAlert) => {
						const deviceIdSet = new Set<string>();
						const sensorTypeSet = new Set<string>();
						//get the friendly name of the device
						serverAlert.deviceSensors.forEach((deviceSensor) => {
							const device = allDevices.find((device) => device.id === deviceSensor.deviceId);
							if (device) {
								if (device.deviceName) {
									deviceIdSet.add(device.deviceName);
								} else {
									deviceIdSet.add(deviceSensor.deviceId);
								}
							}
							sensorTypeSet.add(deviceSensor.sensor);
						});
						const alert: Alert = {
							id: serverAlert.id,
							name: serverAlert.name,
							deviceIds: Array.from(deviceIdSet),
							sensorType: Array.from(sensorTypeSet),
							action: serverAlert.action,
							recipient: serverAlert.recipient,
							condition: serverAlert.condition,
							message: serverAlert.message,
							subject: serverAlert.subject,
							triggerInterval: serverAlert.triggerInterval,
							mqttCredentials: serverAlert.mqttCredentials !== null ? JSON.parse(serverAlert.mqttCredentials) : {port: 0, usernam: '', password: ''},
							customheaders: serverAlert.header !== null ? parseHeaders(serverAlert.header) : [],
						};
						alerts.push(alert);
					});
					setAlerts(alerts);
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					setAlertsAreLoading(false);
				});
		});
	}, []);

	const handleCreateAlert = (alertData: Alert) => {
		const conditionIsValid = validateCondition(alertData.condition);
		if (!conditionIsValid) {
			openSnackbar('error', 'The condition is not valid');
			return;
		}
		const data = {
			name: alertData.name,
			deviceIds: alertData.deviceIds.join(', '),
			sensorType: alertData.sensorType.join(', '),
			condition: alertData.condition,
			action: alertData.action,
			recipient: alertData.recipient,
			triggerInterval: alertData.triggerInterval,
			customHeaders: JSON.stringify(alertData.customheaders),
			message: alertData.message,
			subject: alertData.subject,
			mqttCredentials: JSON.stringify(alertData.mqttCredentials),
		};

		cacheFetcher<Alert>(
			`${process.env.REACT_APP_API_URL}addalert`,
			cacheKeys.alerts,
			(alertResponse) => {
				if (alertResponse.success) {
					appendToArrayInLocalStorage(alertResponse.message, cacheKeys.alerts);
				}
			},
			false,
			{
				method: 'POST',
				headers: new Headers({
					// Your header content
					'Content-Type': 'application/json',
				}),
				body: JSON.stringify(data),
			}
		)
			.then((response) => {
				const friendlyNames: string[] = [];
				//convert from friendly names to device ids
				alertData.deviceIds.forEach((element) => {
					const device = clientIdDevices.find((elm) => elm.id === element);
					if (device) {
						if (device.deviceName) {
							friendlyNames.push(device.deviceName);
						} else {
							friendlyNames.push(device.id);
						}
					}
				});
				alertData.deviceIds = friendlyNames;
				console.log(alertData);
				setAlerts((state) => [
					...state,
					{
						name: alertData.name,
						deviceIds: alertData.deviceIds,
						sensorType: alertData.sensorType,
						action: alertData.action,
						recipient: alertData.recipient,
						condition: alertData.condition,
						id: response.id,
						triggerInterval: alertData.triggerInterval,
						customheaders: alertData.customheaders,
						message: alertData.message,
						subject: alertData.subject,
						mqttCredentials: alertData.mqttCredentials,
					},
				]);
				setDialogInfo({open: false, mode: 'add', alertToEdit: null});
				setConditionError('');
				openSnackbar('success', 'Alert created successfully');
			})
			.catch((error) => {
				openSnackbar('error', 'Error creating alert');
				console.log(error);
			});
	};

	//port
	//username
	//password

	const handleDeleteAlert = (alertData: Alert) => {
		const data = {
			id: alertData.id,
		};

		cacheFetcher(
			`${process.env.REACT_APP_API_URL}deletealert`,
			cacheKeys.alerts,
			(alerts) => {
				if (alerts.success) {
					deleteItemFromArrayInLocalStorage<Alert>((item) => item.id === alertData.id, cacheKeys.alerts);
				}
			},
			false,
			{
				method: 'DELETE',
				headers: new Headers({
					// Your header content
					'Content-Type': 'application/json',
				}),
				body: JSON.stringify(data),
			}
		)
			.then((response) => {
				openSnackbar('success', 'Alert deleted successfully');
				setAlerts((state) => state.filter((a) => a.id !== alertData.id));
				setDeleteDialog((state) => {
					return {...state, open: false};
				});
			})
			.catch((error) => {
				openSnackbar('error', 'Error deleting alert');
				console.log(error);
			});
	};

	const handleEditAlert = (alertData: Alert) => {
		const conditionIsValid = validateCondition(alertData.condition);
		if (!conditionIsValid) {
			openSnackbar('error', 'The condition is not valid');
			return;
		}
		const data = {
			id: alertData.id,
			name: alertData.name,
			sensorType: alertData.sensorType.join(', '),
			deviceIds: alertData.deviceIds.join(', '),
			condition: alertData.condition,
			action: alertData.action,
			recipient: alertData.recipient,
			subject: alertData.subject,
			message: alertData.message,
			triggerInterval: alertData.triggerInterval,
			customHeaders: JSON.stringify(alertData.customheaders),
			mqttCredentials: JSON.stringify(alertData.mqttCredentials),
		};

		cacheFetcher<ServerAlert>(
			`${process.env.REACT_APP_API_URL}editalert`,
			cacheKeys.alerts,
			(alertData) => {
				if (alertData.success) {
					updateItemInArrayInLocalStorage<ServerAlert>((item) => item.id === alertData.message.id, alertData.message, cacheKeys.alerts);
				}
			},
			false,
			{
				method: 'PUT',
				headers: new Headers({
					// Your header content
					'Content-Type': 'application/json',
				}),
				body: JSON.stringify(data),
			}
		)
			.then((response) => {
				const deviceIdSet = new Set<string>();
				const sensorTypeSet = new Set<string>();
				response.deviceSensors.forEach((deviceSensor) => {
					deviceIdSet.add(deviceSensor.deviceId);
					sensorTypeSet.add(deviceSensor.sensor);
				});
				const updatedAlert: Alert = {
					...response,
					mqttCredentials: response.mqttCredentials !== null ? JSON.parse(response.mqttCredentials) : {},
					sensorType: Array.from(sensorTypeSet),
					deviceIds: Array.from(deviceIdSet),
					customheaders: response.header !== null ? parseHeaders(response.header) : [],
				};
				setAlerts((state) => state.map((a) => (a.id === alertData.id ? updatedAlert : a)));
				setDialogInfo({open: false, mode: 'add', alertToEdit: null});
				setConditionError('');
				openSnackbar('success', 'Alert edited successfully');
			})
			.catch((error) => {
				openSnackbar('error', 'Error editing alert');
				console.log('error');
			});
	};

	return (
		<Card
			title={'Alerts'}
			headerActions={
				<PrimaryButton
					onClick={() => {
						setDialogInfo({open: true, mode: 'add', alertToEdit: null});
					}}>
					Add new alert
				</PrimaryButton>
			}>
			<IOTTableWrapper
				columns={columns}
				data={alerts}
				onEdit={(alertItem) => {
					setDialogInfo({open: true, mode: 'edit', alertToEdit: alertItem});
				}}
				onDelete={(alertItem) => {
					setDeleteDialog({open: true, alert: alertItem});
				}}
				isLoading={alertsAreLoading}
			/>
			{dialogInfo.open && (
				<AlertDialog
					alertItem={dialogInfo.alertToEdit}
					onClose={() => setDialogInfo({open: false, mode: 'add', alertToEdit: null})}
					onSave={dialogInfo.mode === 'add' ? handleCreateAlert : handleEditAlert}
					isEditDialog={dialogInfo.mode === 'edit'}
					conditionValidator={validateCondition}
					conditionError={conditionError}
					clientIdDevices={clientIdDevices}
				/>
			)}
			<DeleteDialog
				title={deleteDialog.alert?.name}
				open={deleteDialog.open}
				handleDelete={() => {
					handleDeleteAlert(deleteDialog.alert);
				}}
				onClose={() =>
					setDeleteDialog((state) => {
						return {...state, open: false};
					})
				}
				itemName={deleteDialog.alert?.name}
			/>
		</Card>
	);
}

export default AlertsPage;
